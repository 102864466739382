import { Alert, Avatar, Badge, Box, Button, Card, Chip, Container, Divider, Drawer, Grid, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, Skeleton, Theme, Typography, useMediaQuery } from '@mui/material';
import React, { memo, useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router';
import { FixedSizeList, ListChildComponentProps } from 'react-window';
import axiosClient from 'src/axios_client';
import PageContainer from 'src/components/container/PageContainer';
import Scrollbar from 'src/components/custom-scroll/Scrollbar';
import BlankCard from 'src/components/shared/BlankCard';
import { formatCurrency } from 'src/globals/NumericFormat';
import { formatDate } from 'src/globals/PublicFunctions';
import { AppState, useDispatch, useSelector } from 'src/store/Store';
import AutoSizer, { Size } from 'react-virtualized-auto-sizer';
import { IconMenu2 } from '@tabler/icons';
import { QueryClient, QueryClientProvider, keepPreviousData, useQuery } from '@tanstack/react-query';
import { MRT_ColumnDef, MRT_ColumnFiltersState, MRT_PaginationState, MRT_RowSelectionState, MRT_SortingState, MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { Add } from '@mui/icons-material';
import { Link } from 'react-router-dom';

type Props = {}

type HandleClickType = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, index: number) => void;

// Define the props for the Row component
interface RowProps extends ListChildComponentProps {
    data: any;
    selectedIndex: number | null;
    index: any;
    style: any;
    handleClick: HandleClickType;
}
interface AdjustmentList {
    id: any;
    requested_buying: any;
    requested_quantity: any;
    requested_selling: any;
    product_name: any;
    product_description: any;
    product_image: any;
    sku: any;
    transaction_id: any;
    updated_by: any;
    reference_number: any;
    transaction_number: any;
    updated_at: any;
}

interface AdjustmentData {
    adjustment_list: AdjustmentList[];
    adjustment_status: any;
    created_at: any;
    id: any;
    image: any;
    products: any;
    reason: any;
    reference_number: any;
    transaction_number: any;
    updated_at: any;
}

type AdjustmentApiResponse = {
    data: Array<AdjustmentData>;
    pagination: {
        total: number;
        per_page: number;
        current_page: number;
        last_page: number;
        from: number;
        to: number;
    };
};

// Memoize the Row component to prevent unnecessary re-renders
// const Row: React.FC<RowProps> = memo(({ data, index, style, selectedIndex, handleClick }) => {
//     const item = data[index];
//     const status = data[index].adjustment_status;
//     const statusText = status >= 1 && status <= 3 ? 'Pending' : status >= 4 && status <= 6 ? 'Approved' : 'Unknown';

//     return (
//         <div style={style}>
//             <ListItemButton
//                 onClick={(event) => handleClick(event, index)}
//                 sx={{
//                     mb: 0,
//                     py: 2,
//                     px: 3,
//                 }}
//                 selected={selectedIndex === index}
//             >
//                 <ListItemAvatar>
//                     <Badge
//                         color={'success'}
//                         variant="dot"
//                         anchorOrigin={{
//                             vertical: 'bottom',
//                             horizontal: 'right',
//                         }}
//                         overlap="circular"
//                     >
//                         <Avatar alt="Remy Sharp" src={''} sx={{ width: 42, height: 42 }} />
//                     </Badge>
//                 </ListItemAvatar>
//                 <ListItemText
//                     primary={
//                         <>
//                             <Grid container>
//                                 <Grid item flexGrow={1}>
//                                     <Typography variant="subtitle2" fontWeight={600}>
//                                         {item.transaction_number}
//                                     </Typography>
//                                     <Typography variant="body2" fontWeight={600}>
//                                         {item.reason}
//                                     </Typography>
//                                     <Typography variant="body2" fontWeight={600}>
//                                         {formatDate(item.created_at)}
//                                     </Typography>
//                                 </Grid>
//                                 <Grid item alignSelf={'center'}>
//                                     <Chip label={statusText} color={statusText == 'Pending' ? 'secondary' : statusText == 'Approved' ? 'success' : 'error'} />
//                                 </Grid>
//                             </Grid>

//                         </>

//                     }

//                     // secondary={formatDate(item.created_at)}
//                     // secondaryTypographyProps={{
//                     //     noWrap: true,
//                     // }}
//                     sx={{ my: 0 }}
//                 />
//             </ListItemButton>
//         </div>
//     )
// }
// );

const MainTable = () => {
    const { id } = useParams();

    const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>([{ id: 'transaction_number', value: id }]);
    const [globalFilter, setGlobalFilter] = useState('');
    const [sorting, setSorting] = useState<MRT_SortingState>([]);

    const navigate = useNavigate();
    const [pagination, setPagination] = useState<MRT_PaginationState>({
        pageIndex: 0,
        pageSize: 10,
    });
    const {
        data: { data = [], pagination: meta } = {},
        isError,
        isRefetching,
        isLoading,
        refetch,
    } = useQuery<AdjustmentApiResponse>({
        queryKey: [
            'table-data',
            columnFilters,
            globalFilter,
            pagination.pageIndex,
            pagination.pageSize,
            sorting,
        ],
        queryFn: async () => {
            try {
                const fetchURL = new URL(`${process.env.REACT_APP_API_SERVER}/adjustments_transaction`);
                fetchURL.searchParams.set('page', `${pagination.pageIndex + 1}`);
                fetchURL.searchParams.set('per_page', `${pagination.pageSize}`);
                fetchURL.searchParams.set('filters', JSON.stringify(columnFilters ?? []));
                fetchURL.searchParams.set('globalFilter', globalFilter ?? '');
                fetchURL.searchParams.set('sorting', JSON.stringify(sorting ?? []));

                console.log(columnFilters);
                const response = await axiosClient.get(fetchURL.href);
                const json = response.data as AdjustmentApiResponse;

                console.log(json);

                return json;
            } catch (error) {
                console.error('Error fetching data:', error);
                throw error;
            }
        },
        placeholderData: keepPreviousData,
    });
    const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({ 0: true });

    const columns = useMemo<MRT_ColumnDef<AdjustmentData>[]>(
        () => [
            {
                accessorKey: 'transaction_number',
                header: 'Transaction No.',
                Cell: ({ row }) => {
                    const item = row.original;
                    const status = row.original.adjustment_status;
                    const statusText = status >= 1 && status <= 3 ? 'Pending' : status >= 4 && status <= 6 ? 'Approved' : 'Unknown';

                    return (
                        <ListItem sx={{ p: 0 }}>
                            <ListItemAvatar>
                                <Avatar alt="Remy Sharp" src={''} sx={{ width: 42, height: 42 }} />
                            </ListItemAvatar>
                            <ListItemText
                                primary={
                                    <>
                                        <Grid container>
                                            <Grid item flexGrow={1}>
                                                <Typography variant="subtitle2" fontWeight={600}>
                                                    {item.transaction_number}
                                                </Typography>
                                                <Typography variant="body2" fontWeight={600}>
                                                    {item.reason}
                                                </Typography>
                                                <Typography variant="body2" fontWeight={600}>
                                                    {formatDate(item.created_at)}
                                                </Typography>
                                            </Grid>
                                            <Grid item alignSelf={'center'}>
                                                <Chip label={statusText} color={statusText == 'Pending' ? 'secondary' : statusText == 'Approved' ? 'success' : 'error'} />
                                            </Grid>
                                        </Grid>

                                    </>

                                }

                                // secondary={formatDate(item.created_at)}
                                // secondaryTypographyProps={{
                                //     noWrap: true,
                                // }}
                                sx={{ my: 0 }}
                            />
                        </ListItem>
                    );
                },
            },
        ],
        [],
    );

    const table = useMaterialReactTable({
        columns,
        data,
        layoutMode: 'grid',
        defaultColumn: {
            minSize: 20, //allow columns to get smaller than default
            maxSize: 9001, //allow columns to get larger than default
            size: 180, //make columns wider by default
        },
        initialState: {
            showColumnFilters: true,
            columnPinning: {
                left: ['mrt-row-expand', 'mrt-row-select'],
                right: ['mrt-row-actions'],
            },
        },
        manualFiltering: true,
        manualPagination: true,
        manualSorting: true,
        enableRowSelection: true,
        enableMultiRowSelection: false,
        muiToolbarAlertBannerProps: isError
            ? {
                color: 'error',
                children: 'Error loading data',
            }
            : undefined,
        onColumnFiltersChange: setColumnFilters,
        onGlobalFilterChange: setGlobalFilter,
        onPaginationChange: setPagination,
        onSortingChange: setSorting,
        enableColumnOrdering: true,
        renderTopToolbarCustomActions: () => (
            <Box sx={{ display: 'flex', gap: '1rem', p: '4px', alignItems: 'center' }}>
                <Button variant="contained" startIcon={<Add />} color="primary" to="/product-adjustment" component={Link}>
                    New
                </Button>
            </Box>
        ),
        positionToolbarAlertBanner: 'none',
        onRowSelectionChange: setRowSelection,
        muiTableBodyRowProps: ({ row }) => ({
            onClick: (event) => {

                const toggleHandler = row.getToggleSelectedHandler();
                toggleHandler(event);

                navigate(`/adjustment-transactions/view/${row.original.transaction_number}`);
            },
            sx: {
                cursor: 'pointer', //you might want to change the cursor too when adding an onClick
            },
        }),
        rowCount: meta?.total ?? 0,
        state: {
            columnFilters,
            globalFilter,
            isLoading,
            rowSelection,
            pagination,
            showAlertBanner: isError,
            showProgressBars: isRefetching,
            sorting,
        },
    });

    useEffect(() => {
        console.log(rowSelection);
    }, [rowSelection])

    return (
        <>
            <MaterialReactTable table={table} />
        </>
    );
}

function TransactionTables() {
    const { id } = useParams();
    const [data, setData] = useState<any[]>([]);

    const drawerWidth = 380;

    // const navigate = useNavigate();
    // const [selectedIndex, setSelectedIndex] = React.useState(0);

    const [adjustmentData, setAdjustmentData] = useState<AdjustmentData>();
    const [loadingData, setLoadingData] = useState(false);

    const [isMobileSidebarOpen, setMobileSidebarOpen] = useState(false);
    const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));

    // const handleListItemClick = (
    //     event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    //     index: number,
    // ) => {
    //     setSelectedIndex(index);
    //     navigate(`/adjustment-transactions/view/${index + 1}`);
    // };

    useEffect(() => {
        axiosClient.get(`/adjustments_transaction`)
            .then(({ data, status }) => {
                setData(data.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }, [])
    useEffect(() => {
        setLoadingData(true);
        axiosClient.get(`/adjustments_transaction/view/${id}`)
            .then(({ data, status }) => {
                if (status == 200) {
                    setLoadingData(false);
                }
                setAdjustmentData(data.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }, [id])

    useEffect(() => {
        console.log(data);
    }, [data]);
    const customizer = useSelector((state: AppState) => state.customizer);



    return (

        <PageContainer title="RKCWMS - Product Adjustment Page" description="this is Sample page">
            <Container sx={{ maxWidth: { md: '1500px' } }}>
                <Card
                    sx={{ display: 'flex', p: 0 }}
                    elevation={customizer.isCardShadow ? 9 : 0}
                    variant={!customizer.isCardShadow ? 'outlined' : undefined}
                >
                    <Drawer
                        open={isMobileSidebarOpen}
                        onClose={() => setMobileSidebarOpen(false)}
                        variant={lgUp ? 'permanent' : 'temporary'}
                        sx={{
                            width: drawerWidth,
                            flexShrink: 0,

                            zIndex: lgUp ? 0 : 1,
                            [`& .MuiDrawer-paper`]: { position: 'relative', overflow: 'hidden' },
                        }}
                    >

                        <Box display="flex" alignItems="center" p={2}>
                            <ListItem dense disableGutters>
                                <ListItemText
                                    primary={<Typography variant="h5">Adjustment Transactions</Typography>}
                                />
                            </ListItem>
                        </Box>
                        <MainTable />

                        {/* <AutoSizer>
                            {({ height, width }: Size) => (
                                <FixedSizeList
                                    height={height} // Adjust based on your container's height
                                    itemCount={data.length}
                                    itemSize={90}
                                    width={width}
                                >
                                    {({ index, style }) => (
                                        <Row
                                            data={data}
                                            index={index}
                                            style={style}
                                            selectedIndex={selectedIndex}
                                            handleClick={handleListItemClick}
                                        />
                                    )}
                                </FixedSizeList>
                            )}
                        </AutoSizer> */}
                    </Drawer>
                    <Box flexGrow={1}>
                        <Box display="flex" alignItems="center" p={2}>
                            <Box
                                sx={{
                                    display: { xs: 'block', md: 'block', lg: 'none' },
                                    mr: '10px',
                                }}
                            >
                                <IconMenu2 stroke={1.5} onClick={() => setMobileSidebarOpen(true)} />
                            </Box>
                            <ListItem dense disableGutters>
                                <ListItemText
                                    primary={<Typography variant="h5">Transaction Details</Typography>}
                                />
                            </ListItem>
                        </Box>
                        <Divider />
                        <Scrollbar sx={{ height: 'calc(100vh - 170px)', flexGrow: 1 }}>

                            <Container>
                                {loadingData &&
                                    <Box flexGrow={2}>
                                        <Container sx={{ mt: 5 }}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={3}>
                                                    <Skeleton variant="rectangular" height={19} />
                                                </Grid>
                                                <Grid item xs={9}>
                                                    <Skeleton variant="rectangular" height={19} />
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <Skeleton variant="rectangular" height={19} />
                                                </Grid>
                                                <Grid item xs={9}>
                                                    <Skeleton variant="rectangular" height={19} />
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <Skeleton variant="rectangular" height={19} />
                                                </Grid>
                                                <Grid item xs={9}>
                                                    <Skeleton variant="rectangular" height={19} />
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <Skeleton variant="rectangular" height={19} />
                                                </Grid>
                                                <Grid item xs={9}>
                                                    <Skeleton variant="rectangular" height={19} />
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <Skeleton variant="rectangular" height={19} />
                                                </Grid>
                                                <Grid item xs={9}>
                                                    <Skeleton variant="rectangular" height={19} />
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <Skeleton variant="rectangular" height={19} />
                                                </Grid>
                                                <Grid item xs={9}>
                                                    <Skeleton variant="rectangular" height={19} />
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <Skeleton variant="rectangular" height={19} />
                                                </Grid>
                                                <Grid item xs={9}>
                                                    <Skeleton variant="rectangular" height={19} />
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <Skeleton variant="rectangular" height={19} />
                                                </Grid>
                                                <Grid item xs={9}>
                                                    <Skeleton variant="rectangular" height={19} />
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <Divider />
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <Grid rowSpacing={2}>
                                                        <Grid item xs={12}>
                                                            <Typography variant='h6'>
                                                                Adjustment Items
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <List
                                                                subheader={
                                                                    <ListItem sx={{ backgroundColor: 'grey.100' }}>
                                                                        <ListItemAvatar >
                                                                            <Box width={20} height={20} >
                                                                            </Box>
                                                                        </ListItemAvatar>
                                                                        <Grid container columnSpacing={1}>
                                                                            <Grid item xs={6}>
                                                                                <ListItemText primary={
                                                                                    <Typography variant='h6'>
                                                                                        Item Details
                                                                                    </Typography>
                                                                                } />
                                                                            </Grid>
                                                                            <Grid item xs={3}>
                                                                                <ListItemText primary={
                                                                                    <Typography variant='h6'>
                                                                                        Quantity Adjusted
                                                                                    </Typography>
                                                                                } />
                                                                            </Grid>
                                                                            <Grid item xs={3}>
                                                                                <ListItemText primary={
                                                                                    <Typography variant='h6'>
                                                                                        Buying Price
                                                                                    </Typography>
                                                                                } />
                                                                            </Grid>

                                                                        </Grid>

                                                                    </ListItem>
                                                                }
                                                            >
                                                                <ListItem

                                                                // secondaryAction={
                                                                //     <IconButton edge="end" aria-label="comments" onClick={handleRemoveClick}>
                                                                //         <Close width={20} height={20} />
                                                                //     </IconButton>
                                                                // }
                                                                >
                                                                    <ListItemAvatar>
                                                                        <Avatar>
                                                                            <img src={''} height={'auto'} width={'100%'}></img>
                                                                        </Avatar>
                                                                    </ListItemAvatar>
                                                                    <Grid container columnSpacing={1}>
                                                                        <Grid item xs={6}>
                                                                            <ListItemText primary={
                                                                                <>
                                                                                    <Typography>
                                                                                        <Skeleton variant="rectangular" height={19} />
                                                                                    </Typography>
                                                                                </>
                                                                            } secondary={
                                                                                <>
                                                                                    <Typography sx={{ mt: 1 }}>
                                                                                        <Skeleton variant="rectangular" height={19} />
                                                                                    </Typography>
                                                                                </>
                                                                            } />
                                                                        </Grid>
                                                                        <Grid item xs={3}>
                                                                            <ListItemText
                                                                                primary={
                                                                                    <>
                                                                                        <Typography>
                                                                                            <Skeleton variant="rectangular" height={19} />
                                                                                        </Typography>
                                                                                    </>
                                                                                }
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={3}>
                                                                            <ListItemText
                                                                                primary={
                                                                                    <>
                                                                                        <Typography>
                                                                                            <Skeleton variant="rectangular" height={19} />
                                                                                        </Typography>
                                                                                    </>
                                                                                }
                                                                            />
                                                                        </Grid>
                                                                    </Grid>
                                                                </ListItem>
                                                            </List>
                                                        </Grid>

                                                    </Grid>


                                                </Grid>
                                            </Grid>
                                        </Container>
                                    </Box>
                                }
                                {(!loadingData && adjustmentData) &&
                                    <Box flexGrow={2}>
                                        <Container sx={{ mt: 5 }}>
                                            <Grid container rowSpacing={{ xs: 0, sm: 2 }}>
                                                <Grid item xs={12} sm={3} fontWeight={600}>
                                                    Date
                                                </Grid>
                                                <Grid item xs={12} sm={9}>
                                                    {formatDate(adjustmentData.created_at)}
                                                </Grid>
                                                <Grid item xs={12} sm={3} fontWeight={600} mt={{ xs: 1, sm: 0 }}>
                                                    Reason
                                                </Grid>
                                                <Grid item xs={12} sm={9}>
                                                    {adjustmentData.reason}
                                                </Grid>
                                                <Grid item xs={12} sm={3} fontWeight={600} mt={{ xs: 1, sm: 0 }}>
                                                    Status
                                                </Grid>
                                                <Grid item xs={12} sm={9}>
                                                    {adjustmentData.adjustment_status >= 1 && adjustmentData.adjustment_status <= 3 ? 'Pending' : adjustmentData.adjustment_status >= 4 && adjustmentData.adjustment_status <= 6 ? 'Approved' : 'Unknown'}
                                                </Grid>
                                                <Grid item xs={12} sm={3} fontWeight={600} mt={{ xs: 1, sm: 0 }}>
                                                    Adjustment Type
                                                </Grid>
                                                <Grid item xs={12} sm={9}>
                                                    {adjustmentData.adjustment_status == 1 || adjustmentData.adjustment_status == 4 ? 'Price Adjustment' : adjustmentData.adjustment_status == 2 || adjustmentData.adjustment_status == 5 ? 'Quantity Adjustment' : 'Quantity and Price Adjustment'}
                                                </Grid>
                                                <Grid item xs={12} sm={3} fontWeight={600} mt={{ xs: 1, sm: 0 }}>
                                                    Reference Number
                                                </Grid>
                                                <Grid item xs={12} sm={9}>
                                                    {adjustmentData.reference_number}
                                                </Grid>
                                                <Grid item xs={12} sm={3} fontWeight={600} mt={{ xs: 1, sm: 0 }}>
                                                    Transaction Number
                                                </Grid>
                                                <Grid item xs={12} sm={9}>
                                                    {adjustmentData.transaction_number}
                                                </Grid>
                                                <Grid item xs={12} sm={3} fontWeight={600} mt={{ xs: 1, sm: 0 }}>
                                                    Adjusted By
                                                </Grid>
                                                <Grid item xs={12} sm={9}>
                                                    {'Hindi ko alam'}
                                                </Grid>
                                                <Grid item xs={12} sm={3} fontWeight={600} mt={{ xs: 1, sm: 0 }}>
                                                    Created Time
                                                </Grid>
                                                <Grid item xs={12} sm={9}>
                                                    {adjustmentData.created_at}
                                                </Grid>
                                                <Grid item xs={12} sm={3} fontWeight={600} mt={{ xs: 1, sm: 0 }}>
                                                    Description
                                                </Grid>
                                                <Grid item xs={12} sm={9}>
                                                    {adjustmentData.reason}
                                                </Grid>

                                                <Grid item xs={12} mt={{ xs: 1, sm: 0 }}>
                                                    <Divider />
                                                </Grid>

                                                <Grid item xs={12} mt={{ xs: 1, sm: 0 }}>
                                                    <Grid container rowSpacing={2}>
                                                        <Grid item xs={12}>
                                                            <Typography variant='h6'>
                                                                Adjustment Items
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12} >
                                                            <Box component={'div'} sx={{ width: { xs: 250, sm: 'unset' }, overflowX: 'scroll' }}>

                                                                <Box component="div" >
                                                                    <List
                                                                        sx={{ width: {xs: 'max-content', sm: 'auto'} }}
                                                                        subheader={
                                                                            <ListItem sx={{ backgroundColor: 'grey.100' }}>
                                                                                <ListItemAvatar >
                                                                                    <Box width={20} height={20} >
                                                                                    </Box>
                                                                                </ListItemAvatar>
                                                                                <Grid container columnSpacing={1}>
                                                                                    <Grid item xs={6}>
                                                                                        <ListItemText primary={
                                                                                            <Typography variant='h6'>
                                                                                                Item Details
                                                                                            </Typography>
                                                                                        } />
                                                                                    </Grid>
                                                                                    <Grid item xs={3}>
                                                                                        <ListItemText primary={
                                                                                            <Typography variant='h6'>
                                                                                                Quantity Adjusted
                                                                                            </Typography>
                                                                                        } />
                                                                                    </Grid>
                                                                                    <Grid item xs={3}>
                                                                                        <ListItemText primary={
                                                                                            <Typography variant='h6'>
                                                                                                Buying Price Adjusted
                                                                                            </Typography>
                                                                                        } />
                                                                                    </Grid>

                                                                                </Grid>

                                                                            </ListItem>
                                                                        }
                                                                    >

                                                                        {adjustmentData.adjustment_list && adjustmentData.adjustment_list.length > 0 &&
                                                                            adjustmentData.adjustment_list.map((adjustment) => (
                                                                                <ListItem key={adjustment.id}>
                                                                                    <ListItemAvatar>
                                                                                        
                                                                                        <Avatar>
                                                                                            <img src={adjustment.product_image} height={'auto'} width={'100%'}></img>
                                                                                        </Avatar>
                                                                                    </ListItemAvatar>
                                                                                    <Grid container columnSpacing={1}>
                                                                                        <Grid item xs={6}>
                                                                                            <ListItemText primary={adjustment.product_name} secondary={adjustment.product_description} />
                                                                                        </Grid>
                                                                                        <Grid item xs={3}>
                                                                                            <ListItemText
                                                                                                primary={
                                                                                                    <>
                                                                                                        <Typography>
                                                                                                            {adjustment.requested_quantity}
                                                                                                        </Typography>
                                                                                                    </>
                                                                                                }
                                                                                            />
                                                                                        </Grid>
                                                                                        <Grid item xs={3}>
                                                                                            <ListItemText
                                                                                                primary={
                                                                                                    <>
                                                                                                        <Typography>
                                                                                                            {formatCurrency(adjustment.requested_buying)}
                                                                                                        </Typography>
                                                                                                    </>
                                                                                                }
                                                                                            />
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                </ListItem>
                                                                            ))
                                                                        }

                                                                    </List>
                                                                </Box>
                                                            </Box>

                                                        </Grid>

                                                    </Grid>


                                                </Grid>
                                            </Grid>
                                        </Container>
                                    </Box>
                                }
                            </Container>
                        </Scrollbar>
                    </Box>

                </Card>
            </Container>


        </PageContainer>
    )
}

const queryClient = new QueryClient();
function AdjustmentDetails({ }: Props) {

    return (
        <QueryClientProvider client={queryClient}>
            <TransactionTables />
        </QueryClientProvider>

    )
}

export default AdjustmentDetails