import { useMemo } from 'react';

const useFormattedNumber = () => {
  const formatNumber = (number: number): string => {
    return number.toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  const addPrefix = (formattedNumber: string): string => {
    return `₱${formattedNumber}`;
  };

  const formatWithPrefix = (number: number): string => {
    const formattedNumber = formatNumber(number);
    return addPrefix(formattedNumber);
  };

  return useMemo(() => (
    { formatWithPrefix }
  ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []);
};

export default useFormattedNumber;