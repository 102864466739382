import React from 'react';
import { Box } from '@mui/material';
import Breadcrumb from 'src/layouts/full/shared/breadcrumb/Breadcrumb';
import PageContainer from 'src/components/container/PageContainer';
import DashboardCard from '../../components/shared/DashboardCard';
import SalesByCustomerTable from './SalesByCustomer/Table'

const BCrumb = [
  {
    to: '/',
    title: 'Home',
  },
  {
    to: '',
    title: 'Reports',
  },
  {
    title: 'Sales By Customer Page',
  },
];

const SalesByCustomer = () => {

  return (
    <PageContainer title="RKCWMS - Sales By Customer Page" description="this is Sample page">
      {/* breadcrumb */}
      <Breadcrumb title="Sales By Customer Page" items={BCrumb} />
      {/* end breadcrumb */}
      <DashboardCard title="Sales By Customer Page">
        <Box>
          <SalesByCustomerTable/>
        </Box>
      </DashboardCard>
    </PageContainer>
  );
};

export default SalesByCustomer;
