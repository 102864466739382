import React from 'react';
import { Box } from '@mui/material';
import Breadcrumb from 'src/layouts/full/shared/breadcrumb/Breadcrumb';
import PageContainer from 'src/components/container/PageContainer';
import DashboardCard from '../../components/shared/DashboardCard';
import InventoryValuationSummaryTable from './InventoryValuationSummary/Table';

const BCrumb = [
  {
    to: '/',
    title: 'Home',
  },
  {
    title: 'InventoryValuationSummary Page',
  },
];

const InventoryValuationSummary = () => {

  return (
    <PageContainer title="RKCWMS - InventoryValuationSummary Page" description="this is Sample page">
      {/* breadcrumb */}
      <Breadcrumb title="InventoryValuationSummary Page" items={BCrumb} />
      {/* end breadcrumb */}
      <DashboardCard title="InventoryValuationSummary Page">
        <Box>
          <InventoryValuationSummaryTable/>
        </Box>
      </DashboardCard>
    </PageContainer>
  );
};

export default InventoryValuationSummary;
