import React, { Suspense, lazy } from 'react';
import { Box } from '@mui/material';
import Breadcrumb from 'src/layouts/full/shared/breadcrumb/Breadcrumb';
import PageContainer from 'src/components/container/PageContainer';

const NotificationsTable = lazy(() => import('./components/NotificationsTable'));

const BCrumb = [
  {
    to: '/',
    title: 'Home',
  },
  {
    title: 'Notifications Page',
  },
];

const Notifications = () => {
  return (
    <PageContainer title="RKCWMS - Notifications Page" description="this is Sample page">
      {/* breadcrumb */}
      <Breadcrumb title="Notifications Page" items={BCrumb} />
      {/* end breadcrumb */}
      <Suspense fallback={<div>Loading...</div>}>
        <NotificationsTable />
      </Suspense>
    </PageContainer>
  );
};

export default Notifications;
