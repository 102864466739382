import React from 'react';

import Breadcrumb from 'src/layouts/full/shared/breadcrumb/Breadcrumb';
import PageContainer from 'src/components/container/PageContainer';
import BlankCard from 'src/components/shared/BlankCard';
import AdjustmentPage from './ProductTableListAdj';
import { useSelector } from 'react-redux';
import { AppState } from 'src/store/Store';
import { Card, useMediaQuery, useTheme } from '@mui/material';

const BCrumb = [
    {
        to: '/',
        title: 'Home',
    },
    {
        title: 'Product Adjustment',
    },
];

const ProductMaster = () => {
    const customizer = useSelector((state: AppState) => state.customizer);
    const theme = useTheme();
    const borderColor = theme.palette.grey[100];
    const isSmallScreen = useMediaQuery('(max-width:375px)');

    return (
        <PageContainer title="RKCWMS - Product Adjustment Page" description="this is Sample page">
            {/* breadcrumb */}
            <Breadcrumb title="Product Adjustment Page" items={BCrumb} />
            {/* end breadcrumb */}
            <Card sx={{
                padding: 0,
                border: !customizer.isCardShadow ? `1px solid ${borderColor}` : 'none',
                maxWidth: isSmallScreen ? '345px' : 'none',
            }} style={{ height: '100%', position: 'relative' }}>
                <AdjustmentPage />
            </Card>
        </PageContainer>
    );
};
export default ProductMaster;
