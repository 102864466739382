import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { Modal, Tag } from 'antd';
import axiosClient from 'src/axios_client';
import debounce from 'lodash/debounce';
import { useSelector } from 'src/store/Store';
import { AppState } from 'src/store/Store';
import { theme } from 'antd/lib';
import { Box, Chip, ClickAwayListener, Menu, MenuItem, Paper, Popper, TextField } from '@mui/material';

export interface TagItem {
  id: number;
  value: string;
  product_id: any;
  location_id: any;
  floor: any;
  divider: any;
  serial_number_id: number;
  is_used: any;
  price: number;
  notice?: any;
  note?: any;
}

interface CustomMultiInputProps {
  maximumTags: number;
  productId: number;
  disabled?: boolean;
}

const CustomMultiInput = forwardRef<
  {
    getTags: () => TagItem[];
    setTags: (tags: TagItem[]) => void;
    clearTags: () => void;
  },
  CustomMultiInputProps
>(({ maximumTags, productId, disabled = false }, ref) => {
  const [inputValue, setInputValue] = useState<string>('');
  const [tags, setTags] = useState<TagItem[]>([]);
  const [suggestions, setSuggestions] = useState<any[]>([]);
  const [activeSuggestionIndex, setActiveSuggestionIndex] = useState<number>(-1);
  const [noResults, setNoResults] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [issuedSerialNumber, setIssuedSerialNumber] = useState('');
  const [modalText, setModalText] = useState('');
  const fetchSuggestions = async (query: string): Promise<any[]> => {
    try {
      const response = await axiosClient.get(`/check_serial_number`, {
        params: {
          globalFilter: query,
          product_id: productId,
        },
      });

      console.log(response.data.data);

      return response.data.data;
    } catch (error) {
      console.error('Error fetching suggestions:', error);
      return [];
    }
  };

  const debouncedFetchSuggestions = useCallback(
    debounce(async (query: string) => {
      if (query) {
        const fetchedSuggestions = await fetchSuggestions(query);
        const filteredSuggestions = fetchedSuggestions.filter(
          (suggestion) => !tags.some((tag) => tag.value === suggestion.serial_number),
        );
        setSuggestions(filteredSuggestions);
        setNoResults(filteredSuggestions.length === 0);
      } else {
        setSuggestions([]);
        setNoResults(false);
      }
    }, 300),
    [tags, productId],
  );

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
    setAnchorEl(divRef.current);
    debouncedFetchSuggestions(e.target.value);
    setActiveSuggestionIndex(-1);
  };

  const handleTagClose = (removedTag: string) => {
    const newTags = tags.filter((tag) => tag.value !== removedTag);
    setTags(newTags);
  };

  const handleSuggestionClick = (suggestion: any) => {
    setAnchorEl(null);
    console.log(suggestion);

    if (tags.length >= maximumTags) {
      console.warn(`Maximum of ${maximumTags} tags reached.`);
      return;
    }
    if (suggestion.status === 'issued') {
      showIssuedPopup(suggestion.serial_number);
      setModalText('issued');
      return;
    }
    if (suggestion.status === 'pending') {
      showIssuedPopup(suggestion.serial_number);
      setModalText('pending');
      return;
    }
    if (!tags.some((tag) => tag.value === suggestion.serial_number)) {
      setTags((prevTags) => [
        ...prevTags,
        {
          id: suggestion.id,
          product_id: suggestion.product_id,
          is_used: suggestion.is_used,
          location_id: suggestion.location_id,
          floor: suggestion.floor,
          divider: suggestion.divider,
          value: suggestion.serial_number,
          serial_number_id: suggestion.id,
          price: suggestion.is_used == 1 ? suggestion.product_entered_price : suggestion.product_selling_price,
        },
      ]);
    }
    setInputValue('');
    setSuggestions([]);
    setNoResults(false);
  };

  const handleInputFocus = () => {

    setAnchorEl(divRef.current);

    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (suggestions.length > 0) {
      if (e.key === 'ArrowDown') {
        setActiveSuggestionIndex((prevIndex) => (prevIndex + 1) % suggestions.length);
      } else if (e.key === 'ArrowUp') {
        setActiveSuggestionIndex(
          (prevIndex) => (prevIndex + suggestions.length - 1) % suggestions.length,
        );
      } else if (e.key === 'Enter' && activeSuggestionIndex >= 0) {
        e.preventDefault();
        handleSuggestionClick(suggestions[activeSuggestionIndex]);
      }
    }
  };

  const showIssuedPopup = (serialNumber: any) => {
    setIssuedSerialNumber(serialNumber);
    setIsModalVisible(true);
  };

  const handleModalOk = () => {
    setIsModalVisible(false);
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    if (!disabled) {
      handleInputFocus();
    }
  }, [disabled]);

  useImperativeHandle(ref, () => ({
    getTags: () => tags,
    setTags: (newTags: TagItem[]) => setTags(newTags),
    clearTags: () => setTags([]),
  }));

  const customizer = useSelector((state: AppState) => state.customizer);
  const currentTheme = customizer.activeMode;

  const [isDarkMode, setIsDarkMode] = useState(false);
  const { defaultAlgorithm, darkAlgorithm } = theme;
  useEffect(() => {
    setIsDarkMode(currentTheme === 'dark');
  }, [currentTheme]);


  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleBlur = () => {
    // console.log("Hello");

    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const divRef = useRef<HTMLDivElement>(null);
  return (
    <ClickAwayListener onClickAway={handleBlur}>
      <div
        ref={divRef}
        onClick={handleInputFocus}
        style={{
          display: 'flex',
          alignItems: 'center',
          flexWrap: 'wrap',
          padding: '4px',
          border: disabled ? '1px solid #d9d9d9' : '1px solid #1890ff',
          borderRadius: '4px',
          minHeight: '33px',
          maxHeight: '33px',
          overflowY: 'auto',
          cursor: disabled ? 'not-allowed' : 'text',
          backgroundColor: disabled ? '#f5f5f5' : isDarkMode ? '#292E34' : 'white',
          color: disabled ? '#a1a1a1' : isDarkMode ? '#ffffff' : '#000000',
        }}
      >
        <Modal
          title="Serial Number Issued"
          open={isModalVisible}
          onOk={handleModalOk}
          onCancel={handleModalCancel}
        >
          {modalText == "pending" &&
            <p>The serial number {issuedSerialNumber} is has pending issuance.</p>
          }
          {modalText == "issued" &&
            <p>The serial number {issuedSerialNumber} is has already been issued.</p>
          }
        </Modal>

        {tags.map((tag) => (
          <Tag
            key={tag.value}
            closable={!disabled}
            onClose={() => !disabled && handleTagClose(tag.value)}
            style={{ marginBottom: '4px' }}
          >
            {tag.value}
          </Tag>
        ))}
        {tags.length < maximumTags && !disabled && (
          <>
            <input
              ref={inputRef}
              value={inputValue}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
              // onBlur={() => {
              //   setSuggestions([]);
              // }}
              placeholder="Type values separated by commas"
              style={{
                border: 'none',
                outline: 'none',
                boxShadow: 'none',
                flex: '1 0 auto',
                minWidth: '100px',
                backgroundColor: disabled ? '#f5f5f5' : (isDarkMode ? '#292E34' : 'white'),
                color: disabled ? '#a1a1a1' : (isDarkMode ? '#ffffff' : '#000000'),
              }}
              disabled={disabled}
            />
            {suggestions.length > 0 ? (


              <Box sx={{
                position: 'relative',
                backgroundColor: isDarkMode ? '#292E34' : 'white',
                boxShadow: '0 2px 8px rgba(0,0,0,0.15)',
                zIndex: 1,
                top: '35px',
                left: '0px',
                maxHeight: '250px',
                overflowY: 'scroll',
                // marginTop: '4px',
                width: '100%',
                color: isDarkMode ? '#ffffff' : '#000000',
              }}>

                <Popper
                  open={open}
                  anchorEl={anchorEl}
                  placement="bottom-start"
                  style={{
                    zIndex: 1,
                    position: 'absolute',
                    backgroundColor: isDarkMode ? '#292E34' : 'white',
                    boxShadow: '0 2px 8px rgba(0,0,0,0.15)',
                    top: '35px',
                    left: '0px',
                    maxHeight: '250px',
                    overflowY: 'scroll',
                    // marginTop: '4px',
                    width: 'auto',
                    color: isDarkMode ? '#ffffff' : '#000000',
                  }}
                  nonce={undefined}
                  onResize={undefined}
                  onResizeCapture={undefined} >
                  <Paper>
                    <Box sx={{ maxHeight: '250px', overflowY: 'auto', width: inputRef.current ? inputRef.current.clientWidth : 'auto' }}>
                      {suggestions.map((suggestion, index) => (
                        <MenuItem
                          key={suggestion.serial_number}
                          onClick={() => handleSuggestionClick(suggestion)}
                          selected={activeSuggestionIndex === index}
                          onMouseEnter={() => setActiveSuggestionIndex(index)}
                          style={{
                            cursor: suggestion.status === 'issued' || suggestion.status === 'pending' ? 'not-allowed' : 'pointer',
                            backgroundColor: activeSuggestionIndex === index ? (isDarkMode ? '#394047' : '#f5f5f5') : (isDarkMode ? '#292E34' : 'white'),
                            color: suggestion.status === 'issued' ? 'orange' : (suggestion.status === 'pending' ? 'royalblue' : (isDarkMode ? '#ffffff' : '#000000')),
                          }}
                        >
                          {suggestion.serial_number}
                          {suggestion.is_used ? <Chip
                            label="Old Stock"
                            color='warning'
                            size="small"
                            sx={{
                              ml: 1,
                              fontSize: '0.8rem',
                              height: '22px',
                              minWidth: '50px',
                            }}
                          />
                            : ''}
                        </MenuItem>
                      ))}
                    </Box>
                  </Paper>
                </Popper>
              </Box>


              // <div
              //   style={{
              //     position: 'absolute',
              //     backgroundColor: isDarkMode ? '#292E34' : 'white',
              //     boxShadow: '0 2px 8px rgba(0,0,0,0.15)',
              //     zIndex: 1,
              //     top: '35px',
              //     left: '0px',
              //     maxHeight: '250px',
              //     overflowY: 'scroll',
              //     // marginTop: '4px',
              //     width: '100%',
              //     color: isDarkMode ? '#ffffff' : '#000000',
              //   }}
              // >
              //   {suggestions.map((suggestion, index) => (
              //     <div
              //       key={suggestion.serial_number}
              //       onClick={() => handleSuggestionClick(suggestion)}
              //       style={{
              //         padding: '8px',
              //         cursor: suggestion.status === 'issued' || suggestion.status === 'pending' ? 'not-allowed' : 'pointer',
              //         backgroundColor: activeSuggestionIndex === index ? (isDarkMode ? '#394047' : '#f5f5f5') : (isDarkMode ? '#292E34' : 'white'),
              //         borderBottom: '1px solid #f0f0f0',
              //         color: suggestion.status === 'issued' ? 'orange' : (suggestion.status === 'pending' ? 'royalblue' : (isDarkMode ? '#ffffff' : '#000000')),
              //       }}
              //       onMouseEnter={() => setActiveSuggestionIndex(index)}
              //     >
              //       {suggestion.serial_number}
              //     </div>
              //   ))}
              // </div>
            ) : (
              noResults && (
                <Box sx={{
                  position: 'relative',
                  backgroundColor: isDarkMode ? '#292E34' : 'white',
                  boxShadow: '0 2px 8px rgba(0,0,0,0.15)',
                  zIndex: 1,
                  top: '35px',
                  left: '0px',
                  maxHeight: '250px',
                  overflowY: 'scroll',
                  // marginTop: '4px',
                  width: '100%',
                  color: isDarkMode ? '#ffffff' : '#000000',
                }}>

                  <Popper
                    open={open}
                    anchorEl={anchorEl}
                    placement="bottom-start"
                    style={{
                      zIndex: 1,
                      position: 'absolute',
                      backgroundColor: isDarkMode ? '#292E34' : 'white',
                      boxShadow: '0 2px 8px rgba(0,0,0,0.15)',
                      top: '35px',
                      left: '0px',
                      maxHeight: '250px',
                      overflowY: 'scroll',
                      // marginTop: '4px',
                      width: 'auto',
                      color: isDarkMode ? '#ffffff' : '#000000',
                    }}
                    nonce={undefined}
                    onResize={undefined}
                    onResizeCapture={undefined} >
                    <Paper>
                      <Box sx={{ maxHeight: '250px', overflowY: 'auto', width: inputRef.current ? inputRef.current.clientWidth : 'auto' }}>
                        <MenuItem
                          onClick={() => setNoResults(false)}
                        >
                          No result
                        </MenuItem>
                      </Box>
                    </Paper>
                  </Popper>
                </Box>

                // <div
                //   style={{
                //     position: 'absolute',
                //     top: '35px',
                //     left: '0px',
                //     backgroundColor: isDarkMode ? '#292E34' : 'white',
                //     boxShadow: '0 2px 8px rgba(0,0,0,0.15)',
                //     zIndex: 1,
                //     maxHeight: '250px',
                //     overflowY: 'scroll',
                //     // marginTop: '4px',
                //     width: '100%',
                //     padding: '8px',
                //     textAlign: 'center',
                //     color: isDarkMode ? '#ffffff' : '#000000',
                //   }}
                //   onClick={() => setNoResults(false)}
                // >
                //   No results found
                // </div>
              )
            )}
          </>
        )}
      </div>
    </ClickAwayListener>
  );
});

export default CustomMultiInput;
