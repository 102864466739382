import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  Grid,
  InputAdornment,
  IconButton,
  FormControl,
  Input,
  Box,
  Typography,
  Divider,
  FormControlLabel,
  Checkbox,
  FormLabel,
  Tooltip,
  Stack,
  Button,
  Autocomplete,
  Alert,
  TextField,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  ListSubheader,
  FormHelperText,
  Card,
} from '@mui/material';
import { IconBeach, IconBriefcase, IconEdit, IconMessage, IconPhoto, IconTrash, IconUpload } from '@tabler/icons';
import CustomTextField from 'src/components/theme-elements/CustomTextField';
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';
import axiosClient from 'src/axios_client';
import { MRT_ColumnDef, MRT_ColumnFiltersState, MRT_PaginationState, MRT_SortingState, MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { Close, RefreshOutlined } from '@mui/icons-material';
import { isError } from 'lodash';
import { QueryClient, QueryClientProvider, keepPreviousData, useQuery } from '@tanstack/react-query';
import { NumericFormat, NumericFormatProps } from 'react-number-format';
import { any } from 'prop-types';
import CustomCheckbox from 'src/components/theme-elements/CustomCheckbox';
import { NumericFormatCustom, adjustAndFormat, formatCurrency } from 'src/globals/NumericFormat';
import BlankCard from 'src/components/shared/BlankCard';
import * as Yup from 'yup';

type Adjustment = {
  id: number;
  product_id: number;
  product_name: string;
  sku: string;
  is_price_adjustment: number;
  is_quantity_adjustment: number;
  previous_selling: number,
  previous_buying: number;
  status: any;
  reason: string;
};

type AdjustmentApiResponse = {
  data: Array<Adjustment>;
  pagination: {
    total: number;
    per_page: number;
    current_page: number;
    last_page: number;
    from: number;
    to: number;
  };
};

type AdjustmentOptions = {
  id: string,
  name: any,
  sku: any,
  has_serial: any,
  unit: any,
  description: any,
  image: any,
  buying_price: any,
  selling_price: any,
  qty_before: any,
  quantity: any
};


const validationSchema = Yup.object().shape({
  product_id: Yup.string().required('Please provide product'),
  reason: Yup.string().required('Please provide a valid reason'),
  reference_number: Yup.string().required('Please provide a reference number'),
  transaction_number: Yup.string().required('Please provide a transaction number'),
  is_price_adjustment: Yup.number().test(
    'oneOfRequired',
    'Either Price Adjustment or Quantity Adjustment must be checked',
    function (value) {
      const { is_quantity_adjustment } = this.parent;
      return value === 1 || is_quantity_adjustment === 1;
    }
  ),
  is_quantity_adjustment: Yup.number().test(
    'oneOfRequired',
    'Either Price Adjustment or Quantity Adjustment must be checked',
    function (value) {
      const { is_price_adjustment } = this.parent;
      return value === 1 || is_price_adjustment === 1;
    }
  ),
  requested_selling: Yup.number().when('is_price_adjustment', {
    is: 1,
    then: Yup.number().required('Selling Price is required when price adjustment is checked'),
  }),
  requested_buying: Yup.number().when('is_price_adjustment', {
    is: 1,
    then: Yup.number().required('Buying Price is required when price adjustment is checked'),
  }),
  requested_quantity: Yup.number().when('is_quantity_adjustment', {
    is: 1,
    then: Yup.number().required('Quantity is required when quantity adjustment is checked'),
  }),
});

const MainComponent = () => {


  const [selectedFiles, setSelectedFiles] = useState<{ file: File; preview: string }[]>([]);
  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(e.target.files || []);
    if (files.length + selectedFiles.length > 10) {
      alert('You can only upload up to 10 files.');
      return;
    }
    const newFiles = files.map(file => ({
      file,
      preview: URL.createObjectURL(file),
    }));
    setSelectedFiles(prev => [...prev, ...newFiles]);
  };
  const handleDeleteFile = (index: number) => {
    setSelectedFiles(prev => {
      const newFiles = [...prev];
      newFiles.splice(index, 1);
      return newFiles;
    });
  };
  const [isSearching, setIsSearching] = useState(false);
  const [isEmptySearch, setIsEmptySearch] = useState(false);
  const debounceTimeout = useRef<ReturnType<typeof setTimeout> | null>(null);
  const [productOptions, setProductOptions] = useState<AdjustmentOptions[]>([]);
  const [adjustmentList, setAdjustmentList] = useState<any>([]);
  const adjustmentListRef = useRef(adjustmentList);
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const [sucess, setSucess] = useState('');
  const [error, setError] = useState('');
  const [isQuantityAdjustment, setIsQuantityAdjustment] = useState(true);
  const [isPriceAdjustment, setIsPriceAdjustment] = useState(true);

  const formik = useFormik({
    enableReinitialize: false,
    initialValues: {
      product_id: '',
      product_name: '',
      product_description: '',
      product_image: '',
      current_buying: '',
      current_selling: '',
      current_quantity: '',
      requested_selling: 0,
      requested_buying: 0,
      requested_quantity: 0,
      quantity_before: 0,
      reference_number: '',
      transaction_number: '',
      sku: '',
      unit: '',
      has_serial: 0,
      is_price_adjustment: 1,
      is_quantity_adjustment: 1,
      status: '',
      reason: '',
    },
    validateOnChange: false,
    validationSchema: validationSchema,
    onSubmit: async (values) => {

      if (formik.isValid) {
        const formattedValues = {
          ...values,
        };

        console.log(adjustmentList);

        axiosClient.post(`/adjustments`, formattedValues, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
          .then((response) => {

            if (response.status === 201) {
              console.log(response.data.data.message);
              setSucess(response.data.message);
              setError('');
              setSelectedFiles([]);

              setTimeout(() => {
                setSucess('');
              }, 5000);
              formik.resetForm();
            }
            console.log(response);
          })
          .catch((error) => {
            setError(error.response.data.message);
            console.log(error);
          });
      }
      else {
        console.log("Form invalid");
      }
    },
  });
  const autocompleteSearchChange = (searchText: any, apiText: any) => {
    setIsSearching(true);
    setIsEmptySearch(false);
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }
    debounceTimeout.current = setTimeout(() => {
      const value = searchText.target.value;
      fetchOptions(value, apiText);
    }, 500);
  };

  const fetchOptions = async (searchText: string, apiText: string) => {
    try {
      const filter = [
        { "id": "name", "value": searchText },
        { "id": "is_adjusting", "value": 1 }
      ];
      const { status, data } = await axiosClient.get(`/${apiText}`, {
        params: { filters: JSON.stringify(filter), per_page: 10 },
      });
      if (status == 200) {


        setIsSearching(false);
        if (data.data.length == 0) {
          setIsEmptySearch(true);
        }

        console.log(data.data);
        const fetchedData = data.data.map((innerData: any) => ({
          id: innerData.id,
          name: innerData.name,
          description: innerData.description,
          has_serial: innerData.has_serial,
          image: innerData.image,
          unit: innerData.unit,
          sku: innerData.sku,
          qty_before: innerData.qty_before,
          buying_price: innerData.buying_price,
          selling_price: innerData.selling_price,
          quantity: innerData.quantity
        }));
        setProductOptions(fetchedData);

      }

    } catch (error) {
      console.error('Failed to fetch product options:', error);
    }
  };

  // const {
  //   data: { data = [], pagination: meta } = {},
  //   isError,
  //   isRefetching,
  //   isLoading,
  //   refetch,
  // } = useQuery<AdjustmentApiResponse>({
  //   queryKey: [
  //     'table-data',
  //     columnFilters,
  //     globalFilter,
  //     pagination.pageIndex,
  //     pagination.pageSize,
  //     sorting,
  //   ],
  //   queryFn: async () => {
  //     try {
  //       const fetchURL = new URL(`${process.env.REACT_APP_API_SERVER}/adjustments`);
  //       fetchURL.searchParams.set('page', `${pagination.pageIndex + 1}`);
  //       fetchURL.searchParams.set('per_page', `${pagination.pageSize}`);
  //       fetchURL.searchParams.set('filters', JSON.stringify(columnFilters ?? []));
  //       fetchURL.searchParams.set('globalFilter', globalFilter ?? '');
  //       fetchURL.searchParams.set('sorting', JSON.stringify(sorting ?? []));

  //       const response = await axiosClient.get(fetchURL.href);
  //       const json = response.data as AdjustmentApiResponse;

  //       console.log(json);

  //       return json;
  //     } catch (error) {
  //       console.error('Error fetching data:', error);
  //       throw error;
  //     }
  //   },
  //   placeholderData: keepPreviousData,
  // });
  // const columns = useMemo<MRT_ColumnDef<Adjustment>[]>(
  //   () => [
  //     {
  //       accessorKey: 'id',
  //       header: 'DB ID',
  //       minSize: 10,
  //       maxSize: 10,
  //       size: 50,
  //     },
  //     {
  //       accessorKey: 'product_name',
  //       header: 'Product Name',
  //       size: 180,
  //     },
  //     {
  //       accessorKey: 'sku',
  //       header: 'SKU no.',
  //       size: 180,
  //     },
  //     {
  //       accessorKey: 'status',
  //       header: 'Status',
  //       minSize: 10,
  //       maxSize: 10,
  //       size: 10,
  //     },
  //     {
  //       accessorKey: 'created_at',
  //       header: 'Adjustment Date',
  //       size: 10,
  //     }
  //   ],
  //   [],
  // );
  const handleProduct = useCallback((record: any) => {
    console.log("edit clicked");
  }, []);
  const handleDeleteProduct = useCallback((id: any) => {
    console.log('Delete action for record ID:', id);
  }, []);

  // const table = useMaterialReactTable({
  //   columns,
  //   data,
  //   initialState: { showColumnFilters: true },
  //   manualFiltering: true,
  //   manualPagination: true,
  //   manualSorting: true,
  //   muiToolbarAlertBannerProps: isError
  //     ? {
  //       color: 'error',
  //       children: 'Error loading data',
  //     }
  //     : undefined,
  //   onColumnFiltersChange: setColumnFilters,
  //   onGlobalFilterChange: setGlobalFilter,
  //   onPaginationChange: setPagination,
  //   onSortingChange: setSorting,
  //   enableColumnOrdering: true,
  //   renderTopToolbarCustomActions: () => (
  //     <Box sx={{ display: 'flex', gap: '1rem', p: '4px', alignItems: 'center' }}>
  //       <Tooltip arrow title="Refresh Data">
  //         <IconButton onClick={() => refetch()}>
  //           <RefreshOutlined />
  //         </IconButton>
  //       </Tooltip>
  //       <Typography variant='h5'>Adjustment List</Typography>
  //     </Box>
  //   ),
  //   rowCount: meta?.total ?? 0,
  //   state: {
  //     columnFilters,
  //     globalFilter,
  //     isLoading,
  //     pagination,
  //     showAlertBanner: isError,
  //     showProgressBars: isRefetching,
  //     sorting,
  //   },
  // });


  const handleQuantityCheckbox = (event: any) => {
    setIsQuantityAdjustment(event.target.checked);
    if (event.target.checked) {
      formik.setFieldValue('is_quantity_adjustment', 1);
    }
    else {
      formik.setFieldValue('requested_quantity', 0);
      formik.setFieldValue('is_quantity_adjustment', 0);
    }
  }

  const handlePriceCheckbox = (event: any) => {
    setIsPriceAdjustment(event.target.checked);
    if (event.target.checked) {
      formik.setFieldValue('is_price_adjustment', 1);
    }
    else {
      formik.setFieldValue('requested_buying', 0);
      formik.setFieldValue('requested_selling', 0);
      formik.setFieldValue('is_price_adjustment', 0);
    }
  }

  const addAdjustmentClick = async () => {
    const errors = await formik.validateForm();
    formik.setTouched({ ['reason']: true, ['reference_number']: true, ['transaction_number']: true, ['product_id']: true, ['requested_buying']: true, ['requested_selling']: true, ['requested_quantity']: true });
    console.log(formik.values.current_quantity);
    if (Object.keys(errors).length === 0) {
      const detectUnique = adjustmentList.some((item: any) => item.product_id == formik.values.product_id);

      if (detectUnique) {
        setError("Duplicate Product is selected. Please select different product");
      }
      else {
        setError('');

        setAdjustmentList([...adjustmentList, formik.values]);
        formik.setValues({
          ...formik.values,
          product_id: '',
          current_buying: '',
          current_selling: '',
          current_quantity: '',
          requested_selling: 0,
          requested_buying: 0,
          requested_quantity: 0,
          sku: '',
        });
      }

    }
    console.log(errors);
  };

  useEffect(() => {

    adjustmentListRef.current = adjustmentList;

  }, [adjustmentList])

  const postTransaction = () => {

    if (formik.values.reference_number && formik.values.transaction_number && formik.values.reason && adjustmentList.length > 0) {

      const formData = new FormData();
      formData.append('reason', formik.values.reason);
      formData.append('reference_number', formik.values.reference_number);
      formData.append('transaction_number', formik.values.transaction_number);

      // Append adjustments data
      adjustmentList.forEach((adjustment: any, index: any) => {
        Object.keys(adjustment).forEach(key => {
          formData.append(`arrayAdjustments[${index}][${key}]`, adjustment[key]);
        });
      });

      axiosClient.post(`/adjustments`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
        .then((response) => {

          if (response.status === 201) {
            setSucess(response.data.message);
            setError('');
            setSelectedFiles([]);
            setAdjustmentList([]);
            setTimeout(() => {
              setSucess('');
            }, 5000);
            formik.resetForm();
          }
          console.log(response);
        })
        .catch((error) => {
          setError(error.response.data.message);
          console.log(error);
        });

    } else {
      setError('Please check fields and adjustment list to proceed');
    }
  }
  const handleRemoveClick = React.useCallback((passedAdj: any) => {
    removeAdjustment(passedAdj.product_id)
  }, []);

  const removeAdjustment = (id: any) => {

    const updatedArray = adjustmentListRef.current.filter((item: any) => item.product_id !== id);
    setAdjustmentList(updatedArray);
  }

  return (
    <Box>
      <form onSubmit={formik.handleSubmit}>
        <Grid container padding={3} columnSpacing={2}>
          <Grid item sm={12} lg={4}>
            {sucess &&
              <Alert
                variant="filled"
                severity="success"
                sx={{ alignItems: "center" }}
              >
                {sucess}
              </Alert>
            }

            {error &&
              <Alert
                variant="filled"
                severity="error"
                sx={{ alignItems: "center" }}
              >
                {error}
              </Alert>
            }
            <FormLabel component="legend">
              <Typography variant={'h4'}>Adjustment Details</Typography>
            </FormLabel>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <FormControlLabel
                  control={
                    <CustomCheckbox
                      checked={isPriceAdjustment}
                      color="primary"
                      onChange={handlePriceCheckbox}
                      inputProps={{ 'aria-label': 'checkbox with default color' }}
                    />
                  }
                  label="Price Adjustment"
                />
              </Grid>
              <Grid item xs={6}>
                <FormControlLabel
                  control={
                    <CustomCheckbox
                      checked={isQuantityAdjustment}
                      color="primary"
                      onChange={handleQuantityCheckbox}
                      inputProps={{ 'aria-label': 'checkbox with default color' }}
                    />
                  }
                  label="Quantity Adjustment"
                />
              </Grid>
              {
                !isQuantityAdjustment && !isPriceAdjustment ?
                  <Grid item xs={12}>
                    <FormHelperText sx={{ color: 'red' }}>{formik.errors.is_price_adjustment}</FormHelperText>
                  </Grid> : null
              }
              <Grid item xs={12}>
                <CustomTextField
                  id="reference_number"
                  size="small"
                  variant="standard"
                  label="Reference Number"
                  value={formik.values.reference_number}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.reference_number && Boolean(formik.errors.reference_number)}
                  helperText={formik.touched.reference_number && formik.errors.reference_number}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <CustomTextField
                  id="transaction_number"
                  size="small"
                  variant="standard"
                  label="Transaction Number"
                  value={formik.values.transaction_number}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.transaction_number && Boolean(formik.errors.transaction_number)}
                  helperText={formik.touched.transaction_number && formik.errors.transaction_number}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <CustomTextField
                  id="reason"
                  size="small"
                  variant="standard"
                  label="Reason of adjustment"
                  multiline
                  maxRows={6}
                  value={formik.values.reason}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.reason && Boolean(formik.errors.reason)}
                  helperText={formik.touched.reason && formik.errors.reason}
                  fullWidth
                />
              </Grid>
            </Grid>


            <FormLabel component="legend" sx={{ mt: 3 }}>
              <Typography variant={'h4'}>Product Adjustment Details</Typography>
            </FormLabel>
            <Grid container spacing={2}>


              <Grid item xs={12}>
                <Autocomplete
                  id="product_id"
                  noOptionsText={isSearching ? 'Searching...' : (isEmptySearch ? `Can't find search word` : 'Type to search')}
                  value={formik.values.product_id || null}
                  onChange={(_, newValue) => {
                    formik.setFieldValue('product_id', newValue);

                    const selectedProduct = productOptions.find(p => p.id === newValue);

                    if (selectedProduct) {
                      formik.setFieldValue('sku', selectedProduct.sku);
                      formik.setFieldValue('unit', selectedProduct.unit);
                      formik.setFieldValue('has_serial', selectedProduct.has_serial);
                      formik.setFieldValue('product_description', selectedProduct.description);
                      formik.setFieldValue('product_image', selectedProduct.image);
                      formik.setFieldValue('product_name', selectedProduct.name);
                      formik.setFieldValue('current_buying', selectedProduct.buying_price ? selectedProduct.buying_price : 0);
                      formik.setFieldValue('current_selling', selectedProduct.selling_price ? selectedProduct.selling_price : 0);
                      formik.setFieldValue('current_quantity', selectedProduct.qty_before ? selectedProduct.qty_before : 0);

                      // formik.setFieldValue('current_quantity', selectedProduct.quantity ? selectedProduct.quantity : 0);

                      formik.setFieldValue('requested_buying', selectedProduct.buying_price ? selectedProduct.buying_price : 0);
                      // formik.setFieldValue('requested_selling', selectedProduct.selling_price ? selectedProduct.selling_price : 0);
                      formik.setFieldValue('requested_quantity', selectedProduct.quantity ? selectedProduct.quantity : 0);
                    }

                    if (newValue === null) {
                      formik.resetForm();
                      setProductOptions([]);
                    }
                  }}
                  onBlur={() => formik.setFieldTouched('product_id', true)}
                  options={productOptions.map(p => p.id)}
                  fullWidth
                  getOptionLabel={(option) => {
                    return productOptions.find(p => p.id === option)?.name ?? '';
                  }}
                  isOptionEqualToValue={(option, value) => option === value}
                  renderInput={(params) => (
                    <CustomTextField
                      {...params}
                      error={formik.touched.product_id && Boolean(formik.errors.product_id)}
                      helperText={formik.touched.product_id && formik.errors.product_id}
                      onChange={(e: any) => autocompleteSearchChange(e, 'products')}
                      variant="standard"
                      placeholder="Product name"
                      aria-label="Product name"
                    />
                  )}
                />
              </Grid>

              {/* {formik.values.has_serial ?
                <Grid item xs={12}>
                  <Autocomplete
                    id="product_id"
                    noOptionsText={isSearching ? 'Searching...' : (isEmptySearch ? `Can't find search word` : 'Type to search')}
                    value={formik.values.product_id || null}
                    onChange={(_, newValue) => {
                      formik.setFieldValue('product_id', newValue);

                      const selectedProduct = productOptions.find(p => p.id === newValue);

                      if (selectedProduct) {
                        formik.setFieldValue('sku', selectedProduct.sku);
                        formik.setFieldValue('unit', selectedProduct.unit);
                        formik.setFieldValue('has_serial', selectedProduct.has_serial);
                        formik.setFieldValue('product_description', selectedProduct.description);
                        formik.setFieldValue('product_image', selectedProduct.image);
                        formik.setFieldValue('product_name', selectedProduct.name);
                        formik.setFieldValue('current_buying', selectedProduct.buying_price ? selectedProduct.buying_price : 0);
                        formik.setFieldValue('current_selling', selectedProduct.selling_price ? selectedProduct.selling_price : 0);
                        formik.setFieldValue('current_quantity', selectedProduct.quantity ? selectedProduct.quantity : 0);
                      }

                      if (newValue === null) {
                        formik.resetForm();
                        setProductOptions([]);
                      }
                    }}
                    onBlur={() => formik.setFieldTouched('product_id', true)}
                    options={productOptions.map(p => p.id)}
                    fullWidth
                    getOptionLabel={(option) => {
                      return productOptions.find(p => p.id === option)?.name ?? '';
                    }}
                    isOptionEqualToValue={(option, value) => option === value}
                    renderInput={(params) => (
                      <CustomTextField
                        {...params}
                        error={formik.touched.product_id && Boolean(formik.errors.product_id)}
                        helperText={formik.touched.product_id && formik.errors.product_id}
                        onChange={(e: any) => autocompleteSearchChange(e, 'products')}
                        variant="standard"
                        placeholder="Product name"
                        aria-label="Product name"
                      />
                    )}
                  />
                </Grid> : null
              } */}

              <Grid item xs={12}>
                <CustomTextField
                  id="sku"
                  size="small"
                  disabled
                  variant="filled"
                  label="Sku"
                  value={formik.values.sku}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.sku && Boolean(formik.errors.sku)}
                  helperText={formik.touched.sku && formik.errors.sku}
                  fullWidth
                />
              </Grid>

              <Grid item xs={6}>
                <CustomTextField
                  id="current_buying"
                  size="small"
                  disabled
                  variant="filled"
                  label="Buying Price"
                  value={formik.values.current_buying}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.current_buying && Boolean(formik.errors.current_buying)}
                  helperText={formik.touched.current_buying && formik.errors.current_buying}
                  InputProps={{
                    inputComponent: NumericFormatCustom as any,
                    inputProps: {
                      name: "current_buying",
                    },
                  }}
                  fullWidth
                />
              </Grid>

              <Grid item xs={6}>
                <CustomTextField
                  id="requested_buying"
                  size="small"
                  variant="standard"
                  label="Buying Price Adjustment"
                  disabled={!isPriceAdjustment}
                  value={formik.values.requested_buying}
                  onChange={(e: any) => {
                    const reqBuying = parseFloat(e.target.value);
                    formik.setFieldValue("requested_selling", reqBuying + (reqBuying * 0.10));
                    formik.handleChange(e);
                  }}
                  onBlur={formik.handleBlur}
                  error={formik.touched.requested_buying && Boolean(formik.errors.requested_buying)}
                  helperText={formik.touched.requested_buying && formik.errors.requested_buying}
                  InputProps={{
                    inputComponent: NumericFormatCustom as any,
                    inputProps: {
                      name: "requested_buying",
                    },
                  }}
                  fullWidth
                />
              </Grid>

              {/* <Grid item xs={6}>
                <CustomTextField
                  id="current_selling"
                  size="small"
                  disabled
                  variant="filled"
                  label="Selling Price"
                  value={formik.values.current_selling}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.current_selling && Boolean(formik.errors.current_selling)}
                  helperText={formik.touched.current_selling && formik.errors.current_selling}
                  InputProps={{
                    inputComponent: NumericFormatCustom as any,
                    inputProps: {
                      name: "current_selling",
                    },
                  }}
                  fullWidth
                />
              </Grid>

              <Grid item xs={6}>
                <CustomTextField
                  id="requested_selling"
                  size="small"
                  variant="standard"
                  disabled={!isPriceAdjustment}
                  label="Selling Price Adjustment"
                  value={formik.values.requested_selling}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.requested_selling && Boolean(formik.errors.requested_selling)}
                  helperText={formik.touched.requested_selling && formik.errors.requested_selling}
                  InputProps={{
                    inputComponent: NumericFormatCustom as any,
                    inputProps: {
                      name: "requested_selling",
                    },
                  }}
                  fullWidth
                />
              </Grid> */}

              <Grid item xs={6}>
                <CustomTextField
                  id="current_quantity"
                  size="small"
                  disabled
                  variant="filled"
                  label="Quantity"
                  value={formik.values.current_quantity}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.current_quantity && Boolean(formik.errors.current_quantity)}
                  helperText={formik.touched.current_quantity && formik.errors.current_quantity}
                  fullWidth
                />
              </Grid>

              <Grid item xs={6}>
                <CustomTextField
                  id="requested_quantity"
                  size="small"
                  variant="standard"
                  type="number"
                  label="Quantity Adjustment"
                  disabled={!isQuantityAdjustment}
                  value={formik.values.requested_quantity}
                  InputProps={{
                    endAdornment: <InputAdornment position="start">{formik.values.unit}</InputAdornment>,
                  }}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.requested_quantity && Boolean(formik.errors.requested_quantity)}
                  helperText={formik.touched.requested_quantity && formik.errors.requested_quantity}
                  fullWidth
                />
              </Grid>

              {/* <Grid item xs={12}>
                <Typography variant='h6' color={'red'} >Note: input + or - in adjustments to add or deduct. (Eg. +10, -10)</Typography>
              </Grid> */}
              <Grid item xs={6}>
                <Grid container gap={2}>
                  <Button variant="contained" color="primary" onClick={addAdjustmentClick}>
                    Add
                  </Button>
                  <Button variant="contained" color="primary">
                    Reset
                  </Button>
                </Grid>
              </Grid>

            </Grid>

          </Grid>
          <Grid item sm={12} lg={8} flexGrow={1} mt={{ xs: 2, md: 0 }}>
            <Card elevation={6}>
              <Grid item xs={12} sm={12} padding={2}>
                <Stack direction="row" spacing={2}>
                  <Button variant="contained" color="primary" onClick={postTransaction}>
                    POST
                  </Button>
                  <Button variant="text" color="error">
                    CLEAR
                  </Button>
                </Stack>
              </Grid>
              <List
                subheader={
                  <ListItem >
                    <ListItemAvatar >
                      <Box width={20} height={20} >
                      </Box>
                    </ListItemAvatar>
                    <Grid container columnSpacing={1}>
                      <Grid item xs={3}>
                        <ListItemText primary="Item Details" />
                      </Grid>
                      <Grid item xs={3}>
                        <ListItemText primary="Quantity" />
                      </Grid>
                      <Grid item xs={3}>
                        <ListItemText primary="Selling Price" />
                      </Grid>
                      <Grid item xs={3}>
                        <ListItemText primary="Buying Price" />
                      </Grid>

                    </Grid>

                  </ListItem>
                }
              >

                {
                  adjustmentList.map(
                    (adj: any) => {
                      return (
                        <>
                          <AdjustmentListComponent adjustmentDetails={adj} onRemoveClick={handleRemoveClick} />
                        </>
                      )
                    }
                  )
                }


              </List>
            </Card>
            {/* <MaterialReactTable table={table} /> */}
          </Grid>
        </Grid>

        {/* <Grid container>
          <Grid item xs={12} my={3}>
            <Divider sx={{ mx: "-24px" }} />
          </Grid>
        </Grid> */}
        {/*SEPARATOR LINE*/}

        {/*---------- File Upload Section ----------*/}
        {/* <Grid container padding={3}>
          <Grid item sm={12} lg={4}>
            <FormLabel component="legend" sx={{ mb: '15px' }}>
              Attach File(s) to inventory adjustment (up to 10)
            </FormLabel>
            <FormControl fullWidth>
              <Input
                id="file-upload"
                type="file"
                inputProps={{ multiple: true }}
                onChange={handleFileUpload}
                endAdornment={
                  <InputAdornment position="end">
                    <Tooltip title="Upload Files">
                      <IconButton component="label" htmlFor="file-upload">
                        <IconUpload />
                      </IconButton>
                    </Tooltip>
                  </InputAdornment>
                }
              />
            </FormControl>
            <FormLabel component="legend" sx={{ mb: '15px' }}>
              You can upload a maximum of 5 files, 10MB each
            </FormLabel>
            <Box mt={3}>
              {selectedFiles.length > 0 && (
                <Typography variant="body1" sx={{ mb: 1 }}>File Previews:</Typography>
              )}
              <Grid container spacing={2}>
                {selectedFiles.map((fileObj, index) => (
                  <Grid item xs={12} sm={6} md={4} lg={2.4} key={index}>
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                      p={1}
                      border={1}
                      borderColor="grey.300"
                      borderRadius={2}
                      sx={{
                        backgroundColor: 'background.paper',
                        boxShadow: 1,
                      }}
                    >
                      <img
                        src={fileObj.preview}
                        alt={`preview-${index}`}
                        style={{
                          width: '100px',
                          height: '100px',
                          objectFit: 'cover',
                          borderRadius: 4,
                          marginBottom: 8,
                        }}
                      />
                      <Typography
                        variant="body2"
                        noWrap
                        textAlign="center"
                        sx={{ width: '100px' }}
                      >
                        {fileObj.file.name}
                      </Typography>
                      <Tooltip title="Delete File">
                        <IconButton
                          edge="end"
                          size="small"
                          onClick={() => handleDeleteFile(index)}
                        >
                          <IconTrash />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Grid>
        </Grid> */}
      </form>


    </Box >
  );
};
const queryClient = new QueryClient();

const AdjustmentListComponent: React.FC<{
  adjustmentDetails: any;
  onRemoveClick: any;
}> = React.memo(({ adjustmentDetails, onRemoveClick }) => {

  console.log(adjustmentDetails);
  const handleRemoveClick = () => {
    onRemoveClick(adjustmentDetails);
  };

  const baseQuant = adjustmentDetails.current_quantity ? adjustmentDetails.current_quantity : 0;
  const reqQuant = adjustmentDetails.requested_quantity ? adjustmentDetails.requested_quantity : 0;

  const baseQuantity = typeof baseQuant === 'string' ? parseFloat(baseQuant) : baseQuant;
  const quantityAdjustment = typeof reqQuant === 'string' ? parseFloat(reqQuant) : reqQuant;

  return (
    <>
      <ListItem
        secondaryAction={
          <IconButton edge="end" aria-label="comments" onClick={handleRemoveClick}>
            <Close width={20} height={20} />
          </IconButton>
        }
      >
        <ListItemAvatar>
          <Avatar>
            <img src={adjustmentDetails.product_image} height={'auto'} width={'100%'}></img>
          </Avatar>
        </ListItemAvatar>
        <Grid container columnSpacing={1}>
          <Grid item xs={3}>
            <ListItemText primary={adjustmentDetails.product_name} secondary={adjustmentDetails.product_description} />
          </Grid>
          <Grid item xs={3}>
            <ListItemText
              primary={
                <>
                  <Typography variant="body2">
                    Current: {adjustmentDetails.current_quantity}
                  </Typography>
                  <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                    Adjustment: {adjustmentDetails.requested_quantity ? adjustmentDetails.requested_quantity : 0}
                  </Typography>
                  {/* <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                    New Quantity: {baseQuantity + quantityAdjustment}
                  </Typography> */}
                </>
              }
            />
          </Grid>
          <Grid item xs={3}>
            <ListItemText
              primary={
                <>
                  <Typography variant="body2">
                    Current: {formatCurrency(adjustmentDetails.current_selling)}
                  </Typography>
                  <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                    Adjustment: {formatCurrency(parseFloat(adjustmentDetails.requested_selling))}
                  </Typography>
                  {/* <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                    New Price: {adjustAndFormat(adjustmentDetails.current_selling, adjustmentDetails.requested_selling)}
                  </Typography> */}
                </>
              }
            />
          </Grid>
          <Grid item xs={3}>
            <ListItemText
              primary={
                <>
                  <Typography variant="body2">
                    Current: {formatCurrency(adjustmentDetails.current_buying)}
                  </Typography>
                  <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                    Adjustment: {formatCurrency(adjustmentDetails.requested_buying)}
                  </Typography>
                  {/* <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                    New Price: {adjustAndFormat(adjustmentDetails.current_buying, adjustmentDetails.requested_buying)}
                  </Typography> */}
                </>
              }
            />
          </Grid>
        </Grid>
      </ListItem>
      <Divider component="li" />
    </>
  );
});
const FormSeparator = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <MainComponent />
    </QueryClientProvider>
  );
};
export default FormSeparator;
