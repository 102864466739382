import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Box,
  DialogActions,
  Button,
} from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';
import CustomTextField from 'src/components/theme-elements/CustomTextField';
import axiosClient from 'src/axios_client';
import StatusAlert from 'src/components/shared/AlertResponse'; // Adjust the import path as per your directory structure

const validationSchema = yup.object({
  name: yup.string().required('Name is required'),
  address: yup.string().required('Address is required'),
  contact_person: yup.string(),
  mobile_number: yup.string(),
  telephone_number: yup.string(),
  payment_terms_id: yup.string(),
});

type DialogFormProps = {
  isOpen: boolean;
  isEditing: boolean;
  initialData: {
    id: number;
    name: string;
    address: string;
    contact_person: string;
    mobile_number: string;
    telephone_number: string;
    payment_terms_id: string;
  } | null;
  onClose: () => void;
  onSubmit: (payload: {
    id: number | null;
    name: string;
    contact_person: string;
    mobile_number: string;
    telephone_number: string;
    payment_terms_id: string;
  }) => void;
  refetch: () => void;
};

const DialogForm: React.FC<DialogFormProps> = ({
  isOpen,
  isEditing,
  initialData,
  onClose,
  onSubmit,
  refetch,
}) => {
  const [statusCode, setStatusCode] = useState(0);
  const [statusData, setStatusData] = useState([]);

  let apiResponse = 0;
  const formik = useFormik({
    initialValues: {
      id: initialData?.id || null,
      name: initialData?.name || '',
      address: initialData?.address || '',
      contact_person: initialData?.contact_person || '',
      mobile_number: initialData?.mobile_number || '',
      telephone_number: initialData?.telephone_number || '',
      payment_terms_id: initialData?.payment_terms_id || '',
    },
    validationSchema,
    onSubmit: async (values, { resetForm }) => {
      const payload = {
        id: isEditing ? values.id : 0,
        name: values.name,
        address: values.address,
        contact_person: values.contact_person,
        mobile_number: values.mobile_number,
        telephone_number: values.telephone_number,
        payment_terms_id: values.payment_terms_id,
      };

      try {
        if (isEditing) {
          const response = await axiosClient.put(`/suppliers/${payload?.id}`, {
            name: payload.name,
            address: payload.address,
            contact_person: payload.contact_person,
            mobile_number: payload.mobile_number,
            telephone_number: payload.telephone_number,
            payment_terms_id: payload.payment_terms_id,
          });

          const updatedSupplier = response.data;

          console.log('Server Response:', updatedSupplier);
          apiResponse = response.status;
        } else {
          const response = await axiosClient.post('/suppliers', {
            name: payload.name,
            address: payload.address,
            contact_person: payload.contact_person,
            mobile_number: payload.mobile_number,
            telephone_number: payload.telephone_number,
            payment_terms_id: payload.payment_terms_id,
          });

          const addedMsp = response.data;
          console.log('Server Response:', addedMsp);
          apiResponse = response.status;
        }

        resetForm();

        refetch();

        onClose();
      } catch (error: any) {
        if (error.response && error.response.status === 422) {
          const errorMessage = error.response.data;
          apiResponse = error.response.status;

          setStatusData(errorMessage);
        } else if (error.response && error.response.status) {
          apiResponse = error.response.status;
        }
      }
      setStatusCode(apiResponse);
      onSubmit(payload);
    },
  });

  useEffect(() => {
    formik.setValues({
      id: initialData?.id || null,
      name: initialData?.name || '',
      address: initialData?.address || '',
      contact_person: initialData?.contact_person || '',
      mobile_number: initialData?.mobile_number || '',
      telephone_number: initialData?.telephone_number || '',
      payment_terms_id: initialData?.payment_terms_id || '',
    });
  }, [initialData]);

  return (
    <>
      {(statusCode || statusCode !== 0) && (
        <StatusAlert statusCode={statusCode} statusData={statusData} />
      )}
      <Dialog open={isOpen} onClose={onClose}>
        {/* Render StatusAlert if statusCode is set */}
        <form onSubmit={formik.handleSubmit}>
          <DialogTitle>
            {isEditing ? `Edit Supplier [${initialData?.name}]` : 'Add Supplier'}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>You are editing the device setup.</DialogContentText>
            <Box mt={2}>
              <CustomTextField
                autoFocus
                margin="dense"
                id="name"
                name="name"
                label="Name"
                type="text"
                fullWidth
                value={formik.values.name}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />

              {/* <CustomTextField
                margin="dense"
                id="code"
                name="code"
                label="Code"
                type="text"
                fullWidth
                value={formik.values.code}
                error={formik.touched.code && Boolean(formik.errors.code)}
                helperText={formik.touched.code && formik.errors.code}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              /> */}
              
              <CustomTextField
                margin="dense"
                id="address"
                name="address"
                label="Address"
                type="text"
                fullWidth
                value={formik.values.address}
                error={formik.touched.address && Boolean(formik.errors.address)}
                helperText={formik.touched.address && formik.errors.address}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <CustomTextField
                margin="dense"
                id="contact_person"
                name="contact_person"
                label="Contact Person"
                type="text"
                fullWidth
                value={formik.values.contact_person}
                error={formik.touched.contact_person && Boolean(formik.errors.contact_person)}
                helperText={formik.touched.contact_person && formik.errors.contact_person}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <CustomTextField
                margin="dense"
                id="mobile_number"
                name="mobile_number"
                label="Mobile Number"
                type="text"
                fullWidth
                value={formik.values.mobile_number}
                error={formik.touched.mobile_number && Boolean(formik.errors.mobile_number)}
                helperText={formik.touched.mobile_number && formik.errors.mobile_number}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <CustomTextField
                margin="dense"
                id="telephone_number"
                name="telephone_number"
                label="Telephone Number"
                type="text"
                fullWidth
                value={formik.values.telephone_number}
                error={formik.touched.telephone_number && Boolean(formik.errors.telephone_number)}
                helperText={formik.touched.telephone_number && formik.errors.telephone_number}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <CustomTextField
                margin="dense"
                id="payment_terms_id"
                name="payment_terms_id"
                label="Payment Terms"
                type="text"
                fullWidth
                value={formik.values.payment_terms_id}
                error={formik.touched.payment_terms_id && Boolean(formik.errors.payment_terms_id)}
                helperText={formik.touched.payment_terms_id && formik.errors.payment_terms_id}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </Box>
          </DialogContent>
          <DialogActions sx={{ mb: 2, mr: 2 }}>
            <Button color="error" onClick={onClose}>
              Cancel
            </Button>
            <Button type="submit">{isEditing ? 'Update' : 'Add'}</Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default React.memo(DialogForm);
