import { Alert, Box, Button, Card, Divider, Grid, IconButton, InputAdornment, TextField, Tooltip, useMediaQuery, useTheme } from '@mui/material';
import React, { forwardRef, useImperativeHandle, useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux';
import PageContainer from 'src/components/container/PageContainer'
import Breadcrumb from 'src/layouts/full/shared/breadcrumb/Breadcrumb'
import { AppState } from 'src/store/Store';
import AdjustmentPage from '../product-master-adjs/ProductTableListAdj';
import CustomFormLabel from 'src/components/theme-elements/CustomFormLabel';
import CustomTextField from 'src/components/theme-elements/CustomTextField';
import { useFormik } from 'formik';
import { NumericFormatCustom } from 'src/globals/NumericFormat';
import { Percent, RefreshOutlined } from '@mui/icons-material';
import * as Yup from 'yup';
import axiosClient from 'src/axios_client';
import { keepPreviousData, QueryClient, QueryClientProvider, useQuery } from '@tanstack/react-query';
import { MaterialReactTable, MRT_ColumnDef, MRT_ColumnFiltersState, MRT_PaginationState, MRT_SortingState, useMaterialReactTable } from 'material-react-table';
import { useNavigate } from 'react-router';

type Props = {}
const BCrumb = [
    {
        to: '/',
        title: 'Home',
    },
    {
        title: 'Product Adjustment',
    },
];
const validationSchema = Yup.object().shape({
    starting_price: Yup.string().required('Please provide starting price'),
    ending_price: Yup.string().required('Please provide ending price'),
    percentage: Yup.string().required('Please provide a percentage'),
});

type Markup = {
    id: number;
    starting_price: any;
    ending_price: any;
    percentage: any;
};

type MarkupApiResponse = {
    data: Array<Markup>;
    pagination: {
        total: number;
        per_page: number;
        current_page: number;
        last_page: number;
        from: number;
        to: number;
    };
};

interface MarkupTableRef {
    refetch: () => void;
}

const MarkupTable = forwardRef<MarkupTableRef>((props, ref) => {
    const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>([]);
    const [globalFilter, setGlobalFilter] = useState('');
    const [sorting, setSorting] = useState<MRT_SortingState>([]);

    const navigate = useNavigate();
    const [pagination, setPagination] = useState<MRT_PaginationState>({
        pageIndex: 0,
        pageSize: 10,
    });
    const {
        data: { data = [], pagination: meta } = {},
        isError,
        isRefetching,
        isLoading,
        refetch,
    } = useQuery<MarkupApiResponse>({
        queryKey: [
            'table-data',
            columnFilters,
            globalFilter,
            pagination.pageIndex,
            pagination.pageSize,
            sorting,
        ],
        queryFn: async () => {
            try {
                const fetchURL = new URL(`${process.env.REACT_APP_API_SERVER}/markup`);
                fetchURL.searchParams.set('page', `${pagination.pageIndex + 1}`);
                fetchURL.searchParams.set('per_page', `${pagination.pageSize}`);
                fetchURL.searchParams.set('filters', JSON.stringify(columnFilters ?? []));
                fetchURL.searchParams.set('globalFilter', globalFilter ?? '');
                fetchURL.searchParams.set('sorting', JSON.stringify(sorting ?? []));

                const response = await axiosClient.get(fetchURL.href);
                const json = response.data as MarkupApiResponse;

                console.log(json);

                return json;
            } catch (error) {
                console.error('Error fetching data:', error);
                throw error;
            }
        },
        placeholderData: keepPreviousData,
    });
    const columns = useMemo<MRT_ColumnDef<Markup>[]>(
        () => [
            {
                accessorKey: 'id',
                header: 'ID',
                size: 80
            },
            {
                accessorKey: 'starting_price',
                header: 'Starting Price',
                size: 80
            },
            {
                accessorKey: 'ending_price',
                header: 'Ending Price',
                size: 80
            },
            {
                accessorKey: 'percentage',
                header: 'Percentage',
                size: 80
            },
        ],
        [],
    );

    const table = useMaterialReactTable({
        columns,
        data,
        defaultColumn: {
            minSize: 20, //allow columns to get smaller than default
            maxSize: 9001, //allow columns to get larger than default
            size: 180, //make columns wider by default
        },
        initialState: {
            showColumnFilters: true,
        },
        manualFiltering: true,
        manualPagination: true,
        manualSorting: true,
        muiToolbarAlertBannerProps: isError
            ? {
                color: 'error',
                children: 'Error loading data',
            }
            : undefined,
        onColumnFiltersChange: setColumnFilters,
        onGlobalFilterChange: setGlobalFilter,
        onPaginationChange: setPagination,
        onSortingChange: setSorting,
        enableColumnOrdering: true,
        renderTopToolbarCustomActions: () => (
            <Box sx={{ display: 'flex', gap: '1rem', p: '4px', alignItems: 'center' }}>
                <Tooltip arrow title="Refresh Data">
                    <IconButton onClick={() => refetch()}>
                        <RefreshOutlined />
                    </IconButton>
                </Tooltip>
            </Box>
        ),
        rowCount: meta?.total ?? 0,
        state: {
            columnFilters,
            globalFilter,
            isLoading,
            pagination,
            showAlertBanner: isError,
            showProgressBars: isRefetching,
            sorting,
        },
    });

    useImperativeHandle(ref, () => ({
        refetch: () => {
            if (refetch) {
                refetch();
            }
        },
    }));

    return (
        <>
            <MaterialReactTable table={table} />
        </>
    );
});
const MainComponent = () => {
    const customizer = useSelector((state: AppState) => state.customizer);
    const theme = useTheme();
    const borderColor = theme.palette.grey[100];
    const isSmallScreen = useMediaQuery('(max-width:375px)');

    const [sucess, setSucess] = useState('');
    const [error, setError] = useState('');
    const tableRef = useRef<MarkupTableRef>(null);


    const formik = useFormik({
        initialValues: {
            starting_price: '',
            ending_price: '',
            percentage: '',
        },
        validateOnChange: false,
        validationSchema: validationSchema,
        onSubmit: async (values) => {

            if (formik.isValid) {
                const formattedValues = {
                    ...values,
                };

                axiosClient.post(`/markup`, formattedValues)
                    .then((response) => {
                        if (response.status === 201) {
                            console.log(response.data.data.message);
                            setSucess(response.data.message);
                            setError('');

                            setTimeout(() => {
                                setSucess('');
                            }, 5000);
                            formik.resetForm();
                            if (tableRef.current) {
                                tableRef.current.refetch();
                            }
                        }
                        console.log(response);
                    })
                    .catch((error) => {
                        setError(error.response.data.message);
                        console.log(error);
                    });
            }
            else {
                console.log("Form invalid");
            }
        },
    });



    return (
        <PageContainer title="RKCWMS - Product Adjustment Page" description="this is Sample page">
            {/* breadcrumb */}
            <Breadcrumb title="Product Adjustment Page" items={BCrumb} />
            {/* end breadcrumb */}



            <form onSubmit={formik.handleSubmit}>
                {sucess &&
                    <Alert
                        variant="filled"
                        severity="success"
                        sx={{ alignItems: "center" }}
                    >
                        {sucess}
                    </Alert>
                }
                {error &&
                    <Alert
                        variant="filled"
                        severity="error"
                        sx={{ alignItems: "center" }}
                    >
                        {error}
                    </Alert>
                }
                <Grid container columnSpacing={2}>
                    <Grid item xs={3}>
                        <Grid container rowSpacing={2}>

                            <Grid item xs={12} sm={12} display="flex" alignItems="center">
                                <CustomFormLabel htmlFor="fs-uname" sx={{ mt: 0, }}>
                                    Price Range
                                </CustomFormLabel>
                            </Grid>
                            <Grid item xs={12} sm={5}>
                                <CustomTextField
                                    id="starting_price"
                                    size="small"
                                    variant="standard"
                                    label="Starting Price"
                                    value={formik.values.starting_price}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.starting_price && Boolean(formik.errors.starting_price)}
                                    helperText={formik.touched.starting_price && formik.errors.starting_price}
                                    InputProps={{
                                        inputComponent: NumericFormatCustom as any,
                                        inputProps: {
                                            name: "starting_price",
                                        },
                                    }}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={2} display={{ xs: 'none', md: 'block' }} sx={{ alignSelf: 'center', display: 'flex', justifyContent: 'space-evenly' }}>
                                <Divider sx={{ borderColor: 'black', width: '50%' }} />
                            </Grid>
                            <Grid item xs={12} sm={5}>
                                <CustomTextField
                                    id="ending_price"
                                    size="small"
                                    variant="standard"
                                    label="Ending Price"
                                    value={formik.values.ending_price}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.ending_price && Boolean(formik.errors.ending_price)}
                                    helperText={formik.touched.ending_price && formik.errors.ending_price}
                                    InputProps={{
                                        inputComponent: NumericFormatCustom as any,
                                        inputProps: {
                                            name: "ending_price",
                                        },
                                    }}
                                    fullWidth
                                />
                            </Grid>

                            <Grid item xs={12} md={3}>
                                <TextField
                                    id="percentage"
                                    size="small"
                                    variant="standard"
                                    label="Percentage"
                                    value={formik.values.percentage}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.percentage && Boolean(formik.errors.percentage)}
                                    helperText={formik.touched.percentage && formik.errors.percentage}
                                    InputProps={{
                                        inputProps: {
                                            name: "percentage",
                                        },
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Percent />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container columnSpacing={2}>
                                    <Grid item xs={6}>
                                        <Button variant='contained' fullWidth type='submit' >
                                            Submit
                                        </Button>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Button fullWidth onClick={() => formik.resetForm()} >
                                            Reset
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>

                        </Grid>

                    </Grid>

                    <Grid item xs={9}>
                        <Card sx={{
                            padding: 0,
                            border: !customizer.isCardShadow ? `1px solid ${borderColor}` : 'none',
                            maxWidth: isSmallScreen ? '345px' : 'none',
                        }} style={{ height: '100%', position: 'relative' }}>
                            <MarkupTable ref={tableRef} />
                        </Card>
                    </Grid>
                </Grid>
            </form>



        </PageContainer>
    )
}

const queryClient = new QueryClient();
function Markup({ }: Props) {
    return (
        <QueryClientProvider client={queryClient}>
            <MainComponent />
        </QueryClientProvider>
    );
};
export default Markup