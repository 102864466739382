import React, { createContext, useState, useContext, ReactNode } from 'react';

// Define the shape of your context state
interface TransactionContextType {
  transactionId: number | null;
  setTransactionId: (id: number | null) => void;
}

// Create the context with a default value
const TransactionContext = createContext<TransactionContextType>({
  transactionId: null,
  setTransactionId: () => {

    console.log("Transaction ID received")
  },
});

interface TransactionIdProviderProps {
  children: ReactNode;
}

export const TransactionIdProvider = ({ children }: TransactionIdProviderProps) => {
  const [transactionId, setTransactionId] = useState<number | null>(null);

  return (
    <TransactionContext.Provider value={{ transactionId, setTransactionId }}>
      {children}
    </TransactionContext.Provider>
  );
};

export const useTransaction = () => {
  return useContext(TransactionContext);
};
