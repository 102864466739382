import React, { Suspense, lazy } from 'react';
import { Box } from '@mui/material';
import Breadcrumb from 'src/layouts/full/shared/breadcrumb/Breadcrumb';
import PageContainer from 'src/components/container/PageContainer';

const AuditLogsTable = lazy(() => import('./components/AuditLogsTable'));

const BCrumb = [
  {
    to: '/',
    title: 'Home',
  },
  {
    title: 'Audit Logs Page',
  },
];

const AuditLogs = () => {
  return (
    <PageContainer title="RKCWMS - Audit Logs Page" description="this is Sample page">
      {/* breadcrumb */}
      <Breadcrumb title="AuditLogs Page" items={BCrumb} />
      {/* end breadcrumb */}
      <Suspense fallback={<div>Loading...</div>}>
        <AuditLogsTable />
      </Suspense>
    </PageContainer>
  );
};

export default AuditLogs;
