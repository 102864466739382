import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import Breadcrumb from 'src/layouts/full/shared/breadcrumb/Breadcrumb';
import PageContainer from 'src/components/container/PageContainer';
import DashboardCard from 'src/components/shared/DashboardCard';
import SelectedSupplierPurchaseTable from './SelectedSupplierPurchasesTable';
import { useTransaction } from 'src/contexts/SupplierPurchasesContext';
import { useNavigate } from 'react-router';

const BCrumb = [
  {
    to: '/',
    title: 'Home',
  },
  {
    title: 'Reports',
  },
  {
    to: '/report-purchases-by-supplier',
    title: 'Purchases By Supplier Page',
  },
];

const SelectedSupplierPurchase = () => {

  const { supplierId, dateRange } = useTransaction();
  const navigate = useNavigate();

  console.log("SupplierID: ",supplierId)
  console.log("dateRange: ",dateRange)

  useEffect(() => {
    if (!supplierId) {
      console.warn('No transaction ID found, redirecting to purchase report');
      navigate('/report-purchases-by-supplier');
    }
  }, [supplierId, navigate]);

  if (!supplierId) {
    return null; // or show a loading spinner/message
  }

  return (
    <PageContainer title="RKCWMS - Purchases By Supplier Page" description="this is Sample page">
      {/* breadcrumb */}
      <Breadcrumb title="Purchases By Supplier Page" items={BCrumb} />
      {/* end breadcrumb */}
      <DashboardCard title="Purchases By Supplier Page">
        <Box>
          <SelectedSupplierPurchaseTable supplierId={supplierId} dateRange={dateRange} />
        </Box>
      </DashboardCard>
    </PageContainer>
  );
};

export default SelectedSupplierPurchase;
