import React from 'react';
import { Input, Button } from 'antd';
import { CheckOutlined } from '@mui/icons-material';

interface SerialNumberInputProps {
  serialNumbers: Array<{ id: number; value: string; price: number }>;
  onChange: (
    serialNumbers: Array<{ id: number; value: string; price: number }>,
    removedCount: number,
  ) => void;
  onAllUsed: () => void;
}

const SerialNumberInput: React.FC<SerialNumberInputProps> = ({
  serialNumbers,
  onChange,
  onAllUsed,
}) => {
  const handleRemoveSerialNumber = (index: number) => {
    const newSerialNumbers = [...serialNumbers];
    newSerialNumbers.splice(index, 1);
    onChange(newSerialNumbers, 1);
    if (newSerialNumbers.length === 0) {
      onAllUsed();
    }
  };

  return (
    <div>
      {serialNumbers.map((serialNumber, index) => (
        <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: 8 }}>
          <Input value={serialNumber.value} readOnly style={{ width: '70%' }} />
          <Button
            type="primary"
            onClick={() => handleRemoveSerialNumber(index)}
            icon={<CheckOutlined />}
            style={{ marginLeft: 5 }}
          >
            Used
          </Button>
        </div>
      ))}
    </div>
  );
};

export default SerialNumberInput;
