import React, { useEffect, useState } from 'react';
import { Alert, Box } from '@mui/material';

type StatusAlertProps = {
  statusCode: number;
  statusData: any;
};

type AlertColor = 'success' | 'info' | 'warning' | 'error';

const StatusAlert: React.FC<StatusAlertProps> = ({ statusCode, statusData }) => {
  const [visible, setVisible] = useState(false);

  let severity: AlertColor = 'info';
  let message = '';

  if (statusCode >= 200 && statusCode < 300) {
    severity = 'success';
    message = 'Success!';
  } else if (statusCode >= 400 && statusCode < 500) {
    severity = 'error';
    message = 'Error!';
  }

  useEffect(() => {
    if (statusCode) {
      setVisible(true);
      const timer = setTimeout(() => {
        setVisible(false);
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [statusCode]);

  if (!visible) {
    return null;
  }

  return (
    <Box marginBottom={1}>
      <Alert variant="filled" severity={severity}>
        {message} {severity === 'error' && statusData.message}
      </Alert>
    </Box>
  );
};

export default StatusAlert;
