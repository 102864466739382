import React from 'react';
import Breadcrumb from 'src/layouts/full/shared/breadcrumb/Breadcrumb';
import PageContainer from 'src/components/container/PageContainer';
import FormSeparator from './FormSeparatorAdj';
import BlankCard from 'src/components/shared/BlankCard';
import { Box, Card } from '@mui/material';


const BCrumb = [
    {
        to: '/',
        title: 'Home',
    },
    {
        title: 'Product Adjustment',
    },
];

const ProductAdjustment = () => {
    return (
        <PageContainer title="RKCWMS - Product Adjustment" description="this is Sample page">
            <Card sx={{mt: {xs: 0, md: 5}}} elevation={12}>
                <FormSeparator />
            </Card>
        </PageContainer>
    );
};

export default ProductAdjustment;
