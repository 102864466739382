import React from "react";
import { NumericFormat, NumericFormatProps } from "react-number-format";


interface CustomProps {
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
}

const NumericFormatCustom = React.forwardRef<NumericFormatProps, CustomProps>(
    function NumericFormatCustom(props, ref) {
        const { onChange, ...other } = props;

        return (
            <NumericFormat
                {...other}
                getInputRef={ref}
                onValueChange={(values) => {
                    onChange({
                        target: {
                            name: props.name,
                            value: values.value,
                        },
                    });
                }}
                thousandSeparator
                valueIsNumericString
                prefix="₱"
            />
        );
    },
);

const formatCurrency = (value: any) => {
    const number = typeof value === 'string' ? parseFloat(value) : value;
    if (isNaN(number)) {
        return value;
    }

    return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'PHP',
    }).format(number);
};

const adjustAndFormat = (base: any, adjustment: any) => {
    // Convert string parameters to numbers
    const baseNumber = typeof base === 'string' ? parseFloat(base) : base;
    const adjustmentNumber = typeof adjustment === 'string' ? parseFloat(adjustment) : adjustment;

    if (isNaN(baseNumber) || isNaN(adjustment)) {
        return "Invalid input"; // Return an error message if either input is not a valid number
    }

    const result = baseNumber + adjustmentNumber;
    
    return formatCurrency(result);
};

export { NumericFormatCustom, formatCurrency, adjustAndFormat };