import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import Breadcrumb from 'src/layouts/full/shared/breadcrumb/Breadcrumb';
import PageContainer from 'src/components/container/PageContainer';
import DashboardCard from '../../components/shared/DashboardCard';
import IssuanceEntryFormStatic from './components/IssuanceEntryFormStatic';
import { useTransaction } from 'src/contexts/PurchaseTransactionContext';
import { useNavigate } from 'react-router-dom';
import { useEssentialDataContext } from 'src/contexts/EssentialDataContext';

const BCrumb = [
  {
    to: '/',
    title: 'Home',
  },
  {
    title: 'Issuance Entry View Transaction',
  },
];

const IssuanceEntryViewTransaction: React.FC = () => {
  const { transactionId } = useTransaction();
  const navigate = useNavigate();
  const { userData } = useEssentialDataContext();

  const userlevel = userData?.userlevel;

  console.log('Transaction ID: ', transactionId);
  
  useEffect(() => {
    if (!transactionId) {
      console.warn('No transaction ID found, redirecting to purchase entry');
      navigate('/issuance');
    }
  }, [transactionId, navigate]);

  if (!transactionId) {
    return null; // or show a loading spinner/message
  }

  return (
    <PageContainer title="RKCWMS - Issuance Entry ViewTransaction">
      <Breadcrumb title="Issuance Entry - View Transaction" items={BCrumb} />
      <DashboardCard title="Issuance Entry - View Transaction">
        <Box>
          <IssuanceEntryFormStatic transactionId={transactionId} userlevel={userlevel}/>
        </Box>
      </DashboardCard>
    </PageContainer>
  );
};

export default IssuanceEntryViewTransaction;
