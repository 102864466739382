import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import Breadcrumb from 'src/layouts/full/shared/breadcrumb/Breadcrumb';
import PageContainer from 'src/components/container/PageContainer';
import DashboardCard from 'src/components/shared/DashboardCard';
import SelectedCustomerSalesTable from './SelectedCustomerSalesTable';
import { useTransaction } from 'src/contexts/CustomerSalesContext';
import { useNavigate } from 'react-router';

const BCrumb = [
  {
    to: '/',
    title: 'Home',
  },
  {
    title: 'Reports',
  },
  {
    title: 'Sales By Customer Page',
  },
];

const SelectedCustomerSales = () => {

  const { customerId, dateRange } = useTransaction();
  const navigate = useNavigate();

  console.log("CustomerID: ",customerId)
  console.log("dateRange: ",dateRange)

  useEffect(() => {
    if (!customerId) {
      console.warn('No transaction ID found, redirecting to purchase report');
      navigate('/report-sales-by-customer');
    }
  }, [customerId, navigate]);

  if (!customerId) {
    return null; // or show a loading spinner/message
  }

  return (
    <PageContainer title="RKCWMS - Sales By Customer Page" description="this is Sample page">
      {/* breadcrumb */}
      <Breadcrumb title="Sales By Customer Page" items={BCrumb} />
      {/* end breadcrumb */}
      <DashboardCard title="Sales By Customer Page">
        <Box>
          <SelectedCustomerSalesTable customerId={customerId} dateRange={dateRange} />
        </Box>
      </DashboardCard>
    </PageContainer>
  );
};

export default SelectedCustomerSales;
