import React, { useRef } from 'react';
import { Box, Button, Grid } from '@mui/material';
import Breadcrumb from 'src/layouts/full/shared/breadcrumb/Breadcrumb';
import PageContainer from 'src/components/container/PageContainer';
import DashboardCard from '../../components/shared/DashboardCard';
import { IconFile3d, IconPrinter, IconTrash } from '@tabler/icons';
import { Divider } from 'antd';

import IssuanceReturnsEntryTransactionsTable from './components/IssuanceReturnsEntryTransactionsTable';
import IssuanceReturnsEntryForm from './components/IssuanceReturnsEntryForm';
import { useEssentialDataContext } from 'src/contexts/EssentialDataContext';

const BCrumb = [
  {
    to: '/',
    title: 'Home',
  },
  {
    to: '/purchases',
    title: 'Sales',
  },
  {
    title: 'Returns',
  },
];

const IssuanceReturns = () => {
  const transactionsTableRef = useRef<{ refetch: () => void }>(null);

  const handleRefreshTransactions = () => {
    transactionsTableRef.current?.refetch();
  };

  const issuanceEntryFormRef = useRef<{
    postTransaction: () => void;
    clearTransaction: () => void;
  }>(null);

  const handlePostTransaction = () => {
    issuanceEntryFormRef.current?.postTransaction();
  };

  const handleClearTransaction = () => {
    issuanceEntryFormRef.current?.clearTransaction();
  };

  const { userData } = useEssentialDataContext();
  const userlevel = userData?.userlevel;

  return (
    <PageContainer title="RKCWMS - Returns Page" description="this is Sample page">
      {/* breadcrumb */}
      <Breadcrumb title="Returns" items={BCrumb} />
      {/* end breadcrumb */}

      {userlevel === 'superadmin' || userlevel === 'customer' ? (
        <>
          <DashboardCard
            title="Issuance Returns Entry"
            action={
              <Box>
                <Grid container spacing={1}>
                  <Button
                    style={{ marginRight: '8px' }}
                    onClick={handleClearTransaction}
                    startIcon={<IconTrash />}
                  >
                    Clear Transaction
                  </Button>
                  <Button
                    style={{ marginRight: '8px' }}
                    onClick={handlePostTransaction}
                    startIcon={<IconFile3d />}
                  >
                    Post Transaction
                  </Button>

                  <Button
                    style={{ marginRight: '8px' }}
                    onClick={handlePostTransaction}
                    startIcon={<IconPrinter />}
                  >
                    Print
                  </Button>
                </Grid>
              </Box>
            }
          >
            <Box>
              <IssuanceReturnsEntryForm
                ref={issuanceEntryFormRef}
                onRefreshTransactions={handleRefreshTransactions}
              />
            </Box>
          </DashboardCard>

          <Divider />
        </>
      ) : (
        <></>
      )}

      <DashboardCard title="Transaction History" subtitle="See history of returned issuance">
        <IssuanceReturnsEntryTransactionsTable onRefetchRef={transactionsTableRef} />
      </DashboardCard>
    </PageContainer>
  );
};

export default IssuanceReturns;
