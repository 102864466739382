import React, { useEffect, useMemo, useState, useCallback } from 'react';
import {
  MaterialReactTable,
  useMaterialReactTable,
  type MRT_ColumnDef,
  type MRT_ColumnFiltersState,
  type MRT_PaginationState,
  type MRT_SortingState,
} from 'material-react-table';
import { Tooltip, IconButton, Grid, Button, Box, Skeleton } from '@mui/material';
import { useSelector } from 'src/store/Store';
import { AppState } from 'src/store/Store';
import RefreshIcon from '@mui/icons-material/Refresh';
import {
  QueryClient,
  QueryClientProvider,
  keepPreviousData,
  useQuery,
} from '@tanstack/react-query';
import axiosClient from 'src/axios_client';
import { IconEdit, IconPlus, IconTrash } from '@tabler/icons';
import ManufacturerDialog from './ManufacturerDialog'; // Import the ManufacturerDialog component

type ManufacturersApiResponse = {
  data: Array<Manufacturers>;
  pagination: {
    total: number;
    per_page: number;
    current_page: number;
    last_page: number;
    from: number;
    to: number;
  };
};

type Manufacturers = {
  id: number;
  name: string;
  description: string;
};

const ManufacturersTable = () => {
  const customizer = useSelector((state: AppState) => state.customizer);
  const currentTheme = customizer.activeMode;

  const [isDarkMode, setIsDarkMode] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [openManufacturerModal, setOpenManufacturerModal] = useState(false);
  const [ManufacturerData, setManufacturerData] = useState<Manufacturers | null>(null);

  useEffect(() => {
    setIsDarkMode(currentTheme === 'dark');
  }, [currentTheme]);

  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const {
    data: { data = [], pagination: meta } = {},
    isError,
    isRefetching,
    isLoading,
    refetch,
  } = useQuery<ManufacturersApiResponse>({
    queryKey: [
      'table-data',
      columnFilters,
      globalFilter,
      pagination.pageIndex,
      pagination.pageSize,
      sorting,
    ],
    queryFn: async () => {
      try {
        const fetchURL = new URL(`${process.env.REACT_APP_API_SERVER}/manufacturers`);
        fetchURL.searchParams.set('page', `${pagination.pageIndex + 1}`);
        fetchURL.searchParams.set('per_page', `${pagination.pageSize}`);
        fetchURL.searchParams.set('filters', JSON.stringify(columnFilters ?? []));
        fetchURL.searchParams.set('globalFilter', globalFilter ?? '');
        fetchURL.searchParams.set('sorting', JSON.stringify(sorting ?? []));

        const response = await axiosClient.get(fetchURL.href);
        const json = response.data as ManufacturersApiResponse;

        return json;
      } catch (error) {
        console.error('Error fetching offices:', error);
        throw error;
      }
    },
    placeholderData: keepPreviousData,
  });

  const columns = useMemo<MRT_ColumnDef<Manufacturers>[]>(
    () => [
      {
        accessorKey: 'id',
        header: 'DB ID',
        minSize: 10,
        maxSize: 10,
        size: 10,
      },
      {
        accessorKey: 'name',
        header: 'Name',
        size: 180,
      },
      {
        accessorKey: 'description',
        header: 'Description',
        size: 180,
      },
      {
        accessorKey: 'actions',
        header: 'Actions',
        size: 120,
        enableSorting: false,
        enableColumnActions: false,
        Cell: ({ row }) => (
          <Grid container spacing={2}>
            <Grid item>
              <Button onClick={() => handleEditManufacturer(row.original)} variant="outlined">
                <IconEdit />
              </Button>
            </Grid>
            <Grid item>
              <Button onClick={() => handleDeleteManufacturer(row.original.id)} variant="outlined">
                <IconTrash />
              </Button>
            </Grid>
          </Grid>
        ),
      },
    ],
    [],
  );

  const table = useMaterialReactTable({
    columns,
    data,
    initialState: { showColumnFilters: true },
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    muiToolbarAlertBannerProps: isError
      ? {
          color: 'error',
          children: 'Error loading data',
        }
      : undefined,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    enableColumnOrdering: true,
    renderTopToolbarCustomActions: () => (
      <Box sx={{ display: 'flex', gap: '1rem', p: '4px' }}>
        <Tooltip arrow title="Refresh Data">
          <IconButton onClick={() => refetch()}>
            <RefreshIcon />
          </IconButton>
        </Tooltip>

        <Tooltip arrow title="Add New Manufacturer">
          <IconButton onClick={handleAddManufacturer}>
            <IconPlus />
          </IconButton>
        </Tooltip>
      </Box>
    ),
    rowCount: meta?.total ?? 0,
    state: {
      columnFilters,
      globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      sorting,
    },
  });

  const handleEditManufacturer = useCallback((record: any) => {
    setManufacturerData(record);
    setIsEditing(true);
    setOpenManufacturerModal(true);
  }, []);

  const handleAddManufacturer = useCallback(() => {
    setManufacturerData(null);
    setIsEditing(false);
    setOpenManufacturerModal(true);
  }, []);

  const handleCloseManufacturerModal = useCallback(() => {
    setOpenManufacturerModal(false);
  }, []);

  const handleSubmit = useCallback((payload: any) => {
    console.log('Payload:', payload);
    setOpenManufacturerModal(false);
  }, []);

  const handleDeleteManufacturer = useCallback((id: any) => {
    console.log('Delete action for record ID:', id);
  }, []);

  return (
    <>
      <ManufacturerDialog
        isOpen={openManufacturerModal}
        isEditing={isEditing}
        initialData={ManufacturerData}
        onClose={handleCloseManufacturerModal}
        onSubmit={handleSubmit}
        refetch={refetch}
      />

      {isLoading ? (
        <Skeleton variant="rectangular" width="100%" height={400} />
      ) : (
        <MaterialReactTable table={table} />
      )}
    </>
  );
};

const queryClient = new QueryClient();

const ManufacturersView = () => (
  <QueryClientProvider client={queryClient}>
    <ManufacturersTable />
  </QueryClientProvider>
);

export default ManufacturersView;
