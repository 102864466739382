import { createContext, useContext, useEffect, useState, ReactNode } from 'react';
import axios, { AxiosResponse } from 'axios';
import useServer from 'src/utils/useServer';

type LoginCredentials = {
  username: string;
  password: string;
};

interface AuthContextType {
  isAuthenticated: boolean;
  userDetails: any; // Change this to your user details type
  login: (credentials: LoginCredentials) => Promise<AuthData | null>;
  logout: () => void;
  updateAuthState: (isAuthenticated: boolean) => void;
  isTriggeredLogout: boolean;
}

interface AuthData {
  userToken: string;
  userDetails: any;
}

type ErrorResponse = {
  message: string;
};

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [userDetails, setUserDetails] = useState<any>(null);
  const [isTriggeredLogout, setIsTriggeredLogout] = useState(false);

  const API_SERVER = process.env.REACT_APP_API_SERVER;

  useEffect(() => {
    const token = localStorage.getItem('token');
    const storedUserDetails = localStorage.getItem('userDetails');
    if (token && storedUserDetails) {
      setIsAuthenticated(true);
      setUserDetails(JSON.parse(storedUserDetails));
    } else {
      setIsAuthenticated(false);
    }
  }, []);

  const login = async (credentials: LoginCredentials): Promise<AuthData | null> => {
    try {
      const params = new URLSearchParams();
      params.append('username', credentials.username);
      params.append('password', credentials.password);

      const response: AxiosResponse<any> = await axios.post<any>(
        `${API_SERVER}/login`,
        params,
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        },
      );

      const userDetails = response.data;
      const userToken = response.data.access_token;

      localStorage.setItem('token', userToken);
      localStorage.setItem('userAllDetails', JSON.stringify(response.data.all_data));
      localStorage.setItem('userDetails', JSON.stringify(response.data.user));

      setIsAuthenticated(true);
      setUserDetails(userDetails);

      return { userToken, userDetails };
    } catch (error: any) {
      if (axios.isAxiosError(error) && error.response) {
        const errorResponse = error.response.data as ErrorResponse;
        console.error('Login error:', errorResponse.message);
      } else {
        console.error('An unexpected error occurred:', error.message);
      }
      return null;
    }
  };

  const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('userDetails');
    setIsAuthenticated(false);
    setUserDetails(null);
    setIsTriggeredLogout(true);
  };

  const updateAuthState = (newIsAuthenticated: boolean) => {
    setIsAuthenticated(newIsAuthenticated);
  };

  return (
    <AuthContext.Provider
      value={{ isAuthenticated, userDetails, login, logout, updateAuthState, isTriggeredLogout }}
    >
      {children}
    </AuthContext.Provider>
  );
};
