import React, { FC, ReactNode, forwardRef, useContext, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { Row, Col, Form, Input, Divider, Table, message, ConfigProvider, DatePicker, Modal, Popconfirm, InputRef } from 'antd';
import { Box, TableBody, TableCell, TableHead, Paper, TableRow, Typography, Table as TableMUI } from '@mui/material';
import axiosClient from 'src/axios_client';
import CustomMultiInput, { TagItem } from './CustomMultiInput';
import locale from 'antd/locale/en_US';
import dayjs from 'dayjs';
import 'dayjs/locale/tl-ph';
import { DeleteOutline } from '@mui/icons-material';
import useFormattedNumber from 'src/hooks/useFormattedNumber';
import _ from 'lodash';
import SerialNumberInput from './SerialNumberInput';
import { GetRef } from 'antd/lib';
import { useSelector, useDispatch } from 'src/store/Store';
import { AppState } from 'src/store/Store';
import { theme } from 'antd/lib';

interface CustomFormLabelProps {
  children: React.ReactNode;
  htmlFor?: string;
}

interface SerialNumberInputProps {
  serialNumbers: string[];
  onChange: (serialNumbers: string[]) => void;
}

interface DataType {
  key: string;
  id: number;
  name: string;
  description: string;
  product_id: number;
  product_unit: string;
  quantity: number;
  unit_price: number;
  amount: number;
  serial_numbers?: any[];
}

type FormInstance<T> = GetRef<typeof Form<T>>;

const EditableContext = React.createContext<{
  form: FormInstance<any>;
  initialQuantities: { [key: number]: number };
} | null>(null);

interface EditableRowProps {
  index: number;
}

const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
  const [form] = Form.useForm();
  const initialQuantities = useContext(EditableContext)?.initialQuantities || {};
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={{ form, initialQuantities }}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

interface EditableCellProps {
  title: React.ReactNode;
  editable: boolean;
  dataIndex: keyof DataType;
  record: DataType;
  handleSave: (record: DataType) => void;
  handleDelete: (key: React.Key) => void;
}

const EditableCell: React.FC<React.PropsWithChildren<EditableCellProps>> = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  handleDelete,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef<InputRef>(null);
  const { form, initialQuantities } = useContext(EditableContext)!;

  useEffect(() => {
    if (editing) {
      inputRef.current?.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({ [dataIndex]: record[dataIndex] });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();

      console.log('EditableCell - save:');
      console.log('initialQuantities:', initialQuantities);
      console.log('record:', record);
      console.log('values:', values);

      // Validation for quantity
      if (dataIndex === 'quantity' && values.quantity > initialQuantities[record.product_id]) {
        message.error('Quantity cannot exceed the original quantity');
        return;
      }

      toggleEdit();
      handleSave({ ...record, ...values, amount: values.quantity * record.unit_price });
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    if (dataIndex === 'serial_numbers') {
      childNode = (
        <SerialNumberInput
          serialNumbers={record.serial_numbers || []}
          onChange={(serialNumbers, removedCount) =>
            handleSave({
              ...record,
              serial_numbers: serialNumbers,
              quantity: Math.max(0, record.quantity - removedCount),
              amount: Math.max(0, record.quantity - removedCount) * record.unit_price,
            })
          }
          onAllUsed={() => handleDelete(record.key)}
        />
      );
    } else {
      childNode = editing ? (
        <Form.Item
          style={{ margin: 0 }}
          name={dataIndex}
          rules={[
            {
              required: true,
              message: `${title} is required.`,
            },
          ]}
        >
          <Input ref={inputRef} onPressEnter={save} onBlur={save} />
        </Form.Item>
      ) : (
        <div className="editable-cell-value-wrap" style={{ paddingRight: 24 }} onClick={toggleEdit}>
          {children}
        </div>
      );
    }
  }

  return <td {...restProps}>{childNode}</td>;
};

type EditableTableProps = Parameters<typeof Table>[0];
type ColumnTypes = Exclude<EditableTableProps['columns'], undefined>;

const components = {
  body: {
    row: EditableRow,
    cell: EditableCell,
  },
};

const initialData: DataType[] = [];

const CustomFormLabel: FC<CustomFormLabelProps> = ({ children, htmlFor }) => (
  <label htmlFor={htmlFor} style={{ display: 'block', marginBottom: 4 }}>
    {children}
  </label>
);

interface IssuanceReturnsEntryFormHandles {
  postTransaction: () => void;
  clearTransaction: () => void;
}

const { confirm } = Modal;

const extractIdFromReferenceNumber = (referenceNumber: string) => {
  const match = referenceNumber.match(/IET-(\d+)/);
  if (match) {
    return parseInt(match[1], 10);
  }
  return null;
};

interface IssuanceReturnsEntryFormProps {
  onRefreshTransactions: () => void;
}

const IssuanceReturnsEntryForm = forwardRef<IssuanceReturnsEntryFormHandles, IssuanceReturnsEntryFormProps>(
  ({ onRefreshTransactions }, ref) => {
    useImperativeHandle(ref, () => ({
      postTransaction,
      clearTransaction,
    }));

    const [form] = Form.useForm();
    const [data, setData] = useState<DataType[]>(initialData);
    const [customerId, setCustomerId] = useState(0);

    interface InitialQuantities {
      [key: number]: number;
    }

    const [initialQuantities, setInitialQuantities] = useState<InitialQuantities>({});

    const showConfirmation = (
      action: () => void,
      title: string,
      content: ReactNode,
      width: number,
    ) => {
      confirm({
        title,
        content,
        onOk() {
          action();
        },
        width,
      });
    };

    const postTransaction = () => {
      form
        .validateFields([
          'issuance_id',
          'date_of_return',
          'customer_code',
          'customer_name',
          'customer_address',
        ])
        .then((values) => {
          if (data.length === 0) {
            message.error('The Temporary Table must have data before posting.');
            return;
          }

          const transactionData = {
            issuance_id: extractIdFromReferenceNumber(values.issuance_id),
            date_of_return: values.date_of_return.format('YYYY-MM-DD'),
            customer_id: customerId,
            customer_name: values.customer_name,
            customer_address: values.customer_address,
            products: dataSource.map((item) => ({
              id: item.product_id,
              name: item.name,
              quantity: item.serial_numbers?.length ? item.quantity : Math.min(item.quantity, initialQuantities[item.product_id] || 0),
              unit_price: item.unit_price,
              amount: item.amount,
              serial_numbers: item.serial_numbers?.map((sn) => ({
                id: sn.id,
                value: sn.value,
                price: item.unit_price
              })) || [],
            })),
            total_amount: dataSource.reduce((sum, item) => sum + item.amount, 0),
          };

          const post = () => {
            axiosClient
              .post('/ir_transactions', transactionData)
              .then((response) => {
                message.success('Transaction posted successfully!');
                clearFormAndTable();
                onRefreshTransactions();
              })
              .catch((error) => {
                console.error('Failed to post transaction:', error);
                message.error('Failed to post transaction. Please try again.');
              });
          };

          const test = () => {
            console.log(transactionData);
          }

          showConfirmation(post, 'Post Transaction', <>Are You Really Sure</>, 1000);
        })
        .catch((error) => {
          console.error('Validation failed:', error);
          message.error('Please fill in all required fields.');
        });
    };

    const clearTransaction = () => {
      console.log('Hello');
    };

    const [dataSource, setDataSource] = useState<DataType[]>([]);

    const handleSave = (row: DataType) => {
      const newData = [...data];
      const newDataSource = [...dataSource];
      const index = newDataSource.findIndex((item) => row.key === item.key);
      const item = newDataSource[index];

      // If serial numbers have changed, update the quantity
      if (row.serial_numbers && row.serial_numbers.length !== item.serial_numbers?.length) {
        const quantityChange = (item.serial_numbers?.length || 0) - row.serial_numbers.length;
        row.quantity = Math.max(0, (item.quantity || 0) - quantityChange);
        row.amount = row.quantity * row.unit_price;
      }

      if (row.quantity > initialQuantities[row.product_id]) {
        row.quantity = initialQuantities[row.product_id];
        row.amount = row.quantity * row.unit_price;
      }

      newDataSource.splice(index, 1, {
        ...item,
        ...row,
        serial_numbers: row.serial_numbers?.map(sn => ({
          id: sn.id,
          value: sn.value,
          price: sn.price
        }))
      });
      const updatedData = newData.map((dataItem) =>
        dataItem.key === row.key ? { ...dataItem, ...row } : dataItem
      );
      setData(updatedData);
      setDataSource(newDataSource);
    };

    const handleDelete = (key: React.Key) => {
      const newData = data.filter((item) => item.key !== key);
      const newDataSource = dataSource.filter((item) => item.key !== key);
      setData(newData);
      setDataSource(newDataSource);
    };

    const defaultColumns: (ColumnTypes[number] & { editable?: boolean; dataIndex: string })[] = [
      {
        title: 'Product ID',
        dataIndex: 'product_id',
        key: 'product_id',
      },
      {
        title: 'Product Code',
        dataIndex: 'name',
        key: 'name',
        render: (text) => <a>{text}</a>,
      },
      {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
      },
      {
        title: 'Serial Numbers',
        key: 'serial_numbers',
        dataIndex: 'serial_numbers',
        editable: true,
        render: (_, record) =>
          record.serial_numbers?.map((item: any) => <div key={item.value}>{item.value}</div>),
      },
      {
        title: 'Unit',
        dataIndex: 'product_unit',
        key: 'product_unit',
      },
      {
        title: 'Quantity',
        key: 'quantity',
        dataIndex: 'quantity',
        editable: true,
        render: (text, record) => {
          if (record.serial_numbers && record.serial_numbers.length > 0) {
            return record.quantity;
          }
          return (
            <div className="editable-cell-value-wrap" style={{ paddingRight: 24 }}>
              {text}
            </div>
          );
        },
      },
      {
        title: 'Unit Price',
        dataIndex: 'unit_price',
        key: 'unit_price',
      },
      {
        title: 'Amount',
        key: 'amount',
        dataIndex: 'amount',
      },
      {
        title: 'operation',
        dataIndex: 'operation',
        render: (_, record) =>
          dataSource.length >= 1 ? (
            <Popconfirm title="Sure to delete?" onConfirm={() => handleDelete(record.key)}>
              <a>Delete</a>
            </Popconfirm>
          ) : null,
      },
    ];

    const memoizedColumns = useMemo(() => {
      return defaultColumns.map((col) => {
        if (!col.editable) {
          return col;
        }
        return {
          ...col,
          onCell: (record: DataType) => ({
            record,
            editable: col.dataIndex === 'quantity'
              ? !(record.serial_numbers && record.serial_numbers.length > 0)
              : col.editable,
            dataIndex: col.dataIndex,
            title: col.title,
            handleSave,
            handleDelete,
          }),
        };
      });
    }, [handleSave, handleDelete, initialQuantities]);

    const calculateTotals = () => {
      const totalAmount = data.reduce((sum, item) => sum + item.amount, 0);

      const formattedTotalAmount = totalAmount.toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });

      return { totalAmount: formattedTotalAmount };
    };

    const { totalAmount } = calculateTotals();

    const [maximumTags, setMaximumTags] = useState(5);

    const onDateChange = (date: any, dateString: string | string[]) => {
      form.setFieldsValue({ date_of_return: date });
    };

    const onValuesChange = (changedValues: any, allValues: any) => {
      const { product_quantity, product_unit_price } = allValues;
      if (product_quantity !== undefined && product_unit_price !== undefined) {
        const quantity = Number(product_quantity);
        const unit_price = Number(product_unit_price);
        const amount = quantity * unit_price;
        form.setFieldsValue({ product_amount: amount });
        setMaximumTags(quantity);
      }
    };

    const clearFormAndTable = () => {
      form.resetFields();
      setData(initialData);
      setDataSource(initialData);
      setCustomerId(0);
      setInitialQuantities({});
    };

    const handleSearch = async (event: React.FocusEvent<HTMLInputElement>) => {
      const referenceNumber = event.target.value;
      const id = extractIdFromReferenceNumber(referenceNumber);
      if (id !== null) {
        try {

          const existingResponse = await axiosClient.get(`ir_transactions?issuance_id=${id}`);
          const existingData = existingResponse.data.data;

          if (existingData.length > 0) {
            console.error('Issuance Reference Number already exists');

            message.error('Issuance Reference Number already exists');
            clearFormAndTable();
            return;
          }

          const response = await axiosClient.get(`ie_transactions/${id}`);
          const fetchedData = response.data.data;

          setCustomerId(fetchedData.customer.id);

          const serialNumberData = fetchedData.transactions.serial_numbers.reduce(
            (acc: DataType[], item: any) => {
              const existingProduct = acc.find((p) => p.product_id === item.product_id);
              if (existingProduct) {
                if (!existingProduct.serial_numbers) {
                  existingProduct.serial_numbers = [];
                }
                existingProduct.serial_numbers.push({ id: item.serial_number_id, value: item.serial_number, price: item.price });
                existingProduct.quantity += 1;
              } else {
                acc.push({
                  key: `serial-${item.serial_number_id}`,
                  id: item.serial_number_id,
                  product_id: item.product_id,
                  name: item.product_name,
                  description: item.product_description,
                  product_unit: item.product_unit,
                  quantity: 1, // Start with quantity 1 for the new product
                  unit_price: item.price,
                  amount: item.price,
                  serial_numbers: [{ id: item.serial_number_id, value: item.serial_number, price: item.price }],
                });
              }
              return acc;
            },
            []
          );

          const stockData: DataType[] = fetchedData.transactions.stocks.map(
            (item: any) => ({
              key: `stock-${item.id}`,
              id: item.id,
              product_id: item.product_id,
              name: item.product_name,
              description: item.product_description,
              product_unit: item.product_unit,
              quantity: item.quantity,
              unit_price: item.price,
              amount: item.quantity * item.price,
              serial_numbers: [],
            })
          );

          const initialQuantitiesData = fetchedData.transactions.stocks.reduce(
            (acc: { [key: number]: number }, item: any) => {
              acc[item.product_id] = item.quantity;
              return acc;
            },
            {}
          );

          setInitialQuantities(initialQuantitiesData);

          const combinedData = [...serialNumberData, ...stockData];

          setData(combinedData);
          setDataSource(combinedData);

          form.setFieldsValue({
            reference_number: fetchedData.reference_number,
            issuance_id: `IET-${String(fetchedData.id).padStart(9, '0')}`,
            date_of_return: dayjs(fetchedData.date_of_purchase),
            customer_code: fetchedData.customer.code,
            customer_name: fetchedData.customer.name,
            customer_address: fetchedData.customer.address,
          });
        } catch (error) {
          console.error('Error fetching data:', error);
          message.error('Error fetching data');
          clearFormAndTable();
        }
      } else {
        message.error('Invalid Issuance Reference Number');
        clearFormAndTable();
      }
    };

    const customizer = useSelector((state: AppState) => state.customizer);
    const currentTheme = customizer.activeMode;

    const [isDarkMode, setIsDarkMode] = useState(false);
    const { defaultAlgorithm, darkAlgorithm } = theme;
    useEffect(() => {
      setIsDarkMode(currentTheme === 'dark');
    }, [currentTheme]);

    return (
      <ConfigProvider
        theme={{
          algorithm: isDarkMode ? darkAlgorithm : defaultAlgorithm,
        }}
      >
        <Form layout="vertical" form={form} onValuesChange={onValuesChange}>

          <Row gutter={16}>
            <Col sm={24} md={12} lg={8}>
              <Paper elevation={3} sx={{ padding: 3, boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', border: 'none', backgroundColor: isDarkMode ? '#1e1e1e' : '#ffffff', color: isDarkMode ? '#ffffff' : '#000000', }}>
                <Row gutter={8}>
                  <Col span={24}>
                    <h4 style={{
                      borderBottom: '1px solid #e8e8e8',
                      paddingBottom: '8px',
                      marginBottom: '16px',
                      fontWeight: 'normal',
                      fontSize: '18px',
                      color: isDarkMode ? '#ffffff' : '#606060',
                    }}>
                      Transaction Details
                    </h4>
                  </Col>
                  <Col xs={24} sm={24} lg={8}>
                    <Form.Item
                      label={<CustomFormLabel htmlFor="reference_number">Reference Number</CustomFormLabel>}
                      name="reference_number"
                      rules={[{ required: false, message: 'Please enter the reference number' }]}
                    >
                      <Input
                        id="reference_number"
                        placeholder="Reference Number is system generated"
                        disabled
                      />
                    </Form.Item>
                  </Col>

                  <Col xs={24} sm={24} lg={8}>
                    <Form.Item
                      label={<CustomFormLabel htmlFor="issuance_id">Issuance Number</CustomFormLabel>}
                      name="issuance_id"
                      rules={[{ required: true, message: 'Please enter the Issuance Reference Number' }]}
                    >
                      <Input
                        id="issuance_id"
                        placeholder="Enter Issuance Reference Number"
                        onBlur={handleSearch}
                      />
                    </Form.Item>
                  </Col>

                  <Col xs={24} sm={24} lg={8}>
                    <Form.Item
                      label={<CustomFormLabel htmlFor="date_of_return">Date of Return</CustomFormLabel>}
                      name="date_of_return"
                      rules={[{ required: true, message: 'Please enter the date of return' }]}
                    >
                      <ConfigProvider locale={locale}>
                        <DatePicker format="YYYY-MM-DD" onChange={onDateChange} defaultValue={dayjs()} />
                      </ConfigProvider>
                    </Form.Item>
                  </Col>

                  <Col xs={24} sm={24} lg={24}>
                    <CustomFormLabel>Customer Details</CustomFormLabel>
                    <Row gutter={16} style={{ paddingTop: '8px' }}>
                      <Col xs={24} sm={12} lg={8}>
                        <Form.Item
                          name="customer_code"
                          rules={[{ required: true, message: 'Please enter the customer code' }]}
                        >
                          <Input id="customer_code" placeholder="Customer Code Here" disabled />
                        </Form.Item>
                      </Col>

                      <Col xs={24} sm={12} lg={8}>
                        <Form.Item name="customer_name">
                          <Input id="customer_name" placeholder="Customer Name Here" disabled />
                        </Form.Item>
                      </Col>

                      <Col xs={24} sm={12} lg={8}>
                        <Form.Item name="customer_address">
                          <Input id="customer_address" placeholder="Customer Address Here" disabled />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Paper>

            </Col>


            <Col sm={24} md={12} lg={16}>
              <Table
                style={{
                  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                  border: 'none'
                }}
                components={components}
                rowClassName={() => 'editable-row'}
                bordered
                dataSource={dataSource}
                columns={memoizedColumns as ColumnTypes}
                footer={() => (
                  <div style={{ textAlign: 'right', paddingRight: '16px' }}>
                    <b>Total Amount:</b> ₱ {totalAmount}
                  </div>
                )}
              />
            </Col>
          </Row>

        </Form>
      </ConfigProvider>
    );
  });

export default IssuanceReturnsEntryForm;