import React, { useCallback, useRef, useState } from 'react';
import { Box, Button, Grid, Popover, Typography } from '@mui/material';
import Breadcrumb from 'src/layouts/full/shared/breadcrumb/Breadcrumb';
import PageContainer from 'src/components/container/PageContainer';
import DashboardCard from '../../components/shared/DashboardCard';
import { IconFile3d, IconPrinter, IconTrash } from '@tabler/icons';
import IssuanceEntryForm from './components/IssuanceEntryForm';
import IssuanceEntryTransactionsTable, {
  IssuanceEntryTransactions,
} from './components/IssuanceEntryTransactionsTable';
import { useEssentialDataContext } from 'src/contexts/EssentialDataContext';
import { Divider } from 'antd';

const BCrumb = [
  {
    to: '/',
    title: 'Home',
  },
  {
    to: '/purchases',
    title: 'Sales',
  },
  {
    title: 'Issuance',
  },
];

const Issuance = () => {
  const transactionsTableRef = useRef<{ refetch: () => void }>(null);
  const [selectedTransaction, setSelectedTransaction] = useState<any | null>(null);

  const handleRefreshTransactions = () => {
    console.log('Transaction refreshed');
    setSelectedTransaction(null);
    transactionsTableRef.current?.refetch();
  };

  const issuanceEntryFormRef = useRef<{
    postTransaction: () => void;
    clearTransaction: () => void;
    updateTransaction: () => void;
  }>(null);

  const handleOpenFloatingIssuance = useCallback((transaction: IssuanceEntryTransactions) => {

    console.log(transaction);
    setSelectedTransaction(transaction);
    console.log('Data:', transaction);
  }, []);

  const handlePostTransaction = () => {
    issuanceEntryFormRef.current?.postTransaction();
  };

  const handleUpdateTransaction = () => {
    issuanceEntryFormRef.current?.updateTransaction();
  };

  const handleClearTransaction = () => {
    setSelectedTransaction(null);
    issuanceEntryFormRef.current?.clearTransaction();
  };

  const { userData } = useEssentialDataContext();

  const userlevel = userData?.userlevel;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <PageContainer title="RKCWMS - Issuance Page" description="this is Sample page">
      {/* breadcrumb */}
      <Breadcrumb title="Issuances" items={BCrumb} />
      {/* end breadcrumb */}

      {userlevel === 'stockman' || userlevel === 'checker' ? (
        <Box></Box>
      ) : (
        <DashboardCard
          title="Issuance Entry"
          action={
            <Box>
              <Grid container spacing={1}>
                <Button
                  style={{ marginRight: '8px' }}
                  onClick={handleClearTransaction}
                  startIcon={<IconTrash />}
                >
                  Clear Transaction
                </Button>
                {selectedTransaction ? (
                  <Button
                    style={{ marginRight: '8px' }}
                    onClick={handleUpdateTransaction}
                    startIcon={<IconFile3d />}
                  >
                    Update Transaction
                  </Button>
                ) : (
                  <Button
                    style={{ marginRight: '8px' }}
                    onClick={handlePostTransaction}
                    startIcon={<IconFile3d />}
                  >
                    Post Transaction
                  </Button>
                )}
                {/* <Button
                style={{ marginRight: '8px' }}
                onClick={handlePostTransaction}
                startIcon={<IconPrinter />}
              >
                Print
              </Button> */}
              </Grid>
            </Box>
          }
        >
          <Box>
            <IssuanceEntryForm
              ref={issuanceEntryFormRef}
              selectedTransaction={selectedTransaction}
              onRefreshTransactions={handleRefreshTransactions}
              userlevel={userlevel}
            />
          </Box>
        </DashboardCard>
      )}


      <>

        {/* <Button aria-describedby={id} variant="contained" onClick={handleClick}>
          Open Popover
        </Button> */}
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <Box p={2}>
            <Typography variant="h6" mb={1}>
              Basic Popover
            </Typography>
            <Typography color="textSecondary">
              The component is built on top of the Modal component.
            </Typography>
          </Box>
        </Popover>
      </>
      <Divider />
      <DashboardCard title="Transaction History" subtitle="See history of issuance transactions">
        <IssuanceEntryTransactionsTable
          onOpenFloatingIssuance={handleOpenFloatingIssuance}
          onRefetchRef={transactionsTableRef}
          userlevel={userlevel}
        />
      </DashboardCard>
    </PageContainer>
  );
};

export default Issuance;
