import React from 'react';

import Breadcrumb from 'src/layouts/full/shared/breadcrumb/Breadcrumb';
import PageContainer from 'src/components/container/PageContainer';
import ProductTableList from './ProductTableList';
import BlankCard from '../../components/shared/BlankCard';
import { Card, useMediaQuery, useTheme } from '@mui/material';
import { useSelector } from 'react-redux';
import { AppState } from 'src/store/Store';

const BCrumb = [
  {
    to: '/',
    title: 'Home',
  },
  {
    title: 'Product Master Page',
  },
];

const ProductEntry = () => {
  
  const customizer = useSelector((state: AppState) => state.customizer);
  const theme = useTheme();
  const borderColor = theme.palette.grey[100];
  const isSmallScreen = useMediaQuery('(max-width:375px)');

  return (
    <PageContainer title="RKCWMS - Product Master Page" description="this is Sample page">
      {/* breadcrumb */}
      <Breadcrumb title="Product Master Page" items={BCrumb} />
      {/* end breadcrumb */}
      <Card sx={{
        padding: 0,
        border: !customizer.isCardShadow ? `1px solid ${borderColor}` : 'none',
        maxWidth: isSmallScreen ? '345px' : 'none',
      }} style={{ height: '100%', position: 'relative' }}>
        <ProductTableList />
      </Card>
    </PageContainer>
  );
};
export default ProductEntry;
