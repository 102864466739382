import React, { FC, useRef, useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Button,
  Grid,
  useTheme,
} from '@mui/material';
import dayjs from 'dayjs';
import useFormattedNumber from 'src/hooks/useFormattedNumber';
import { ReactToPrint } from 'react-to-print';
import './print.css'; // Import the CSS file
import { ReactComponent as RECEIPT } from 'src/assets/images/svgs/rkc_wms_dark_receiptS.svg';
import { Input, Popconfirm, Popover, Steps, message, ConfigProvider } from 'antd';
import { theme } from 'antd/lib';
import axiosClient from 'src/axios_client';
import { InvoiceData } from './types';
import { useSelector, AppState } from 'src/store/Store';

interface GridItemProps {
  data: InvoiceData;
}

interface ErrorResponse {
  message: string;
  error: string;
}

const GridItem: FC<GridItemProps> = ({ data }) => {
  const { formatWithPrefix } = useFormattedNumber();

  const theme = useTheme();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
        padding: '10px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          mb: 4,
        }}
      >
        <Box>
          <Box sx={{ width: '250px', marginLeft: '-36px', marginBottom: '5px' }}>
            <RECEIPT
              style={{
                fill: theme.palette.mode === 'dark' ? '#ffffff' : '#000000', // Change fill color based on theme mode
              }}
              className="printable-logo"
            />
          </Box>
          <Typography variant="h6" component="p" sx={{ color: 'text.primary', fontWeight: 'bold' }}>
            RAEL KITZ CORPORATION - WMS IS
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            RRF Building
            <br />
            San Miguel Calasiao, Pangasinan
            <br />
            +1 (123) 456 7891, +44 (876) 543 2198
          </Typography>
        </Box>
        <Box sx={{ textAlign: 'right' }}>
          <Typography variant="h6" component="p" sx={{ color: 'text.primary', fontWeight: 'bold' }}>
            INVOICE #{data.issuance_id}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            Reference Number: {data.reference_number} <br />
            Purchase Date: {dayjs(data.date_of_return).format('MMMM DD, YYYY')}
          </Typography>
          <Typography variant="body1" sx={{ color: 'text.primary', fontWeight: 'bold' }}>
            Customer:
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {data.customer_name}
            <br />
            Customer Code: {data.customer_code}
            <br />
            Address: {data.customer_address}
            <br />
            Contact Person: {data.customer_contact_person}
            <br />
          </Typography>
        </Box>
      </Box>
      <Table className="printTable" sx={{ width: '100%', textAlign: 'left', mb: 4 }}>
        <TableHead>
          <TableRow>
            <TableCell sx={{ py: 1, color: 'text.secondary' }}>PRODUCT NAME</TableCell>
            <TableCell sx={{ py: 1, color: 'text.secondary' }}>DESCRIPTION</TableCell>
            <TableCell sx={{ py: 1, color: 'text.secondary' }}>UNIT</TableCell>
            <TableCell sx={{ py: 1, color: 'text.secondary' }}>UNIT PRICE</TableCell>
            <TableCell sx={{ py: 1, color: 'text.secondary' }}>QTY</TableCell>
            <TableCell sx={{ py: 1, color: 'text.secondary' }}>AMOUNT</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.transactions.map((transaction, index) => (
            <TableRow key={index}>
              <TableCell sx={{ py: 1 }}>{transaction.product_name}</TableCell>
              <TableCell sx={{ py: 1, whiteSpace: 'pre-line' }}>
                {transaction.description}
              </TableCell>
              <TableCell sx={{ py: 1 }}>{transaction.unit}</TableCell>
              <TableCell sx={{ py: 1 }}>{formatWithPrefix(transaction.unit_price)}</TableCell>
              <TableCell sx={{ py: 1 }}>{transaction.quantity}</TableCell>
              <TableCell sx={{ py: 1 }}>{formatWithPrefix(transaction.amount)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Typography variant="body2" sx={{ color: 'text.secondary' }}>
        Processor: {data.created_by.name}
      </Typography>
      <Typography
        variant="body1"
        sx={{ textAlign: 'right', color: 'text.primary', fontWeight: 'bold' }}
      >
        Subtotal: {formatWithPrefix(data.subtotal)}
      </Typography>
    </Box>
  );
};

const Invoice: FC<{ data: InvoiceData; userlevel: any; onAcknowledgeSuccess?: () => void }> = ({
  data,
  userlevel,
  onAcknowledgeSuccess,
}) => {
  const [note, setNote] = useState('');
  const [isAcknowledged, setIsAcknowledged] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [messageApi, contextHolder] = message.useMessage();

  console.log('Data:', data);

  const componentRef = useRef(null);

  const getCurrentStep = (data: InvoiceData): number => {
    if (data.received_by.name && data.received_at) {
      return 3; // Confirmed
    } else if (data.checked_by.name) {
      return 2; // Received by Checker
    } else if (data.created_at) {
      return 1; // Created Return Transaction
    }
    return 0; // Default to the first step
  };

  const acknowledge = async () => {
    if (!data.id) return;

    try {
      const response = await axiosClient.post(`/ir_transactions/${data.id}/acknowledge`, {
        userlevel: userlevel,
        note: note,
      });
      console.log('Acknowledgment successful', response.data);
      messageApi.open({
        type: 'success',
        content: `Acknowledgment successful`,
        className: 'robotoFont',
        style: {
          marginTop: '20vh',
        },
      });
      setIsAcknowledged(true);
      onAcknowledgeSuccess?.();
    } catch (error) {
      console.error('Acknowledgment failed', error);
      const errorMessage =
        (error as { response?: { data?: ErrorResponse } }).response?.data?.error ||
        'An unexpected error occurred';
      messageApi.open({
        type: 'error',
        content: `Acknowledgment unsuccessful: ${errorMessage}`,
        className: 'robotoFont',
        style: {
          marginTop: '20vh',
        },
      });
    }
  };

  const customizer = useSelector((state: AppState) => state.customizer);
  const currentTheme = customizer.activeMode;

  const [isDarkMode, setIsDarkMode] = useState(false);
  const { defaultAlgorithm, darkAlgorithm } = theme;
  useEffect(() => {
    setIsDarkMode(currentTheme === 'dark');
  }, [currentTheme]);

  return (
    <ConfigProvider
      theme={{
        algorithm: isDarkMode ? darkAlgorithm : defaultAlgorithm,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          p: 4,
          bgcolor: 'background.paper',
          borderRadius: 1,
          boxShadow: 3,
        }}
      >
        {contextHolder}
        <Grid container spacing={4}>
          <Grid item xs={12} md={9} ref={componentRef}>
            <GridItem data={data} />
          </Grid>
          <Grid item xs={12} md={3}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
              <Steps
                progressDot
                current={getCurrentStep(data)}
                direction="vertical"
                items={[
                  {
                    title: 'Return Transaction Created',
                    description: `Created by ${data.created_by.name} on ${dayjs(
                      data.created_at,
                    ).format('MMMM DD, YYYY HH:mm:ss')}`,
                  },
                  {
                    // title: 'Checker Review',
                    title: (
                      <>
                        <Popover
                          content={
                            data.checked_note === '' || data.checked_note == null
                              ? 'Notes will be here'
                              : data.checked_note
                          }
                        >
                          Checker Review
                        </Popover>
                      </>
                    ),
                    description:
                      data.checked_by.name && data.checked_at
                        ? `Checked by ${data.checked_by.name} on ${dayjs(data.checked_at).format(
                          'MMMM DD, YYYY HH:mm:ss',
                        )}`
                        : 'Pending',
                  },
                  {
                    title: (
                      <>
                        <Popover
                          content={
                            data.received_note === '' || data.received_note == null
                              ? 'Notes will be here'
                              : data.received_note
                          }
                        >
                          Stockman Receipt
                        </Popover>
                      </>
                    ),
                    description:
                      data.received_by.name && data.received_at
                        ? `Received by ${data.received_by.name} on ${dayjs(data.received_at).format(
                          'MMMM DD, YYYY HH:mm:ss',
                        )}`
                        : 'Pending',
                  },
                  {
                    title: 'Confirmation',
                    description: data.received_by.name && data.received_at ? 'Completed' : 'Pending',
                  },
                ]}
              />

              {/* <Button variant="contained" color="primary" sx={{ py: 1.5, px: 3, borderRadius: 1 }}>
              Send Transaction Record
            </Button> */}

              {(userlevel === 'checker' ||
                userlevel === 'stockman' ||
                userlevel === 'superadmin') && (
                  <Popconfirm
                    title="Acknowledge Transmittal"
                    description={
                      <>
                        <p>Are you sure you want to acknowledge this transmittal?</p>
                        <Input.TextArea
                          placeholder="Add a note (optional)"
                          value={note}
                          onChange={(e) => setNote(e.target.value)}
                          rows={4}
                        />
                      </>
                    }
                    onConfirm={acknowledge}
                    okText="Yes"
                    cancelText="No"
                    placement="right"
                  >
                    <Button
                      disabled={
                        isAcknowledged ||
                        (userlevel === 'checker' && data?.checked_by.id !== null) ||
                        (userlevel === 'stockman' && data?.received_by.id !== null) ||
                        (userlevel === 'superadmin' &&
                          data?.checked_by.id !== null &&
                          data?.received_by.id !== null)
                      }
                    >
                      {(userlevel === 'checker' && data?.checked_by.id !== null) ||
                        (userlevel === 'stockman' && data?.received_by.id !== null) ||
                        (userlevel === 'superadmin' &&
                          data?.checked_by.id !== null &&
                          data?.received_by.id !== null)
                        ? 'Acknowledged'
                        : 'Acknowledge'}
                    </Button>
                  </Popconfirm>
                )}

              <Button variant="outlined" color="primary" sx={{ py: 1.5, px: 3, borderRadius: 1 }}>
                Download
              </Button>
              <ReactToPrint
                trigger={() => (
                  <Button variant="outlined" color="primary" sx={{ py: 1.5, px: 3, borderRadius: 1 }}>
                    Print
                  </Button>
                )}
                content={() => componentRef.current}
                pageStyle={`
                @media print {
                  body {
                    -webkit-print-color-adjust: exact;
                    margin: 20mm;
                    padding: 50px;
                  }
                  @page {
                    margin: 20mm;
                  }
                  .printTable {
                    font-size: 7px;
                  }
                  .printTable th,
                  .printTable td {
                    padding: 2px;
                  }
                  header, footer {
                    display: none;
                  }
                     .printable-logo {
                    fill: #000000 !important; /* Ensure the logo prints in black */
                  }
                }
              `}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </ConfigProvider>
  );
};

export default Invoice;
