import React, { createContext, useState, useContext, ReactNode } from 'react';

// Define the shape of your context state
interface TransactionContextType {
  transactionId: string | null;
  setTransactionId: (id: string | null) => void;
}

// Create the context with a default value
const TransactionContext = createContext<TransactionContextType>({
  transactionId: null,
  setTransactionId: () => {
    console.log('Transaction ID received');
  },
});

interface TransmittalIdProviderProps {
  children: ReactNode;
}

export const TransmittalIdProvider = ({ children }: TransmittalIdProviderProps) => {
  const [transactionId, setTransactionId] = useState<string | null>(null);

  return (
    <TransactionContext.Provider value={{ transactionId, setTransactionId }}>
      {children}
    </TransactionContext.Provider>
  );
};

export const useTransaction = () => {
  return useContext(TransactionContext);
};
