import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import {
  Grid,
  InputAdornment,
  IconButton,
  Box,
  Typography,
  Divider,
  FormControlLabel,
  FormLabel,
  Stack,
  Button,
  Autocomplete,
  Alert,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  Table,
  Card,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import CustomTextField from 'src/components/theme-elements/CustomTextField';
import { useFormik } from 'formik';
import axiosClient from 'src/axios_client';
import { MRT_ColumnDef, MRT_ColumnFiltersState, MRT_PaginationState, MRT_SortingState, MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { Close, Percent } from '@mui/icons-material';
import { QueryClient, QueryClientProvider, keepPreviousData, useQuery } from '@tanstack/react-query';
import CustomCheckbox from 'src/components/theme-elements/CustomCheckbox';
import { NumericFormatCustom, adjustAndFormat, formatCurrency } from 'src/globals/NumericFormat';
import BlankCard from 'src/components/shared/BlankCard';
import * as Yup from 'yup';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { useSelector } from 'react-redux';
import useFormattedNumber from 'src/hooks/useFormattedNumber';
import { LogoDark, LogoLight } from '../../globals/LogoExports';
import { AppState } from 'src/store/Store';
import { formatDate } from 'src/globals/PublicFunctions';
import ReactToPrint from 'react-to-print';

type Quotation = {
  id: number;
  product_id: number;
  product_name: string;
  sku: string;
  is_price_adjustment: number;
  is_quantity_adjustment: number;
  previous_selling: number,
  previous_buying: number;
  status: any;
  reason: string;
};

type QuotationApiResponse = {
  data: Array<Quotation>;
  pagination: {
    total: number;
    per_page: number;
    current_page: number;
    last_page: number;
    from: number;
    to: number;
  };
};

type QuotationOptions = {
  id: string,
  name: any,
  sku: any,
  has_serial: any,
  unit: any,
  description: any,
  image: any,
  buying_price: any,
  selling_price: any,
  qty_before: any,
  quantity: any
};


const validationSchema = Yup.object().shape({
  product_id: Yup.string().required('Please provide product'),
  customer_name: Yup.string().required('Please provide this data'),
  quotation_percentage: Yup.number().required('Provide Quantity'),
  quotation_quantity: Yup.number().required('Provide Quantity'),
});

const MainComponent = () => {


  const [isSearching, setIsSearching] = useState(false);
  const [isEmptySearch, setIsEmptySearch] = useState(false);
  const debounceTimeout = useRef<ReturnType<typeof setTimeout> | null>(null);
  const [productOptions, setProductOptions] = useState<QuotationOptions[]>([]);
  const [quotationList, setQuotationList] = useState<any>([]);
  const quotationListRef = useRef(quotationList);
  const [sucess, setSucess] = useState('');
  const [error, setError] = useState('');
  const [isAutoPrint, setIsAutoPrint] = useState(true);
  const [fromDate, setFromDate] = useState(dayjs());

  const formik = useFormik({
    enableReinitialize: false,
    initialValues: {
      created_at: fromDate.toISOString(),
      customer_name: '',
      product_id: '',
      product_name: '',
      current_buying: '',
      current_selling: '',
      current_quantity: '',
      quotation_selling: 0,
      quotation_buying: 0,
      quotation_quantity: 0,
      transaction_number: '',
      sku: '',
      unit: '',
      quotation_percentage: 0,
      has_serial: 0,
      is_price_adjustment: 1,
      is_quantity_adjustment: 1,
    },
    validateOnChange: false,
    validationSchema: validationSchema,
    onSubmit: async (values) => {

      if (formik.isValid) {
        const formattedValues = {
          ...values,
        };

        console.log(quotationList);

        axiosClient.post(`/quotation_transaction`, formattedValues, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
          .then((response) => {

            console.log(response);

            if (response.status === 201) {
              console.log(response.data.data.message);
              setSucess(response.data.message);
              setError('');

              setTimeout(() => {
                setSucess('');
              }, 5000);
              formik.resetForm();
            }

          })
          .catch((error) => {
            setError(error.response.data.message);
            console.log(error);
          });
      }
      else {
        console.log("Form invalid");
      }
    },
  });
  const autocompleteSearchChange = (searchText: any, apiText: any) => {
    setIsSearching(true);
    setIsEmptySearch(false);
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }
    debounceTimeout.current = setTimeout(() => {
      const value = searchText.target.value;
      fetchOptions(value, apiText);
    }, 500);
  };

  const fetchOptions = async (searchText: string, apiText: string) => {
    try {
      const filter = [
        { "id": "name", "value": searchText },
        // { "id": "is_adjusting", "value": 1 }
      ];
      const { status, data } = await axiosClient.get(`/${apiText}`, {
        params: { filters: JSON.stringify(filter), per_page: 10 },
      });
      if (status == 200) {


        setIsSearching(false);
        if (data.data.length == 0) {
          setIsEmptySearch(true);
        }

        console.log(data.data);
        const fetchedData = data.data.map((innerData: any) => ({
          id: innerData.id,
          name: innerData.name,
          description: innerData.description,
          has_serial: innerData.has_serial,
          image: innerData.image,
          unit: innerData.unit,
          sku: innerData.sku,
          qty_before: innerData.qty_before,
          buying_price: innerData.buying_price,
          selling_price: innerData.selling_price,
          quantity: innerData.quantity
        }));
        setProductOptions(fetchedData);

      }

    } catch (error) {
      console.error('Failed to fetch product options:', error);
    }
  };

  // const {
  //   data: { data = [], pagination: meta } = {},
  //   isError,
  //   isRefetching,
  //   isLoading,
  //   refetch,
  // } = useQuery<QuotationApiResponse>({
  //   queryKey: [
  //     'table-data',
  //     columnFilters,
  //     globalFilter,
  //     pagination.pageIndex,
  //     pagination.pageSize,
  //     sorting,
  //   ],
  //   queryFn: async () => {
  //     try {
  //       const fetchURL = new URL(`${process.env.REACT_APP_API_SERVER}/adjustments`);
  //       fetchURL.searchParams.set('page', `${pagination.pageIndex + 1}`);
  //       fetchURL.searchParams.set('per_page', `${pagination.pageSize}`);
  //       fetchURL.searchParams.set('filters', JSON.stringify(columnFilters ?? []));
  //       fetchURL.searchParams.set('globalFilter', globalFilter ?? '');
  //       fetchURL.searchParams.set('sorting', JSON.stringify(sorting ?? []));

  //       const response = await axiosClient.get(fetchURL.href);
  //       const json = response.data as QuotationApiResponse;

  //       console.log(json);

  //       return json;
  //     } catch (error) {
  //       console.error('Error fetching data:', error);
  //       throw error;
  //     }
  //   },
  //   placeholderData: keepPreviousData,
  // });
  // const columns = useMemo<MRT_ColumnDef<Adjustment>[]>(
  //   () => [
  //     {
  //       accessorKey: 'id',
  //       header: 'DB ID',
  //       minSize: 10,
  //       maxSize: 10,
  //       size: 50,
  //     },
  //     {
  //       accessorKey: 'product_name',
  //       header: 'Product Name',
  //       size: 180,
  //     },
  //     {
  //       accessorKey: 'sku',
  //       header: 'SKU no.',
  //       size: 180,
  //     },
  //     {
  //       accessorKey: 'status',
  //       header: 'Status',
  //       minSize: 10,
  //       maxSize: 10,
  //       size: 10,
  //     },
  //     {
  //       accessorKey: 'created_at',
  //       header: 'Adjustment Date',
  //       size: 10,
  //     }
  //   ],
  //   [],
  // );
  // const handleProduct = useCallback((record: any) => {
  //   console.log("edit clicked");
  // }, []);
  // const handleDeleteProduct = useCallback((id: any) => {
  //   console.log('Delete action for record ID:', id);
  // }, []);

  // const table = useMaterialReactTable({
  //   columns,
  //   data,
  //   initialState: { showColumnFilters: true },
  //   manualFiltering: true,
  //   manualPagination: true,
  //   manualSorting: true,
  //   muiToolbarAlertBannerProps: isError
  //     ? {
  //       color: 'error',
  //       children: 'Error loading data',
  //     }
  //     : undefined,
  //   onColumnFiltersChange: setColumnFilters,
  //   onGlobalFilterChange: setGlobalFilter,
  //   onPaginationChange: setPagination,
  //   onSortingChange: setSorting,
  //   enableColumnOrdering: true,
  //   renderTopToolbarCustomActions: () => (
  //     <Box sx={{ display: 'flex', gap: '1rem', p: '4px', alignItems: 'center' }}>
  //       <Tooltip arrow title="Refresh Data">
  //         <IconButton onClick={() => refetch()}>
  //           <RefreshOutlined />
  //         </IconButton>
  //       </Tooltip>
  //       <Typography variant='h5'>Adjustment List</Typography>
  //     </Box>
  //   ),
  //   rowCount: meta?.total ?? 0,
  //   state: {
  //     columnFilters,
  //     globalFilter,
  //     isLoading,
  //     pagination,
  //     showAlertBanner: isError,
  //     showProgressBars: isRefetching,
  //     sorting,
  //   },
  // });


  // const handleQuantityCheckbox = (event: any) => {
  //   setIsQuantityAdjustment(event.target.checked);
  //   if (event.target.checked) {
  //     formik.setFieldValue('is_quantity_adjustment', 1);
  //   }
  //   else {
  //     formik.setFieldValue('quotation_quantity', 0);
  //     formik.setFieldValue('is_quantity_adjustment', 0);
  //   }
  // }

  const handlePriceCheckbox = (event: any) => {
    setIsAutoPrint(event.target.checked);

    // if (event.target.checked) {
    //   formik.setFieldValue('is_price_adjustment', 1);
    // }
    // else {
    //   formik.setFieldValue('quotation_buying', 0);
    //   formik.setFieldValue('quotation_selling', 0);
    //   formik.setFieldValue('is_price_adjustment', 0);
    // }
  }

  const addQuotationClick = async () => {
    const errors = await formik.validateForm();
    formik.setTouched({ ['customer_name']: true, ['product_id']: true, ['quotation_buying']: true, ['quotation_selling']: true, ['quotation_quantity']: true });
    console.log(formik.values.current_quantity);

    console.log(errors);
    if (Object.keys(errors).length === 0) {


      const detectUnique = quotationList.some((item: any) => item.product_id == formik.values.product_id);

      if (detectUnique) {
        setError("Duplicate Product is selected. Please select different product");
      }
      else {
        setError('');

        setQuotationList([...quotationList, formik.values]);
        formik.setValues({
          ...formik.values,
          product_id: '',
          current_buying: '',
          current_selling: '',
          current_quantity: '',
          quotation_selling: 0,
          quotation_buying: 0,
          quotation_quantity: 0,
          sku: '',
        });
      }

    }
    console.log(errors);
  };

  useEffect(() => {

    quotationListRef.current = quotationList;

  }, [quotationList])

  const postTransaction = () => {

    if (quotationList.length > 0) {

      const formData = new FormData();

      formData.append(`customer_name`, formik.values.customer_name);

      quotationList.forEach((quotation: any, index: any) => {
        Object.keys(quotation).forEach(key => {
          formData.append(`quotationList[${index}][${key}]`, quotation[key]);
        });
      });

      axiosClient.post(`/quotation_transaction`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
        .then((response) => {

          console.log(response);
          if (response.status === 201) {
            setSucess(response.data.message);
            setError('');
            setQuotationList([]);
            setQuotationId(response.data.created_data.id);


            setTimeout(() => {
              setSucess('');
            }, 5000);

            if (isAutoPrint) {
              setPrintAuto(true);
            }
            formik.resetForm({
              values: {
                ...formik.values,
                product_id: '',
                sku: '',
                quotation_quantity: 0,
                quotation_percentage: 0,
                quotation_selling: 0,
                quotation_buying: 0,
                current_buying: '',
                current_quantity: '',
                current_selling: '',
              },
            });
          }
        })
        .catch((error) => {
          setError(error.response.data.message);
          console.log(error);
        });

    } else {
      setError('Please check fields and adjustment list to proceed');
    }
  }
  const handleRemoveClick = React.useCallback((passedAdj: any) => {
    removeAdjustment(passedAdj.product_id)
  }, []);

  const removeAdjustment = (id: any) => {

    const updatedArray = quotationListRef.current.filter((item: any) => item.product_id !== id);
    setQuotationList(updatedArray);
  }

  const handleFromDatechange = (newDate: any) => {
    formik.setFieldValue('created_at', newDate.toISOString());
    setFromDate(newDate);
  };

  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    if (quotationList.length > 0) {
      setOpen(true);
    }
    else {
      setError('Please check fields and quotation list to proceed');
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const componentRef = useRef(null);

  const [printAuto, setPrintAuto] = useState(false);
  const [quotationId, setQuotationId] = useState('');
  useEffect(() => {
    if (printAuto == true) {
      const printButton = document.getElementById('print-button');
      if (printButton) {
        printButton.click();
        setPrintAuto(false);
      }
    }
  }, [printAuto]);


  return (
    <Box>
      <form onSubmit={formik.handleSubmit}>
        <Grid container padding={3} columnSpacing={2}>
          <Grid item sm={12} lg={4}>
            {sucess &&
              <Alert
                variant="filled"
                severity="success"
                sx={{ alignItems: "center" }}
              >
                {sucess}
              </Alert>
            }

            {error &&
              <Alert
                variant="filled"
                severity="error"
                sx={{ alignItems: "center" }}
              >
                {error}
              </Alert>
            }
            <FormLabel component="legend">
              <Typography variant={'h4'}>Quotation Details</Typography>
            </FormLabel>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <CustomCheckbox
                      checked={isAutoPrint}
                      color="primary"
                      onChange={handlePriceCheckbox}
                      inputProps={{ 'aria-label': 'checkbox with default color' }}
                    />
                  }
                  label="Auto Print"
                />
              </Grid>
              {/* <Grid item xs={6}>
                <FormControlLabel
                  control={
                    <CustomCheckbox
                      checked={isQuantityAdjustment}
                      color="primary"
                      onChange={handleQuantityCheckbox}
                      inputProps={{ 'aria-label': 'checkbox with default color' }}
                    />
                  }
                  label="Quantity Adjustment"
                />
              </Grid>
              {
                !isQuantityAdjustment && !isAutoPrint ?
                  <Grid item xs={12}>
                    <FormHelperText sx={{ color: 'red' }}>{formik.errors.is_price_adjustment}</FormHelperText>
                  </Grid> : null
              } */}
              <Grid item xs={6}>
                <CustomTextField
                  id="quotation_number"
                  size="small"
                  disabled
                  variant="standard"
                  label="Quotation Number"
                  defaultValue="Auto Generated"
                  // value={formik.values.reference_number}
                  // onChange={formik.handleChange}
                  // onBlur={formik.handleBlur}
                  // error={formik.touched.reference_number && Boolean(formik.errors.reference_number)}
                  // helperText={formik.touched.reference_number && formik.errors.reference_number}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker
                    value={fromDate}
                    disabled
                    onChange={handleFromDatechange}
                    slotProps={{ textField: { size: 'small', variant: "standard" } }}
                    sx={{ width: '100%' }}
                    label="From Date"
                    views={['year', 'month', 'day', 'hours', 'minutes']}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid item xs={6}>
                <CustomTextField
                  id="customer_name"
                  size="small"
                  variant="standard"
                  label="Customer Name"
                  defaultValue=""
                  value={formik.values.customer_name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.customer_name && Boolean(formik.errors.customer_name)}
                  helperText={formik.touched.customer_name && formik.errors.customer_name}
                  fullWidth
                />
              </Grid>
            </Grid>


            <FormLabel component="legend" sx={{ mt: 3 }}>
              <Typography variant={'h4'}>Product Quotation Details</Typography>
            </FormLabel>
            <Grid container spacing={2}>


              <Grid item xs={12}>
                <Autocomplete
                  id="product_id"
                  noOptionsText={isSearching ? 'Searching...' : (isEmptySearch ? `Can't find search word` : 'Type to search')}
                  value={formik.values.product_id || null}
                  onChange={(_, newValue) => {
                    formik.setFieldValue('product_id', newValue);

                    const selectedProduct = productOptions.find(p => p.id === newValue);

                    if (selectedProduct) {
                      formik.setFieldValue('sku', selectedProduct.sku);
                      formik.setFieldValue('unit', selectedProduct.unit);
                      formik.setFieldValue('has_serial', selectedProduct.has_serial);
                      formik.setFieldValue('product_description', selectedProduct.description);
                      formik.setFieldValue('product_image', selectedProduct.image);
                      formik.setFieldValue('product_name', selectedProduct.name);
                      formik.setFieldValue('current_buying', selectedProduct.buying_price ? selectedProduct.buying_price : 0);
                      formik.setFieldValue('current_selling', selectedProduct.selling_price ? selectedProduct.selling_price : 0);
                      // formik.setFieldValue('current_quantity', selectedProduct.qty_before ? selectedProduct.qty_before : 0);

                      // formik.setFieldValue('current_quantity', selectedProduct.quantity ? selectedProduct.quantity : 0);

                      // formik.setFieldValue('quotation_buying', selectedProduct.buying_price ? selectedProduct.buying_price : 0);
                      // formik.setFieldValue('quotation_selling', selectedProduct.selling_price ? selectedProduct.selling_price : 0);
                      // formik.setFieldValue('quotation_quantity', selectedProduct.quantity ? selectedProduct.quantity : 0);
                    }

                    if (newValue === null) {
                      formik.resetForm();
                      setProductOptions([]);
                    }
                  }}
                  onBlur={() => formik.setFieldTouched('product_id', true)}
                  options={productOptions.map(p => p.id)}
                  fullWidth
                  getOptionLabel={(option) => {
                    return productOptions.find(p => p.id === option)?.name ?? '';
                  }}
                  isOptionEqualToValue={(option, value) => option === value}
                  renderInput={(params) => (
                    <CustomTextField
                      {...params}
                      error={formik.touched.product_id && Boolean(formik.errors.product_id)}
                      helperText={formik.touched.product_id && formik.errors.product_id}
                      onChange={(e: any) => autocompleteSearchChange(e, 'products')}
                      variant="standard"
                      placeholder="Product name"
                      aria-label="Product name"
                    />
                  )}
                />
              </Grid>

              {/* {formik.values.has_serial ?
                <Grid item xs={12}>
                  <Autocomplete
                    id="product_id"
                    noOptionsText={isSearching ? 'Searching...' : (isEmptySearch ? `Can't find search word` : 'Type to search')}
                    value={formik.values.product_id || null}
                    onChange={(_, newValue) => {
                      formik.setFieldValue('product_id', newValue);

                      const selectedProduct = productOptions.find(p => p.id === newValue);

                      if (selectedProduct) {
                        formik.setFieldValue('sku', selectedProduct.sku);
                        formik.setFieldValue('unit', selectedProduct.unit);
                        formik.setFieldValue('has_serial', selectedProduct.has_serial);
                        formik.setFieldValue('product_description', selectedProduct.description);
                        formik.setFieldValue('product_image', selectedProduct.image);
                        formik.setFieldValue('product_name', selectedProduct.name);
                        formik.setFieldValue('current_buying', selectedProduct.buying_price ? selectedProduct.buying_price : 0);
                        formik.setFieldValue('current_selling', selectedProduct.selling_price ? selectedProduct.selling_price : 0);
                        formik.setFieldValue('current_quantity', selectedProduct.quantity ? selectedProduct.quantity : 0);
                      }

                      if (newValue === null) {
                        formik.resetForm();
                        setProductOptions([]);
                      }
                    }}
                    onBlur={() => formik.setFieldTouched('product_id', true)}
                    options={productOptions.map(p => p.id)}
                    fullWidth
                    getOptionLabel={(option) => {
                      return productOptions.find(p => p.id === option)?.name ?? '';
                    }}
                    isOptionEqualToValue={(option, value) => option === value}
                    renderInput={(params) => (
                      <CustomTextField
                        {...params}
                        error={formik.touched.product_id && Boolean(formik.errors.product_id)}
                        helperText={formik.touched.product_id && formik.errors.product_id}
                        onChange={(e: any) => autocompleteSearchChange(e, 'products')}
                        variant="standard"
                        placeholder="Product name"
                        aria-label="Product name"
                      />
                    )}
                  />
                </Grid> : null
              } */}

              <Grid item xs={12}>
                <CustomTextField
                  id="sku"
                  size="small"
                  disabled
                  variant="filled"
                  label="Sku"
                  value={formik.values.sku}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.sku && Boolean(formik.errors.sku)}
                  helperText={formik.touched.sku && formik.errors.sku}
                  fullWidth
                />
              </Grid>

              <Grid item xs={6}>
                <CustomTextField
                  id="quotation_quantity"
                  size="small"
                  variant="standard"
                  type="number"
                  label="Quantity"
                  value={formik.values.quotation_quantity}
                  InputProps={{
                    endAdornment: <InputAdornment position="start">{formik.values.unit}</InputAdornment>,
                  }}
                  onChange={(e: any) => {
                    const reqBuying = parseFloat(e.target.value);
                    const current_buying = parseFloat(String(formik.values.current_buying || 0));
                    const current_selling = parseFloat(String(formik.values.current_selling || 0));

                    const markup = parseFloat(String(formik.values.quotation_percentage || 0)) / 100;

                    console.log(markup);
                    formik.setFieldValue('quotation_buying', reqBuying * (current_buying + (current_buying * markup)));
                    formik.setFieldValue('quotation_selling', reqBuying * (current_selling + (current_selling * markup)));

                    formik.handleChange(e);
                  }}
                  onBlur={formik.handleBlur}
                  error={formik.touched.quotation_quantity && Boolean(formik.errors.quotation_quantity)}
                  helperText={formik.touched.quotation_quantity && formik.errors.quotation_quantity}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <CustomTextField
                  id="quotation_percentage"
                  size="small"
                  variant="standard"
                  type="number"
                  label="Markup Percentage"
                  // disabled
                  value={formik.values.quotation_percentage}
                  InputProps={{
                    endAdornment: <InputAdornment position="start"><Percent /></InputAdornment>,
                  }}
                  onChange={(e: any) => {
                    const reqBuying = parseFloat(String(formik.values.quotation_quantity || 0));
                    const current_buying = parseFloat(String(formik.values.current_buying || 0));
                    const current_selling = parseFloat(String(formik.values.current_selling || 0));

                    const markup = parseFloat(e.target.value || 0) / 100;

                    console.log(markup);
                    formik.setFieldValue('quotation_buying', reqBuying * (current_buying + (current_buying * markup)));
                    formik.setFieldValue('quotation_selling', reqBuying * (current_selling + (current_selling * markup)));

                    formik.handleChange(e);
                  }}
                  onBlur={formik.handleBlur}
                  error={formik.touched.quotation_percentage && Boolean(formik.errors.quotation_percentage)}
                  helperText={formik.touched.quotation_percentage && formik.errors.quotation_percentage}
                  fullWidth
                />
              </Grid>

              <Grid item xs={6}>
                <CustomTextField
                  id="current_buying"
                  size="small"
                  disabled
                  variant="filled"
                  label="Buying Price"
                  value={formik.values.current_buying}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.current_buying && Boolean(formik.errors.current_buying)}
                  helperText={formik.touched.current_buying && formik.errors.current_buying}
                  InputProps={{
                    inputComponent: NumericFormatCustom as any,
                    inputProps: {
                      name: "current_buying",
                    },
                  }}
                  fullWidth
                />
              </Grid>

              <Grid item xs={6}>
                <CustomTextField
                  id="quotation_buying"
                  size="small"
                  disabled
                  variant="filled"
                  label="Quotation Buying Price"
                  value={formik.values.quotation_buying}
                  onChange={(e: any) => {
                    // const reqBuying = parseFloat(e.target.value);
                    // formik.setFieldValue("quotation_selling", reqBuying + (reqBuying * 0.10));
                    formik.handleChange(e);
                  }}
                  onBlur={formik.handleBlur}
                  error={formik.touched.quotation_buying && Boolean(formik.errors.quotation_buying)}
                  helperText={formik.touched.quotation_buying && formik.errors.quotation_buying}
                  InputProps={{
                    inputComponent: NumericFormatCustom as any,
                    inputProps: {
                      name: "quotation_buying",
                    },
                  }}
                  fullWidth
                />
              </Grid>

              <Grid item xs={6}>
                <CustomTextField
                  id="current_selling"
                  size="small"
                  disabled
                  variant="filled"
                  label="Selling Price"
                  value={formik.values.current_selling}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.current_selling && Boolean(formik.errors.current_selling)}
                  helperText={formik.touched.current_selling && formik.errors.current_selling}
                  InputProps={{
                    inputComponent: NumericFormatCustom as any,
                    inputProps: {
                      name: "current_selling",
                    },
                  }}
                  fullWidth
                />
              </Grid>

              <Grid item xs={6}>
                <CustomTextField
                  id="quotation_selling"
                  size="small"
                  disabled
                  variant="filled"
                  label="Quotation Selling Price"
                  value={formik.values.quotation_selling}
                  onChange={(e: any) => {

                    // const reqBuying = parseFloat(e.target.value);
                    // formik.setFieldValue("quotation_selling", reqBuying + (reqBuying * 0.10));
                    formik.handleChange(e);
                  }}
                  onBlur={formik.handleBlur}
                  error={formik.touched.quotation_selling && Boolean(formik.errors.quotation_selling)}
                  helperText={formik.touched.quotation_selling && formik.errors.quotation_selling}
                  InputProps={{
                    inputComponent: NumericFormatCustom as any,
                    inputProps: {
                      name: "quotation_selling",
                    },
                  }}
                  fullWidth
                />
              </Grid>

              {/* <Grid item xs={6}>
                <CustomTextField
                  id="current_selling"
                  size="small"
                  disabled
                  variant="filled"
                  label="Selling Price"
                  value={formik.values.current_selling}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.current_selling && Boolean(formik.errors.current_selling)}
                  helperText={formik.touched.current_selling && formik.errors.current_selling}
                  InputProps={{
                    inputComponent: NumericFormatCustom as any,
                    inputProps: {
                      name: "current_selling",
                    },
                  }}
                  fullWidth
                />
              </Grid>

              <Grid item xs={6}>
                <CustomTextField
                  id="quotation_selling"
                  size="small"
                  variant="standard"
                  disabled={!isAutoPrint}
                  label="Selling Price Adjustment"
                  value={formik.values.quotation_selling}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.quotation_selling && Boolean(formik.errors.quotation_selling)}
                  helperText={formik.touched.quotation_selling && formik.errors.quotation_selling}
                  InputProps={{
                    inputComponent: NumericFormatCustom as any,
                    inputProps: {
                      name: "quotation_selling",
                    },
                  }}
                  fullWidth
                />
              </Grid> */}


              {/* <Grid item xs={12}>
                <Typography variant='h6' color={'red'} >Note: input + or - in adjustments to add or deduct. (Eg. +10, -10)</Typography>
              </Grid> */}
              <Grid item xs={6}>
                <Grid container gap={2}>
                  <Button variant="contained" color="primary" onClick={addQuotationClick}>
                    Add
                  </Button>
                  <Button variant="contained" color="primary">
                    Reset
                  </Button>
                </Grid>

              </Grid>

            </Grid>

          </Grid>
          <Grid item sm={12} lg={8} flexGrow={1} mt={{ xs: 2, md: 0 }}>
            <Card elevation={6}>
              <Grid item xs={12} sm={12} padding={2}>
                <Stack direction="row" spacing={2}>
                  <Button variant="contained" color="primary" onClick={handleClickOpen}>
                    POST
                  </Button>
                  <Button variant="text" color="error">
                    CLEAR
                  </Button>
                </Stack>
              </Grid>
              <List
                subheader={
                  <ListItem >
                    <ListItemAvatar >
                      <Box width={20} height={20} >
                      </Box>
                    </ListItemAvatar>
                    <Grid container columnSpacing={1}>
                      <Grid item xs={3}>
                        <ListItemText primary="Item Details" />
                      </Grid>
                      <Grid item xs={3}>
                        <ListItemText primary="Quantity" />
                      </Grid>
                      <Grid item xs={3}>
                        <ListItemText primary="Selling Price" />
                      </Grid>
                      <Grid item xs={3}>
                        <ListItemText primary="Buying Price" />
                      </Grid>

                    </Grid>

                  </ListItem>
                }

              >

                {
                  quotationList.map(
                    (adj: any) => {
                      return (
                        <>
                          <QuotationListComponent adjustmentDetails={adj} onRemoveClick={handleRemoveClick} />
                        </>
                      )
                    }
                  )
                }


              </List>
            </Card>
          </Grid>


          <Dialog
            open={open}
            onClose={handleClose}
            fullWidth
            maxWidth={'md'}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Quotation Details"}
            </DialogTitle>
            <DialogContent>
              <QuotationModalReceipt data={quotationList} copy={''} customer={formik.values.customer_name} transaction_id={quotationId} />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button onClick={postTransaction} autoFocus>
                Post
              </Button>
            </DialogActions>
          </Dialog>

        </Grid>
      </form>

      <Grid
        item
        xs={12}
        md={9}
        ref={componentRef}
        sx={{ display: 'none', displayPrint: 'block' }}
      >
        <Box>
          <QuotationModalReceipt data={quotationList} copy={'Original copy'} customer={formik.values.customer_name} transaction_id={quotationId} />
        </Box>
        <div className="pageBreak"></div>
        <Box>
          <QuotationModalReceipt data={quotationList} copy={'Customer copy'} customer={formik.values.customer_name} transaction_id={quotationId} />
        </Box>

      </Grid>

      <ReactToPrint
        trigger={() => (
          <button id="print-button" style={{ display: 'none' }}>
            Print
          </button>
        )}
        content={() => componentRef.current}
        pageStyle={`
          @media print {
            body {
              -webkit-print-color-adjust: exact;
              margin: 20mm;
              padding: 50px;
            }
            @page {
              margin: 20mm;
            }
            .printTable th,
            .printTable td {
              padding: 2px;
            }
            header, footer {
              display: none;
            }
            .pageBreak {
              page-break-after: always;
            }
            .fontSize8pt {
              font-size: 8pt !important;
            }
            tbody tr:not(:last-child) td {
              border-bottom: 1px solid #acacac;
            }
            .printable-logo {
              fill: #000000 !important; /* Ensure the logo prints in black */
            }
          }
        `}
      />
    </Box >
  );
};
const queryClient = new QueryClient();

interface GridItemProps {
  data: any;
  copy: any;
  customer: any;
  transaction_id: any;
}
const QuotationModalReceipt: FC<GridItemProps> = ({ data, copy, customer, transaction_id }) => {
  const { formatWithPrefix } = useFormattedNumber();

  // const theme = useTheme();
  const customizer = useSelector((state: AppState) => state.customizer);
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
        padding: '10px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          mb: 1,
        }}
      >
        <Box>
          {customizer.activeMode === 'dark' ? (
            <LogoLight height={'auto'} />
          ) : (
            <LogoDark height={'auto'} />
          )}
          {/* <Box sx={{ width: '250px', marginLeft: '-36px', marginBottom: '5px' }}>
            <RECEIPT
              style={{
                fill: theme.palette.mode === 'dark' ? '#ffffff' : '#000000', // Change fill color based on theme mode
              }}
              className="printable-logo"
            />
          </Box> */}
          <Typography
            component="p"
            sx={{ color: 'text.primary', fontWeight: 'bold' }}
            className="fontSize8pt"
          >
            RAEL KITZ CORPORATION - WMS IS
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} className="fontSize8pt">
            RRF Building San Miguel Calasiao, Pangasinan
            <br />
            +1 (123) 456 7891, +44 (876) 543 2198
          </Typography>
          <Typography
            variant="body2"
            sx={{ mt: 1, fontWeight: 'bold', textTransform: 'uppercase' }}
            className="fontSize8pt"
          >
            {copy ?? ''}
          </Typography>
        </Box>
        <Box sx={{ textAlign: 'right' }}>
          <Typography
            variant="h6"
            component="p"
            sx={{ color: 'text.primary', fontWeight: 'bold' }}
            className="fontSize8pt"
          >
            INVOICE #{transaction_id ?? ''}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} className="fontSize8pt">
            Reference Number: {data?.reference_number} <br />
            Quoted Date: {formatDate(data[0]?.created_at)}
          </Typography>
          <Typography
            variant="body1"
            sx={{ color: 'text.primary', fontWeight: 'bold' }}
            className="fontSize8pt"
          >
            Customer: {customer ?? ''}
          </Typography>
        </Box>
      </Box>
      <Table className="printTable" sx={{ width: '100%', textAlign: 'left' }}>
        <TableHead sx={{ backgroundColor: 'grey.200' }}>
          <TableRow>
            <TableCell sx={{ py: 1, color: 'text.secondary' }}>PRODUCT NAME</TableCell>
            <TableCell sx={{ py: 1, color: 'text.secondary' }}>QUANTITY</TableCell>
            <TableCell sx={{ py: 1, color: 'text.secondary' }}>COST</TableCell>
            <TableCell sx={{ py: 1, color: 'text.secondary' }}>AMOUNT</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.length > 0 && data?.map((transaction: any, index: any) => {

            return (
              <TableRow key={transaction?.product_id}>
                <TableCell sx={{ py: 1 }}>{transaction?.product_name}</TableCell>
                <TableCell sx={{ py: 1 }}>{transaction?.quotation_quantity}</TableCell>
                <TableCell sx={{ py: 1 }}>{formatCurrency(transaction?.current_selling)}</TableCell>
                <TableCell sx={{ py: 1 }}>{formatCurrency(transaction?.quotation_selling)}</TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>

      <Divider sx={{ borderColor: '#acacac', display: 'none', displayPrint: 'block' }} />
      <Grid
        container
        columnSpacing={5}
        sx={{ fontSize: { md: '8pt', lg: '12pt' }, display: 'none', displayPrint: 'block' }}
      >
        <Grid item xs={2.5} className="fontSize8pt">
          Requested By:
          <Divider sx={{ borderColor: 'black', mt: { xs: 5 } }} />
        </Grid>
        <Grid item xs={2.5} className="fontSize8pt">
          Prepared By:
          <Divider sx={{ borderColor: 'black', mt: { xs: 5 } }} />
        </Grid>
        <Grid item xs={7}>
          <Grid container columnSpacing={1}>
            <Grid item className="fontSize8pt">
              Checked By:
            </Grid>
            <Grid item flexGrow={1}>
              <Grid container columnSpacing={2}>
                <Grid item className="fontSize8pt">
                  Checker
                </Grid>
                <Grid item sx={{ flexGrow: 1, alignContent: 'end' }}>
                  <Divider sx={{ borderColor: 'black' }} />
                </Grid>
              </Grid>
              <Grid container columnSpacing={2} mt={1}>
                <Grid item className="fontSize8pt">
                  Hauler Representative
                </Grid>
                <Grid item sx={{ flexGrow: 1, alignContent: 'end' }}>
                  <Divider sx={{ borderColor: 'black' }} />
                </Grid>
              </Grid>
              <Grid container columnSpacing={2} mt={1}>
                <Grid item className="fontSize8pt">
                  Customer Authorized Representative
                </Grid>
                <Grid item sx={{ flexGrow: 1, alignContent: 'end' }}>
                  <Divider sx={{ borderColor: 'black' }} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} mt={1}>
          <Grid container columnSpacing={1} justifyContent={'between'}>
            <Grid item xs={8}>
              <Typography sx={{ fontWeight: 'bold' }} className="fontSize8pt">
                Received the above goods in full and good condition.
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Grid item flexGrow={1}>
                <Grid container columnSpacing={2}>
                  <Grid item className="fontSize8pt">
                    Received By:
                  </Grid>
                  <Grid item sx={{ flexGrow: 1, alignContent: 'end' }}>
                    <Divider sx={{ borderColor: 'black' }} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        sx={{ textAlign: 'center', display: 'none', displayPrint: 'block' }}
        mt={1}
      >
        <Divider sx={{ borderColor: 'black' }} />
      </Grid>

      <Grid
        item
        xs={12}
        sx={{ textAlign: 'center', display: 'none', displayPrint: 'block' }}
        mt={1}
      >
        <Grid container columnSpacing={1}>
          <Grid item>
            <Typography variant="body2" sx={{ color: 'text.secondary' }} className="fontSize8pt">
              WAREHOUSE MANAGEMENT SYSTEM - by: INFORMATION SYSTEMS{' '}
              {dayjs().format('MM/DD/YYYY h:mm:ss A')}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2" sx={{ color: 'text.secondary' }} className="fontSize8pt">
              User: name of user
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

const QuotationListComponent: React.FC<{
  adjustmentDetails: any;
  onRemoveClick: any;
}> = React.memo(({ adjustmentDetails, onRemoveClick }) => {

  console.log(adjustmentDetails);
  const handleRemoveClick = () => {
    onRemoveClick(adjustmentDetails);
  };

  const baseQuant = adjustmentDetails.current_quantity ? adjustmentDetails.current_quantity : 0;
  const reqQuant = adjustmentDetails.quotation_quantity ? adjustmentDetails.quotation_quantity : 0;

  const baseQuantity = typeof baseQuant === 'string' ? parseFloat(baseQuant) : baseQuant;
  const quantityAdjustment = typeof reqQuant === 'string' ? parseFloat(reqQuant) : reqQuant;

  return (
    <>
      <ListItem
        secondaryAction={
          <IconButton edge="end" aria-label="comments" onClick={handleRemoveClick}>
            <Close width={20} height={20} />
          </IconButton>
        }
      >
        <ListItemAvatar>
          <Avatar>
            <img src={adjustmentDetails.product_image} height={'auto'} width={'100%'}></img>
          </Avatar>
        </ListItemAvatar>
        <Grid container columnSpacing={1}>
          <Grid item xs={3}>
            <ListItemText primary={adjustmentDetails.product_name} secondary={adjustmentDetails.product_description} />
          </Grid>
          <Grid item xs={3}>
            <ListItemText
              primary={
                <>
                  {/* <Typography variant="body2">
                    Current: {adjustmentDetails.current_quantity}
                  </Typography> */}
                  <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                    Qty: {adjustmentDetails.quotation_quantity ? adjustmentDetails.quotation_quantity : 0}
                  </Typography>

                  <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                    Markup: {adjustmentDetails.quotation_percentage ? adjustmentDetails.quotation_percentage : 0}%
                  </Typography>

                  {/* <Typography variant="body2" sx={{ fontWeight: 'bold' }}>-
                    New Quantity: {baseQuantity + quantityAdjustment}
                  </Typography> */}
                </>
              }
            />
          </Grid>
          <Grid item xs={3}>
            <ListItemText
              primary={
                <>
                  <Typography variant="body2">
                    Item Price: {formatCurrency(adjustmentDetails.current_selling)}
                  </Typography>
                  <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                    Markup Total Price: {formatCurrency(parseFloat(adjustmentDetails.quotation_selling))}
                  </Typography>
                  {/* <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                    New Price: {adjustAndFormat(adjustmentDetails.current_selling, adjustmentDetails.quotation_selling)}
                  </Typography> */}
                </>
              }
            />
          </Grid>
          <Grid item xs={3}>
            <ListItemText
              primary={
                <>
                  <Typography variant="body2">
                    Item Price: {formatCurrency(adjustmentDetails.current_buying)}
                  </Typography>
                  <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                    Markup Total Price: {formatCurrency(adjustmentDetails.quotation_buying)}
                  </Typography>
                  {/* <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                    New Price: {adjustAndFormat(adjustmentDetails.current_buying, adjustmentDetails.quotation_buying)}
                  </Typography> */}
                </>
              }
            />
          </Grid>
        </Grid>
      </ListItem>
      <Divider component="li" />
    </>
  );
});
const QuotationEntry = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <MainComponent />
    </QueryClientProvider>
  );
};
export default QuotationEntry;
