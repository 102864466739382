import React, { useRef } from 'react';
import { Box, Button, Grid } from '@mui/material';
import Breadcrumb from 'src/layouts/full/shared/breadcrumb/Breadcrumb';
import PageContainer from 'src/components/container/PageContainer';
import DashboardCard from '../../components/shared/DashboardCard';
import PurchaseEntryForm from './components/PurchaseEntryForm';
import PurchaseEntryTransactionsTable from './components/PurchaseEntryTransactionsTable';
import { IconFile3d, IconTrash } from '@tabler/icons';
import { Divider } from 'antd';

const BCrumb = [
  {
    to: '/',
    title: 'Home',
  },
  {
    to: '/purchases',
    title: 'Sales',
  },
  {
    title: 'Purchase Entry',
  },
];

const Purchases = () => {
  const purchaseEntryFormRef = useRef<{
    postTransaction: () => void;
    clearTransaction: () => void;
  }>(null);
  const transactionsTableRef = useRef<{ refetch: () => void }>(null);


  const handleRefreshTransactions = () => {
    transactionsTableRef.current?.refetch();
  };


  const handlePostTransaction = () => {
    purchaseEntryFormRef.current?.postTransaction();
    transactionsTableRef.current?.refetch();
  };

  const handleClearTransaction = () => {
    purchaseEntryFormRef.current?.clearTransaction();
  };

  return (
    <PageContainer title="RKCWMS - Purchases" description="this is Sample page">
      <Breadcrumb title="Purchases" items={BCrumb} />
      <DashboardCard
        title="Purchase Entry"
        action={
          <Box>
            <Grid container spacing={1}>
              <Button
                style={{ marginRight: '8px' }}
                onClick={handleClearTransaction}
                startIcon={<IconTrash />}
              >
                Clear Transaction
              </Button>
              <Button
                style={{ marginRight: '8px' }}
                onClick={handlePostTransaction}
                startIcon={<IconFile3d />}
              >
                Post Transaction
              </Button>
            </Grid>
          </Box>
        }
      >
        <Box>
          <PurchaseEntryForm ref={purchaseEntryFormRef} onRefreshTransactions={handleRefreshTransactions} />
        </Box>
      </DashboardCard>

      <Divider />
      <DashboardCard
        title="Transaction History"
        subtitle="See history of purchase transactions"
      >
        <PurchaseEntryTransactionsTable onRefetchRef={transactionsTableRef} />
      </DashboardCard>
    </PageContainer>
  );
};

export default Purchases;
