import { RefreshOutlined } from "@mui/icons-material";
import { Box, Chip, IconButton, Tooltip } from "@mui/material";
import { MRT_ColumnDef, MRT_ColumnFiltersState, MRT_PaginationState, MRT_SortingState, MaterialReactTable, useMaterialReactTable } from "material-react-table";
import { formatDate } from "./PublicFunctions";
import { QueryClient, QueryClientProvider, keepPreviousData, useQuery } from "@tanstack/react-query";
import axiosClient from "src/axios_client";
import { useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { Image } from "antd";

type Adjustments = {
    id: number;
    product_id: number;
    product_name: string;
    sku: string;
    created_at: any;
    status: any;
    is_price_adjustment: any;
    is_quantity_adjustment: any;
    transaction_number: any;
    buying_price: number;
    selling_price: number;
    quantity: number;
    requested_selling: number;
    requested_buying: number;
    requested_quantity: number;
    reason: string;
};

type AdjustmentApiResponse = {
    data: Array<Adjustments>;
    pagination: {
        total: number;
        per_page: number;
        current_page: number;
        last_page: number;
        from: number;
        to: number;
    };
};

type Transactions = {
    id: number;
    reference_number: number;
    invoice_number: string;
    supplier_id: string;
    date_of_purchase: any;
    image: any;
    created_by: {
        id: any,
        name: any
    };
    created_at: any;
    updated_at: number;
    selling_price: number;
    supplier: {
        id: any,
        name: any
    };
};

type TransactionsApiResponse = {
    data: Array<Transactions>;
    pagination: {
        total: number;
        per_page: number;
        current_page: number;
        last_page: number;
        from: number;
        to: number;
    };
};
type Issuance = {
    id: number;
    reference_number: number;
    slip_number: string;
    customer_id: string;
    date_of_issuance: any;
    created_by: {
        id: any,
        name: any
    };
    created_at: any;
    updated_at: number;
    total_amount: number;
};

type IssuanceApiResponse = {
    data: Array<Issuance>;
    pagination: {
        total: number;
        per_page: number;
        current_page: number;
        last_page: number;
        from: number;
        to: number;
    };
};

type Serial = {
    id: number;
    serial_number: any;
    warranty_period: any;
    created_at: any;
    status: any;
};

type SerialApiResponse = {
    data: Array<Serial>;
    pagination: {
        total: number;
        per_page: number;
        current_page: number;
        last_page: number;
        from: number;
        to: number;
    };
};

const queryClient = new QueryClient();


const AdjTable = () => {
    const { id } = useParams();
    const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>([{ id: 'sku', value: id }]);
    const [globalFilter, setGlobalFilter] = useState('');
    const [sorting, setSorting] = useState<MRT_SortingState>([]);

    const navigate = useNavigate();
    const [pagination, setPagination] = useState<MRT_PaginationState>({
        pageIndex: 0,
        pageSize: 10,
    });
    const {
        data: { data = [], pagination: meta } = {},
        isError,
        isRefetching,
        isLoading,
        refetch,
    } = useQuery<AdjustmentApiResponse>({
        queryKey: [
            'table-data',
            columnFilters,
            globalFilter,
            pagination.pageIndex,
            pagination.pageSize,
            sorting,
        ],
        queryFn: async () => {
            try {
                const fetchURL = new URL(`${process.env.REACT_APP_API_SERVER}/adjustments`);
                fetchURL.searchParams.set('page', `${pagination.pageIndex + 1}`);
                fetchURL.searchParams.set('per_page', `${pagination.pageSize}`);
                fetchURL.searchParams.set('filters', JSON.stringify(columnFilters ?? []));
                fetchURL.searchParams.set('globalFilter', globalFilter ?? '');
                fetchURL.searchParams.set('sorting', JSON.stringify(sorting ?? []));

                const response = await axiosClient.get(fetchURL.href);
                const json = response.data as AdjustmentApiResponse;

                console.log(json);

                return json;
            } catch (error) {
                console.error('Error fetching data:', error);
                throw error;
            }
        },
        placeholderData: keepPreviousData,
    });
    const columns = useMemo<MRT_ColumnDef<Adjustments>[]>(
        () => [
            {
                accessorKey: 'sku',
                header: 'SKU',
                size: 100,
            },
            {
                accessorKey: 'created_at',
                header: 'Date',
                size: 40,
                Cell: ({ row }) => {

                    return (
                        <div>
                            {formatDate(row.original.created_at)}
                        </div>
                    );
                },
            },
            {
                accessorKey: 'transaction_number',
                header: 'Transaction Number',
                size: 100,
            },
            {
                accessorKey: 'is_price_adjustment',
                header: 'Price Adjustment?',
                enableColumnFilter: false,
                enableColumnActions: false,
                size: 60,
                Cell: ({ row }) => {
                    const isPrice = row.original.is_price_adjustment;
                    const isPriceAdjustment = isPrice == 1 ? 'Yes' : 'No';

                    return (
                        isPriceAdjustment
                    );
                },
            },
            {
                accessorKey: 'is_quantity_adjustment',
                header: 'Qty. Adjustment?',
                size: 60,
                enableColumnFilter: false,
                enableColumnActions: false,
                Cell: ({ row }) => {
                    const isQuantity = row.original.is_quantity_adjustment;
                    const isQuantityAdjustment = isQuantity == 1 ? 'Yes' : 'No';
                    return (
                        isQuantityAdjustment
                    );
                },
            },
            {
                accessorKey: 'status',
                header: 'Current Status',
                size: 60,
                Cell: ({ row }) => {
                    const status = row.original.status;
                    const statusText = status >= 1 && status <= 3 ? 'Pending' : status >= 4 && status <= 6 ? 'Approved' : 'Unknown';

                    return (
                        <Chip label={statusText} color={statusText == 'Pending' ? 'secondary' : statusText == 'Approved' ? 'success' : 'error'} />
                    );
                },
            },

        ],
        [],
    );

    const table = useMaterialReactTable({
        columns,
        data,
        layoutMode: 'grid',
        enableColumnDragging: false,
        defaultColumn: {
            minSize: 20, //allow columns to get smaller than default
            maxSize: 9001, //allow columns to get larger than default
            size: 180, //make columns wider by default
        },
        initialState: {
            showColumnFilters: true,
            columnVisibility: { sku: false }
        },
        positionToolbarAlertBanner: 'none',
        muiToolbarAlertBannerProps: isError
            ? {
                color: 'error',
                children: 'Error loading data',
            }
            : undefined,
        onColumnFiltersChange: setColumnFilters,
        onGlobalFilterChange: setGlobalFilter,
        onPaginationChange: setPagination,
        onSortingChange: setSorting,
        enableColumnOrdering: true,
        muiTableBodyRowProps: ({ row }) => ({
            onClick: (event) => {
                window.open(`/adjustment-transactions/view/${row.original.transaction_number}`, '_blank');
            },
            sx: {
                cursor: 'pointer', //you might want to change the cursor too when adding an onClick
            },
        }),
        renderTopToolbarCustomActions: () => (
            <Box sx={{ display: 'flex', gap: '1rem', p: '4px', alignItems: 'center' }}>
                <Tooltip arrow title="Refresh Data">
                    <IconButton onClick={() => refetch()}>
                        <RefreshOutlined />
                    </IconButton>
                </Tooltip>
            </Box>
        ),

        rowCount: meta?.total ?? 0,
        state: {
            columnFilters,
            globalFilter,
            isLoading,
            pagination,
            showAlertBanner: isError,
            showProgressBars: isRefetching,
            sorting,
        },
    });



    return (
        <MaterialReactTable table={table} />

        // <>
        //     <MaterialReactTable table={table} />
        // </>
    );
}

const PurTable = () => {
    const { id } = useParams();
    const [purchasesFilter, setPurchaseFilter] = useState<MRT_ColumnFiltersState>([]);
    const [purchaseglobalFilter, setPurchaseGlobalFilter] = useState('');
    const [sorting, setSorting] = useState<MRT_SortingState>([]);

    const navigate = useNavigate();
    const [pagination, setPagination] = useState<MRT_PaginationState>({
        pageIndex: 0,
        pageSize: 10,
    });
    const {
        data: { data = [], pagination: meta } = {},
        isError,
        isRefetching,
        isLoading,
        refetch,
    } = useQuery<TransactionsApiResponse>({
        queryKey: [
            'table-data',
            purchasesFilter,
            purchaseglobalFilter,
            pagination.pageIndex,
            pagination.pageSize,
            sorting,
        ],
        queryFn: async () => {
            try {
                const fetchURL = new URL(`${process.env.REACT_APP_API_SERVER}/pe_transactions/view/${id}`);
                fetchURL.searchParams.set('page', `${pagination.pageIndex + 1}`);
                fetchURL.searchParams.set('per_page', `${pagination.pageSize}`);
                fetchURL.searchParams.set('filters', JSON.stringify(purchasesFilter ?? []));
                fetchURL.searchParams.set('globalFilter', purchaseglobalFilter ?? '');
                fetchURL.searchParams.set('sorting', JSON.stringify(sorting ?? []));

                const response = await axiosClient.get(fetchURL.href);
                const json = response.data as TransactionsApiResponse;

                console.log(json);

                return json;
            } catch (error) {
                console.error('Error fetching data:', error);
                throw error;
            }
        },
        placeholderData: keepPreviousData,
    });
    const columns = useMemo<MRT_ColumnDef<Transactions>[]>(
        () => [
            {
                accessorKey: 'id',
                header: 'Id',
                size: 40,
            },
            {
                accessorKey: 'created_at',
                header: 'Date',
                size: 40,
                Cell: ({ row }) => {

                    return (
                        <div>
                            {formatDate(row.original.created_at)}
                        </div>
                    );
                },
            },
            {
                accessorKey: 'invoice_number',
                header: 'Invoice Number',
                size: 40,
            },
            {
                accessorKey: 'reference_number',
                header: 'Reference Number',
                size: 40,
            },
            {
                accessorKey: 'image',
                header: 'Receipt',
                size: 40,
                enableColumnFilter: false,
                enableColumnActions: false,
                enableSorting: false,
                Cell: ({ row }) => {

                    return (
                        <Image
                            width={50}
                            alt=''
                            src={row.original.image}
                            preview={{
                                width: 'auto',
                                src: row.original.image,
                            }}
                        />
                    );
                },
            },
        ],
        [],
    );

    const table = useMaterialReactTable({
        columns,
        data,
        layoutMode: 'grid',
        enableColumnDragging: false,
        defaultColumn: {
            minSize: 20, //allow columns to get smaller than default
            maxSize: 9001, //allow columns to get larger than default
            size: 180, //make columns wider by default
        },
        initialState: {
            showColumnFilters: true,
            columnVisibility: { id: false }
        },
        positionToolbarAlertBanner: 'none',
        muiToolbarAlertBannerProps: isError
            ? {
                color: 'error',
                children: 'Error loading data',
            }
            : undefined,
        onColumnFiltersChange: setPurchaseFilter,
        onGlobalFilterChange: setPurchaseGlobalFilter,
        onPaginationChange: setPagination,
        onSortingChange: setSorting,
        enableColumnOrdering: true,
        renderTopToolbarCustomActions: () => (
            <Box sx={{ display: 'flex', gap: '1rem', p: '4px', alignItems: 'center' }}>
                <Tooltip arrow title="Refresh Data">
                    <IconButton onClick={() => refetch()}>
                        <RefreshOutlined />
                    </IconButton>
                </Tooltip>
            </Box>
        ),

        rowCount: meta?.total ?? 0,
        state: {
            columnFilters: purchasesFilter,
            globalFilter: purchaseglobalFilter,
            isLoading,
            pagination,
            showAlertBanner: isError,
            showProgressBars: isRefetching,
            sorting,
        },
    });



    return (
        <MaterialReactTable table={table} />

        // <>
        //     <MaterialReactTable table={table} />
        // </>
    );
}


const IssTable = () => {
    const { id } = useParams();
    const [issuanceFilter, setIssuanceFilter] = useState<MRT_ColumnFiltersState>([]);
    const [issuanceGlobalFilter, setIssuanceGlobalFilter] = useState('');
    const [sorting, setSorting] = useState<MRT_SortingState>([]);

    const navigate = useNavigate();
    const [pagination, setPagination] = useState<MRT_PaginationState>({
        pageIndex: 0,
        pageSize: 10,
    });
    const {
        data: { data = [], pagination: meta } = {},
        isError,
        isRefetching,
        isLoading,
        refetch,
    } = useQuery<IssuanceApiResponse>({
        queryKey: [
            'table-data',
            issuanceFilter,
            issuanceGlobalFilter,
            pagination.pageIndex,
            pagination.pageSize,
            sorting,
        ],
        queryFn: async () => {
            try {
                const fetchURL = new URL(`${process.env.REACT_APP_API_SERVER}/ie_transactions/view/${id}`);
                fetchURL.searchParams.set('page', `${pagination.pageIndex + 1}`);
                fetchURL.searchParams.set('per_page', `${pagination.pageSize}`);
                fetchURL.searchParams.set('filters', JSON.stringify(issuanceFilter ?? []));
                fetchURL.searchParams.set('globalFilter', issuanceGlobalFilter ?? '');
                fetchURL.searchParams.set('sorting', JSON.stringify(sorting ?? []));

                const response = await axiosClient.get(fetchURL.href);
                const json = response.data as IssuanceApiResponse;

                console.log(json);

                return json;
            } catch (error) {
                console.error('Error fetching data:', error);
                throw error;
            }
        },
        placeholderData: keepPreviousData,
    });
    const columns = useMemo<MRT_ColumnDef<Issuance>[]>(
        () => [
            {
                accessorKey: 'id',
                header: 'Id',
                size: 40,
            },
            {
                accessorKey: 'date_of_issuance',
                header: 'Issued Date',
                size: 40,
                Cell: ({ row }) => {

                    return (
                        <div>
                            {formatDate(row.original.date_of_issuance)}
                        </div>
                    );
                },
            },
            {
                accessorKey: 'reference_number',
                header: 'Reference Number',
                size: 40,
            },

            {
                accessorKey: 'created_by',
                header: 'Creator',
                size: 40,
                Cell: ({ row }) => {
                    console.log(row.original);
                    return (
                        <div>
                            test
                        </div>
                    );
                },
            },

        ],
        [],
    );

    const table = useMaterialReactTable({
        columns,
        data,
        layoutMode: 'grid',
        enableColumnDragging: false,
        defaultColumn: {
            minSize: 20, //allow columns to get smaller than default
            maxSize: 9001, //allow columns to get larger than default
            size: 180, //make columns wider by default
        },
        initialState: {
            showColumnFilters: true,
            columnVisibility: { id: false }
        },
        positionToolbarAlertBanner: 'none',
        muiToolbarAlertBannerProps: isError
            ? {
                color: 'error',
                children: 'Error loading data',
            }
            : undefined,
        onColumnFiltersChange: setIssuanceFilter,
        onGlobalFilterChange: setIssuanceGlobalFilter,
        onPaginationChange: setPagination,
        onSortingChange: setSorting,
        enableColumnOrdering: true,
        renderTopToolbarCustomActions: () => (
            <Box sx={{ display: 'flex', gap: '1rem', p: '4px', alignItems: 'center' }}>
                <Tooltip arrow title="Refresh Data">
                    <IconButton onClick={() => refetch()}>
                        <RefreshOutlined />
                    </IconButton>
                </Tooltip>
            </Box>
        ),

        rowCount: meta?.total ?? 0,
        state: {
            columnFilters: issuanceFilter,
            globalFilter: issuanceGlobalFilter,
            isLoading,
            pagination,
            showAlertBanner: isError,
            showProgressBars: isRefetching,
            sorting,
        },
    });



    return (
        <MaterialReactTable table={table} />

        // <>
        //     <MaterialReactTable table={table} />
        // </>
    );
}

const SerialTable = () => {
    const { id } = useParams();
    const [issuanceFilter, setIssuanceFilter] = useState<MRT_ColumnFiltersState>([]);
    const [issuanceGlobalFilter, setIssuanceGlobalFilter] = useState('');
    const [sorting, setSorting] = useState<MRT_SortingState>([]);

    const navigate = useNavigate();
    const [pagination, setPagination] = useState<MRT_PaginationState>({
        pageIndex: 0,
        pageSize: 10,
    });
    const {
        data: { data = [], pagination: meta } = {},
        isError,
        isRefetching,
        isLoading,
        refetch,
    } = useQuery<SerialApiResponse>({
        queryKey: [
            'table-data',
            issuanceFilter,
            issuanceGlobalFilter,
            pagination.pageIndex,
            pagination.pageSize,
            sorting,
        ],
        queryFn: async () => {
            try {
                const fetchURL = new URL(`${process.env.REACT_APP_API_SERVER}/serial_numbers/view/${id}`);
                fetchURL.searchParams.set('page', `${pagination.pageIndex + 1}`);
                fetchURL.searchParams.set('per_page', `${pagination.pageSize}`);
                fetchURL.searchParams.set('filters', JSON.stringify(issuanceFilter ?? []));
                fetchURL.searchParams.set('globalFilter', issuanceGlobalFilter ?? '');
                fetchURL.searchParams.set('sorting', JSON.stringify(sorting ?? []));

                const response = await axiosClient.get(fetchURL.href);
                const json = response.data as SerialApiResponse;

                console.log(json);

                return json;
            } catch (error) {
                console.error('Error fetching data:', error);
                throw error;
            }
        },
        placeholderData: keepPreviousData,
    });
    const columns = useMemo<MRT_ColumnDef<Serial>[]>(
        () => [
            {
                accessorKey: 'id',
                header: 'Id',
                size: 40,
            },
            {
                accessorKey: 'created_at',
                header: 'Created Date',
                size: 40,
                Cell: ({ row }) => {

                    return (
                        <div>
                            {formatDate(row.original.created_at)}
                        </div>
                    );
                },
            },
            {
                accessorKey: 'serial_number',
                header: 'Serial Number',
                size: 40,
            },
            {
                accessorKey: 'status',
                header: 'Status',
                size: 40,
            },
            {
                accessorKey: 'warranty_period',
                header: 'Warranty Period',
                size: 40,
                Cell: ({ row }) => {

                    return (
                        <div>
                            {formatDate(row.original.warranty_period)}
                        </div>
                    );
                },
            },

        ],
        [],
    );

    const table = useMaterialReactTable({
        columns,
        data,
        layoutMode: 'grid',
        enableColumnDragging: false,
        defaultColumn: {
            minSize: 20, //allow columns to get smaller than default
            maxSize: 9001, //allow columns to get larger than default
            size: 180, //make columns wider by default
        },
        initialState: {
            showColumnFilters: true,
            columnVisibility: { id: false }
        },
        positionToolbarAlertBanner: 'none',
        muiToolbarAlertBannerProps: isError
            ? {
                color: 'error',
                children: 'Error loading data',
            }
            : undefined,
        onColumnFiltersChange: setIssuanceFilter,
        onGlobalFilterChange: setIssuanceGlobalFilter,
        onPaginationChange: setPagination,
        onSortingChange: setSorting,
        enableColumnOrdering: true,
        renderTopToolbarCustomActions: () => (
            <Box sx={{ display: 'flex', gap: '1rem', p: '4px', alignItems: 'center' }}>
                <Tooltip arrow title="Refresh Data">
                    <IconButton onClick={() => refetch()}>
                        <RefreshOutlined />
                    </IconButton>
                </Tooltip>
            </Box>
        ),

        rowCount: meta?.total ?? 0,
        state: {
            columnFilters: issuanceFilter,
            globalFilter: issuanceGlobalFilter,
            isLoading,
            pagination,
            showAlertBanner: isError,
            showProgressBars: isRefetching,
            sorting,
        },
    });



    return (
        <MaterialReactTable table={table} />

        // <>
        //     <MaterialReactTable table={table} />
        // </>
    );
}
function AdjustmentsTable() {

    return (
        <QueryClientProvider client={queryClient}>
            <AdjTable />
        </QueryClientProvider>

    )
}
function PurchasesTable() {

    return (
        <QueryClientProvider client={queryClient}>
            <PurTable />
        </QueryClientProvider>

    )
}
function IssuanceTable() {

    return (
        <QueryClientProvider client={queryClient}>
            <IssTable />
        </QueryClientProvider>

    )
}
function SerialNumberTable() {

    return (
        <QueryClientProvider client={queryClient}>
            <SerialTable />
        </QueryClientProvider>

    )
}

export { AdjustmentsTable, PurchasesTable, IssuanceTable, SerialNumberTable }