import React from 'react';
import Breadcrumb from 'src/layouts/full/shared/breadcrumb/Breadcrumb';
import PageContainer from 'src/components/container/PageContainer';

import BlankCard from '../../components/shared/BlankCard';
import FormSeparator from './FormSeparator';


const BCrumb = [
  {
    to: '/',
    title: 'Home',
  },
  {
    title: 'Product Entry',
  },
];

const ProductEntry = () => {
  return (
    <PageContainer title="RKCWMS - Product Entry" description="this is Sample page">
      {/* breadcrumb */}
      <Breadcrumb title="Product Entry" items={BCrumb} />
      {/* end breadcrumb */}
      <BlankCard>
        <FormSeparator />
      </BlankCard>

    </PageContainer>
  );
};

export default ProductEntry;
