import React, { FC, useCallback, useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Button,
  Divider,
  Paper,
  Grid,
} from '@mui/material';
import axiosClient from 'src/axios_client';
import dayjs from 'dayjs';
import { Input, Popconfirm, Popover, Steps, message, ConfigProvider } from 'antd';
import { theme } from 'antd/lib';
import {
  LoadingOutlined,
  SmileOutlined,
  SolutionOutlined,
  UserOutlined,
  FormOutlined,
} from '@ant-design/icons';
import { useSelector, AppState } from 'src/store/Store';

interface Product {
  id: number;
  name: string;
  brand: string;
  model: string;
  quantity: number;
  status: number;
  serial_number: string;
  reference_number: string;
}

interface Transaction {
  id: number;
  image: string | null;
  notes: string;
  pickup_by: string;
  destination: number;
  created_by: {
    id: number;
    name: string;
  };
  checked_by: any;
  received_by: any;
  checked_at: any;
  received_at: any;
  received_note: string;
  checked_note: string;
  created_at: string;
  updated_at: string;
  products: Product[];
}

interface TransmittalBodyProps {
  transactionId: string | null;
  userlevel: string | undefined;
  onAcknowledge: () => void;
}

interface ErrorResponse {
  message: string;
  error: string;
}

const TransmittalBody: FC<TransmittalBodyProps> = ({ transactionId, userlevel, onAcknowledge }) => {
  const [transaction, setTransaction] = useState<Transaction | null>(null);
  const [note, setNote] = useState('');
  const [isAcknowledged, setIsAcknowledged] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [messageApi, contextHolder] = message.useMessage();

  // Use useSelector at the top level
  const customizer = useSelector((state: AppState) => state.customizer);
  const currentTheme = customizer.activeMode;

  const [isDarkMode, setIsDarkMode] = useState(currentTheme === 'dark');
  const { defaultAlgorithm, darkAlgorithm } = theme;

  useEffect(() => {
    setIsDarkMode(currentTheme === 'dark');
  }, [currentTheme]);

  const fetchData = useCallback(async () => {
    if (!transactionId) {
      setIsLoading(false);
      return;
    }

    try {
      setIsLoading(true);
      const response = await axiosClient.get(`te_transactions/${transactionId}`);
      const transactionData = response.data.data;
      setTransaction(transactionData);

      // Check if the current user has already acknowledged the transaction
      if (userlevel === 'checker' && transactionData.checked_by?.id) {
        setIsAcknowledged(true);
      } else if (userlevel === 'stockman' && transactionData.received_by?.id) {
        setIsAcknowledged(true);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      messageApi.open({
        type: 'error',
        content: `Contact your system administrator`,
        className: 'robotoFont',
        style: {
          marginTop: '20vh',
        },
      });
    } finally {
      setIsLoading(false);
    }
  }, [transactionId, userlevel, messageApi]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const acknowledge = async () => {
    if (!transactionId) return;

    try {
      const response = await axiosClient.post(`/te_transactions/${transactionId}/acknowledge`, {
        userlevel: userlevel,
        note: note,
      });
      console.log('Acknowledgment successful', response.data);
      messageApi.open({
        type: 'success',
        content: `Acknowledgment successful`,
        className: 'robotoFont',
        style: {
          marginTop: '20vh',
        },
      });
      setIsAcknowledged(true);
      onAcknowledge(); // Call the callback to refresh the parent component
      fetchData(); // Refresh the transaction data
    } catch (error) {
      console.error('Acknowledgment failed', error);
      const errorMessage =
        (error as { response?: { data?: ErrorResponse } }).response?.data?.error ||
        'An unexpected error occurred';
      messageApi.open({
        type: 'error',
        content: `Acknowledgment unsuccessful: ${errorMessage}`,
        className: 'robotoFont',
        style: {
          marginTop: '20vh',
        },
      });
    }
  };

  if (isLoading) {
    return <Typography>Loading...</Typography>;
  }

  if (!transaction) {
    return <Typography>No transaction data available.</Typography>;
  }

  const getCurrentStep = () => {
    if (transaction.received_by?.id) return 3;
    if (transaction.checked_by?.id) return 2;
    if (transaction.created_by?.id) return 1;
    return 0;
  };

  const getStepStatus = (step: number) => {
    const currentStep = getCurrentStep();
    if (step < currentStep) return 'finish';
    if (step === currentStep) return 'process';
    return 'wait';
  };

  const formatDate = (date: string | null) => {
    return date ? dayjs(date).format('MMMM D, YYYY h:mm A') : 'Pending';
  };

  const getName = (user: { id: number | null; name: string | null } | null) => {
    return user?.name || 'Pending';
  };

  return (
    <ConfigProvider
      theme={{
        algorithm: isDarkMode ? darkAlgorithm : defaultAlgorithm,
      }}
    >
      <Paper elevation={3} sx={{ p: 3, mt: 3 }}>
        {contextHolder}
        <Typography variant="h4" gutterBottom>
          Transaction Details
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="body1">
              <strong>Transaction ID:</strong> {transaction.id}
            </Typography>
            <Typography variant="body1">
              <strong>Pickup By:</strong> {transaction.pickup_by}
            </Typography>
            <Typography variant="body1">
              <strong>Destination:</strong>{' '}
              {transaction.destination === 1 ? 'Warehouse - San Miguel Calasiao' : 'Unknown'}
            </Typography>
            <Typography variant="body1">
              <strong>Notes:</strong> {transaction.notes}
            </Typography>
          </Grid>
        </Grid>
        <Divider sx={{ my: 2 }} />

        <Grid container spacing={4}>
          <Grid item xs={12} md={9}>
            <Typography variant="h5" gutterBottom>
              Products
            </Typography>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Brand</TableCell>
                  <TableCell>Model</TableCell>
                  <TableCell>Quantity</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Serial Number</TableCell>
                  <TableCell>Reference Number</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {transaction.products.map((product) => (
                  <TableRow key={product.id}>
                    <TableCell>{product.name}</TableCell>
                    <TableCell>{product.brand}</TableCell>
                    <TableCell>{product.model}</TableCell>
                    <TableCell>{product.quantity}</TableCell>
                    <TableCell>{product.status === 0 ? 'DAMAGED' : 'GOOD'}</TableCell>
                    <TableCell>{product.serial_number}</TableCell>
                    <TableCell>{product.reference_number}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Grid>

          <Grid item xs={12} md={3}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
              <Steps
                current={getCurrentStep()}
                direction="vertical"
                items={[
                  {
                    title: 'Create Transmittal',
                    description: (
                      <>
                        <div>{getName(transaction.created_by)}</div>
                        <div>{formatDate(transaction.created_at)}</div>
                      </>
                    ),
                    icon: <FormOutlined />,
                  },
                  {
                    title: (
                      <Popover
                        content={
                          transaction.checked_note === '' || transaction.checked_note == null
                            ? 'Notes will be here'
                            : transaction.checked_note
                        }
                      >
                        {transaction.checked_by?.id
                          ? 'Cross-Checked by Checker'
                          : 'Pending Cross-Check by Checker'}
                      </Popover>
                    ),
                    description: (
                      <>
                        <div>{getName(transaction.checked_by)}</div>
                        <div>{formatDate(transaction.checked_at)}</div>
                      </>
                    ),
                    icon: transaction.checked_by?.id ? <SolutionOutlined /> : <LoadingOutlined />,
                  },
                  {
                    title: (
                      <Popover
                        content={
                          transaction.received_note === '' || transaction.received_note == null
                            ? 'Notes will be here'
                            : transaction.received_note
                        }
                      >
                        {transaction.received_by?.id
                          ? 'Received by Stockman'
                          : 'Pending Receipt by Stockman'}
                      </Popover>
                    ),
                    description: (
                      <>
                        <div>{getName(transaction.received_by)}</div>
                        <div>{formatDate(transaction.received_at)}</div>
                      </>
                    ),
                    icon: !transaction.checked_by?.id || transaction.received_by?.id ? (
                      <SolutionOutlined />
                    ) : (
                      <LoadingOutlined />
                    ),
                  },
                  {
                    title: 'Done',
                    icon: <SmileOutlined />,
                  },
                ]}
              />
            </Box>
          </Grid>
        </Grid>

        {(userlevel === 'checker' || userlevel === 'stockman' || userlevel === 'superadmin') && (
          <Popconfirm
            title="Acknowledge Transmittal"
            description={
              <>
                <p>Are you sure you want to acknowledge this transmittal?</p>
                <Input.TextArea
                  placeholder="Add a note (optional)"
                  value={note}
                  onChange={(e) => setNote(e.target.value)}
                  rows={4}
                />
              </>
            }
            onConfirm={acknowledge}
            okText="Yes"
            cancelText="No"
            placement="right"
          >
            <Button disabled={isAcknowledged}>
              {isAcknowledged ? 'Acknowledged' : 'Acknowledge'}
            </Button>
          </Popconfirm>
        )}
      </Paper>
    </ConfigProvider>
  );
};

export default TransmittalBody;
