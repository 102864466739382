import React, { useState, useRef, useEffect, useCallback, forwardRef, useImperativeHandle } from 'react';
import { Tag } from 'antd';
import axiosClient from 'src/axios_client';
import debounce from 'lodash/debounce';
import { useSelector, useDispatch } from 'src/store/Store';
import { AppState } from 'src/store/Store';
import { theme } from 'antd/lib';

export interface TagItem {
  value: string;
  exists: boolean;
}

interface CustomMultiInputProps {
  maximumTags: number;
  disabled?: boolean;
}

const acceptExistingTags = true;

const CustomMultiInput = forwardRef<{ getTags: () => TagItem[], setTags: (tags: TagItem[]) => void, clearTags: () => void }, CustomMultiInputProps>(
  ({ maximumTags, disabled = false }, ref) => {
    const [inputValue, setInputValue] = useState<string>('');
    const [tags, setTags] = useState<TagItem[]>([]);
    const inputRef = useRef<HTMLInputElement>(null);

    const checkSerialNumberExists = async (serialNumber: string) => {
      try {
        const response = await axiosClient.get(`/serial_numbers?filter=${serialNumber}`);

        return response.data.data.some((item: any) => item.serial_number === serialNumber);
      } catch (error) {
        console.error('Error checking serial number:', error);

        return false;
      }
    };

    const debouncedCheck = useCallback(
      debounce(async (tag: string) => {
        const exists = await checkSerialNumberExists(tag);
        if (exists) {
          console.error(`Serial number ${tag} already exists.`);
          if (acceptExistingTags) {
            setTags(prevTags => [...prevTags, { value: tag, exists: true }]);
          }
        } else {
          setTags(prevTags => [...prevTags, { value: tag, exists: false }]);
        }
      }, 300),
      []
    );

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setInputValue(e.target.value);
    };

    const handleInputKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === ',' || e.key === 'Enter') {
        e.preventDefault();
        if (tags.length >= maximumTags) {
          console.warn(`Maximum of ${maximumTags} tags reached.`);

          return;
        }
        const newTags = inputValue.split(',')
          .map(tag => tag.trim())
          .filter(tag => tag && !tags.some(t => t.value === tag))
          .map(tag => ({ value: tag, exists: false }));

        newTags.forEach(tag => {
          debouncedCheck(tag.value);
        });

        setInputValue('');
      }
    };

    const handleTagClose = (removedTag: string) => {
      const newTags = tags.filter(tag => tag.value !== removedTag);
      setTags(newTags);
    };

    const handleInputFocus = () => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    };

    useEffect(() => {
      if (!disabled) {
        handleInputFocus();
      }
    }, [disabled]);

    useImperativeHandle(ref, () => ({
      getTags: () => tags,
      setTags: (newTags: TagItem[]) => setTags(newTags),
      clearTags: () => setTags([]),
    }));

    const customizer = useSelector((state: AppState) => state.customizer);
    const currentTheme = customizer.activeMode;

    const [isDarkMode, setIsDarkMode] = useState(false);
    const { defaultAlgorithm, darkAlgorithm } = theme;
    useEffect(() => {
      setIsDarkMode(currentTheme === 'dark');
    }, [currentTheme]);

    return (
      <div
        onClick={handleInputFocus}
        style={{
          display: 'flex',
          alignItems: 'center',
          flexWrap: 'wrap',
          padding: '4px',
          border: disabled ? '1px solid #d9d9d9' : '1px solid #1890ff',
          borderRadius: '4px',
          minHeight: '33px',
          maxHeight: '33px',
          overflowY: 'auto',
          cursor: disabled ? 'not-allowed' : 'text',
          backgroundColor: disabled ? '#f5f5f5' : isDarkMode ? '#292E34' : 'white',
          color: isDarkMode ? 'white' : 'white',
        }}
      >
        {tags.map(tag => (
          <Tag
            key={tag.value}
            closable={!disabled}
            onClose={() => !disabled && handleTagClose(tag.value)}
            color={tag.exists ? 'red' : 'default'}
            style={{ marginBottom: '4px' }}
          >
            {tag.value}
          </Tag>
        ))}
        {tags.length < maximumTags && !disabled && (
          <input
            ref={inputRef}
            value={inputValue}
            onChange={handleInputChange}
            onKeyPress={handleInputKeyPress}
            placeholder="Type values separated by commas"
            style={{
              border: 'none',
              outline: 'none',
              boxShadow: 'none',
              flex: '1 0 auto',
              minWidth: '100px',
              backgroundColor: disabled ? '#f5f5f5' : isDarkMode ? '#292E34' : 'white',
              color: disabled ? '#a0a0a0' : isDarkMode ? '#f0f0f0' : 'black',
            }}
            disabled={disabled}
          />
        )}
      </div>
    );
  }
);

export default CustomMultiInput;
