import { PlusOutlined } from '@ant-design/icons';
import { CloudCircleOutlined, DeleteOutline } from '@mui/icons-material';
import { Alert, Box, Button, Card, CardContent, CardHeader, Divider, Grid, IconButton, Input, InputAdornment, Paper, styled, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material'
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { GetProp, Image, Upload, UploadFile } from 'antd';
import { UploadProps } from 'antd/lib';
import dayjs from 'dayjs';
import { useFormik } from 'formik';
import React, { useState } from 'react'
import axiosClient from 'src/axios_client';
import CustomTextField from 'src/components/theme-elements/CustomTextField';
import * as Yup from 'yup';

type Props = {}


type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0];

const getBase64 = (file: FileType): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const validationSchema = Yup.object().shape({
  destination_name: Yup.string().required('Please provide destination'),
  ipo_number: Yup.string().required('Please provide a ipo number'),
  department: Yup.string().required('Provide department'),
  project_site: Yup.string().required('Provide project site'),
  purpose: Yup.string().required('Provide purpose'),
  requested_by: Yup.string().required('Provide who requested this'),
  approved_by: Yup.string().required('Provide who approved this'),
});

function IpoRequest({ }: Props) {

  const [textFields, setTextFields] = useState([{ value: '', quantity: 1 }]);
  const [imageOpen, setImageOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const handleAddIPO = () => {
    setTextFields([...textFields, { value: '', quantity: 1 }]);
  };

  const handleProductRequestChange = (index: any, event: any) => {
    const values = [...textFields];
    values[index].value = event.target.value;
    setTextFields(values);
  };

  const handleProductQuantityRequestChange = (index: any, event: any) => {
    const values = [...textFields];
    values[index].quantity = event.target.value;
    setTextFields(values);
  };
  const [sucess, setSucess] = useState('');
  const [error, setError] = useState('');

  const formik = useFormik({
    enableReinitialize: false,
    initialValues: {
      destination_name: '',
      ipo_number: '',
      department: '',
      project_site: '',
      purpose: '',
      requested_by: '',
      approved_by: '',
    },
    validateOnChange: false,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      console.log(values);
    },
  });



  const handleDeleteProductRequest = (index: any) => {
    const values = [...textFields];
    values.splice(index, 1);
    setTextFields(values);
  };

  const getNonEmptyTextFields = () => {
    return textFields.filter((textField) => textField.value.trim() !== '' && textField.quantity > 0);
  };

  const handleIPOSubmit = async () => {

    const errors = await formik.validateForm();
    formik.setTouched({ ['destination_name']: true, ['ipo_number']: true, ['department']: true, ['project_site']: true, ['purpose']: true, ['requested_by']: true, ['approved_by']: true });

    console.log(errors);
    if (Object.keys(errors).length === 0) {
      if (getNonEmptyTextFields().length > 0) {

        const formData = new FormData();

        const textFieldData = getNonEmptyTextFields();
        formData.append(`ipo_number`, formik.values.ipo_number);
        formData.append(`destination_name`, formik.values.destination_name);
        formData.append(`department`, formik.values.department);
        formData.append(`project_site`, formik.values.project_site);
        formData.append(`purpose`, formik.values.purpose);
        formData.append(`requested_by`, formik.values.requested_by);
        formData.append(`approved_by`, formik.values.approved_by);
        textFieldData.forEach((ipo: any, index: any) => {
          Object.keys(ipo).forEach(key => {
            formData.append(`ipo_list[${index}][${key}]`, ipo[key]);
          });
        });

        if (fileList.length > 0) {
          formData.append('ipo_approval', fileList[0].originFileObj as Blob);
        }
        else {
          setError('Please upload proof of approval');
          return;
        }

        axiosClient.post(`/ipo_transaction`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
          .then((response) => {
            console.log(response);
          })
          .catch((error) => {
            setError(error.response.data.message);
            console.log(error);
          });

      } else {
        setError('Please check the ipo items');
      }
    }
    console.log(getNonEmptyTextFields());
  }


  const handleImagePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as FileType);
    }

    setPreviewImage(file.url || (file.preview as string));
    setImageOpen(true);
  };

  const handleImageChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  }
  const uploadButton = (
    <button style={{ border: 0, background: 'none' }} type="button">
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </button>
  );

  return (
    <div>
      <Grid container columnSpacing={2} mt={2}>
        <Grid item xs={4} display="flex" alignItems="stretch">
          <Card elevation={12} sx={{ padding: 0, borderColor: (theme: any) => theme.palette.divider }}>
            <>
              <CardHeader title={
                <Box sx={{ textAlign: 'center' }}>
                  <Typography variant='h3'>RAEL KITZ CORPORATION</Typography>
                  <Typography variant='subtitle2'>Centrum Fuel Statio, Brgy. Talibaew, Calasiao, Pangasinan</Typography>
                  <Typography variant='subtitle2'>{`Telefax: (075) 653-0008`}</Typography>

                </Box>
              } />
              <Divider />
            </>
            <CardContent>

              <Grid container columnSpacing={2} rowSpacing={1}>
                <Grid item xs={12}>
                  {sucess &&
                    <Alert
                      variant="filled"
                      severity="success"
                      sx={{ alignItems: "center" }}
                    >
                      {sucess}
                    </Alert>
                  }

                  {error &&
                    <Alert
                      variant="filled"
                      severity="error"
                      sx={{ alignItems: "center" }}
                    >
                      {error}
                    </Alert>
                  }
                  <Grid container sx={{ alignItems: 'center' }}>
                    <Grid item xs={8}>
                      <Typography variant='h6'>INTERNAL PURCHASE ORDER FORM</Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <CustomTextField
                        InputProps={{
                          startAdornment: <InputAdornment position="start">IPO </InputAdornment>,
                        }}
                        sx={{
                          '& .MuiInputBase-root:before': {
                            borderBottom: '1px dashed rgba(0, 0, 0, 0.42)', // Dashed border for unfocused state
                          },
                          '& .MuiInputBase-root:hover:not(.Mui-disabled):before': {
                            borderBottom: '1px dashed rgba(0, 0, 0, 0.87)', // Dashed border for hover state
                          },
                          '& .MuiInputBase-root:after': {
                            borderBottom: '1px solid rgba(0, 0, 0, 0.87)', // Solid border for focused state
                          },
                        }}
                        value={formik.values.ipo_number}
                        name={'ipo_number'}
                        id={'ipo_number'}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.ipo_number && Boolean(formik.errors.ipo_number)}
                        helperText={formik.touched.ipo_number && formik.errors.ipo_number}
                        variant="standard"
                        placeholder="Number"
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={8}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography>
                      TO:
                    </Typography>
                    <TextField
                      sx={{
                        marginLeft: 1,
                        '& .MuiInputBase-root:before': {
                          borderBottom: '1px dashed rgba(0, 0, 0, 0.42)', // Dashed border for unfocused state
                        },
                        '& .MuiInputBase-root:hover:not(.Mui-disabled):before': {
                          borderBottom: '1px dashed rgba(0, 0, 0, 0.87)', // Dashed border for hover state
                        },
                        '& .MuiInputBase-root:after': {
                          borderBottom: '1px solid rgba(0, 0, 0, 0.87)', // Solid border for focused state
                        },
                      }}
                      required
                      fullWidth
                      variant='standard'
                      size='small'
                      placeholder="Enter Destination"
                      value={formik.values.destination_name}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.destination_name && Boolean(formik.errors.destination_name)}
                      helperText={formik.touched.destination_name && formik.errors.destination_name}
                      id="destination_name"
                    />
                  </Box>

                </Grid>
                <Grid item xs={4}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker
                      value={dayjs()}
                      disabled
                      slotProps={{ textField: { size: 'small', variant: "standard" } }}
                      sx={{ width: '100%' }}
                      views={['year', 'month', 'day']}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={6}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography>
                      DEPARTMENT:
                    </Typography>
                    <TextField
                      sx={{
                        marginLeft: 1,
                        '& .MuiInputBase-root:before': {
                          borderBottom: '1px dashed rgba(0, 0, 0, 0.42)', // Dashed border for unfocused state
                        },
                        '& .MuiInputBase-root:hover:not(.Mui-disabled):before': {
                          borderBottom: '1px dashed rgba(0, 0, 0, 0.87)', // Dashed border for hover state
                        },
                        '& .MuiInputBase-root:after': {
                          borderBottom: '1px solid rgba(0, 0, 0, 0.87)', // Solid border for focused state
                        },
                      }}
                      required
                      fullWidth
                      variant='standard'
                      placeholder="Enter Department"
                      size='small'
                      value={formik.values.department}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.department && Boolean(formik.errors.department)}
                      helperText={formik.touched.department && formik.errors.department}
                      id="department"
                    />
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography sx={{ textWrap: 'nowrap' }}>
                      Project Site:
                    </Typography>
                    <TextField
                      sx={{
                        marginLeft: 1,
                        '& .MuiInputBase-root:before': {
                          borderBottom: '1px dashed rgba(0, 0, 0, 0.42)', // Dashed border for unfocused state
                        },
                        '& .MuiInputBase-root:hover:not(.Mui-disabled):before': {
                          borderBottom: '1px dashed rgba(0, 0, 0, 0.87)', // Dashed border for hover state
                        },
                        '& .MuiInputBase-root:after': {
                          borderBottom: '1px solid rgba(0, 0, 0, 0.87)', // Solid border for focused state
                        },
                      }}
                      required
                      fullWidth
                      variant='standard'
                      size='small'
                      id="project_site"
                      placeholder="Enter Project site"
                      value={formik.values.project_site}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.project_site && Boolean(formik.errors.project_site)}
                      helperText={formik.touched.project_site && formik.errors.project_site}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography sx={{ textWrap: 'nowrap' }}>
                      Purpose:
                    </Typography>
                    <TextField
                      sx={{
                        marginLeft: 1,
                        '& .MuiInputBase-root:before': {
                          borderBottom: '1px dashed rgba(0, 0, 0, 0.42)', // Dashed border for unfocused state
                        },
                        '& .MuiInputBase-root:hover:not(.Mui-disabled):before': {
                          borderBottom: '1px dashed rgba(0, 0, 0, 0.87)', // Dashed border for hover state
                        },
                        '& .MuiInputBase-root:after': {
                          borderBottom: '1px solid rgba(0, 0, 0, 0.87)', // Solid border for focused state
                        },
                      }}
                      required
                      fullWidth
                      variant='standard'
                      size='small'
                      id="purpose"
                      placeholder="Enter Purpose"
                      value={formik.values.purpose}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.purpose && Boolean(formik.errors.purpose)}
                      helperText={formik.touched.purpose && formik.errors.purpose}
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} mt={{ sx: 0, md: 3 }}>
                  <TableContainer component={Paper} elevation={12}>
                    <Table size="small" aria-label="a dense table">
                      <TableHead>
                        <TableRow>
                          <TableCell width="15%">QTY</TableCell>
                          <TableCell width="75%">Item Name</TableCell>
                          <TableCell width="10%"></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {textFields.map((textField: any, index: any) => (
                          <TableRow
                            key={index}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                          >
                            <TableCell component="th" scope="row">
                              <TextField
                                variant='standard'
                                type='number'
                                size='small'
                                placeholder="Enter Quantity"
                                fullWidth
                                value={textField.quantity}
                                onChange={(event) => handleProductQuantityRequestChange(index, event)}
                                style={{ flex: 1 }}
                              />
                            </TableCell>
                            <TableCell align="right">
                              <TextField
                                variant='standard'
                                fullWidth
                                size='small'
                                placeholder="Enter Item Name"
                                value={textField.value}
                                onChange={(event) => handleProductRequestChange(index, event)}
                                style={{ flex: 1 }}
                              />
                            </TableCell>
                            <TableCell align="right">
                              <IconButton onClick={() => handleDeleteProductRequest(index)}>
                                <DeleteOutline />
                              </IconButton>
                            </TableCell>
                          </TableRow>

                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>

                <Grid item xs={12} mt={{ sx: 0, md: 3 }}>
                  <Grid container spacing={5}>
                    <Grid item xs={6} sx={{ textAlign: 'center' }}>
                      <TextField
                        sx={{
                          '& .MuiInputBase-root:before': {
                            borderBottom: '1px dashed rgba(0, 0, 0, 0.42)', // Dashed border for unfocused state
                          },
                          '& .MuiInputBase-root:hover:not(.Mui-disabled):before': {
                            borderBottom: '1px dashed rgba(0, 0, 0, 0.87)', // Dashed border for hover state
                          },
                          '& .MuiInputBase-root:after': {
                            borderBottom: '1px solid rgba(0, 0, 0, 0.87)', // Solid border for focused state
                          },
                        }}
                        inputProps={{ style: { textAlign: 'center' } }}
                        required
                        fullWidth
                        variant='standard'
                        size='small'
                        id="requested_by"
                        value={formik.values.requested_by}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.requested_by && Boolean(formik.errors.requested_by)}
                        helperText={formik.touched.requested_by && formik.errors.requested_by}
                      />
                      <Typography variant='h6'>
                        Requested By
                      </Typography>
                    </Grid>

                    <Grid item xs={6} sx={{ textAlign: 'center' }}>
                      <TextField
                        sx={{
                          '& .MuiInputBase-root:before': {
                            borderBottom: '1px dashed rgba(0, 0, 0, 0.42)', // Dashed border for unfocused state
                          },
                          '& .MuiInputBase-root:hover:not(.Mui-disabled):before': {
                            borderBottom: '1px dashed rgba(0, 0, 0, 0.87)', // Dashed border for hover state
                          },
                          '& .MuiInputBase-root:after': {
                            borderBottom: '1px solid rgba(0, 0, 0, 0.87)', // Solid border for focused state
                          },
                        }}
                        inputProps={{ style: { textAlign: 'center' } }}
                        required
                        fullWidth
                        variant='standard'
                        size='small'
                        id="approved_by"
                        value={formik.values.approved_by}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.approved_by && Boolean(formik.errors.approved_by)}
                        helperText={formik.touched.approved_by && formik.errors.approved_by}
                      />
                      <Typography variant='h6'>
                        Approved By
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>



                <Grid item xs={12} mt={{ xs: 0, md: 3 }}>
                  <Upload
                    beforeUpload={() => false}
                    listType="picture-card"
                    fileList={fileList}
                    onPreview={handleImagePreview}
                    onChange={handleImageChange}
                  >
                    {fileList.length >= 1 ? null : uploadButton}
                  </Upload>
                  {previewImage && (
                    <Image
                      alt=''
                      wrapperStyle={{ display: 'none' }}
                      preview={{
                        visible: imageOpen,
                        onVisibleChange: (visible) => setImageOpen(visible),
                        afterOpenChange: (visible) => !visible && setPreviewImage(''),
                      }}
                      src={previewImage}
                    />
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Typography variant='body2' sx={{ fontWeight: 'bold', fontStyle: 'italic', color: 'red' }}>
                    Note: The empty item fields or the quantity 0 won't be saved in request
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Button variant="contained" fullWidth color="primary" onClick={handleAddIPO}>
                        Add Item
                      </Button>
                    </Grid>

                    <Grid item xs={6}>
                      <Button
                        fullWidth
                        variant="contained"
                        color="secondary"
                        onClick={handleIPOSubmit}
                      >
                        Submit
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <p>Total Items: {textFields.length}</p>
            </CardContent>

          </Card>
        </Grid>
        <Grid item xs={8}>
          <Card elevation={12}>
            testsdfsd
          </Card>
        </Grid>
      </Grid>
    </div >
  )
}

export default IpoRequest