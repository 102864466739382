import React, { useState, useEffect, useRef } from 'react';
import {
  Grid,
  Box,
  Card,
  Stack,
  Typography,
  Button,
  Dialog,
  DialogContent,
  useMediaQuery,
} from '@mui/material';
import { ReactComponent as LOGO } from 'src/assets/images/svgs/rkc_logo.svg';
import PageContainer from 'src/components/container/PageContainer';
import AuthLogin from './LoginForm';
import { Slide } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { ReactComponent as LOGO1 } from 'src/assets/images/svgs/rkc_logo_landscape.svg';
import Logo from 'src/layouts/full/shared/logo/Logo';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Login = () => {
  return (
    <PageContainer
      title="RKC WMS IS - Your warehouse management utility"
      description="this is Login page"
    >
      <Box
        sx={{
          position: 'relative',
          '&:before': {
            content: '""',
            background: 'radial-gradient(#d2f1df, #d3d7fa, #bad8f4)',
            backgroundSize: '400% 400%',
            animation: 'gradient 15s ease infinite',
            position: 'absolute',
            height: '100%',
            width: '100%',
            opacity: '0.3',
          },
        }}
      >
        <Grid
          container
          spacing={0}
          justifyContent="center"
          sx={{ height: '100vh', padding: '10px' }}
        >
          <Grid
            item
            xs={12}
            sm={12}
            lg={5}
            xl={4}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Card
              elevation={9}
              sx={{ p: { xs: '40px', md: '40px' }, zIndex: 1, width: '100%', maxWidth: '450px' }}
            >
              <AuthLogin
                subtitle={
                  <Grid container spacing={2} justifyContent="center" mt={1}>
                    <Grid item xs={12} display="flex" justifyContent="center" alignItems="center">
                      <Box display="flex" justifyContent="center">
                        <LOGO1/>
                      </Box>
                    </Grid>
                  </Grid>
                }
              />
            </Card>
          </Grid>
        </Grid>
      </Box>
    </PageContainer>
  );
};

export default Login;
