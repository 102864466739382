import { FC, useState, useEffect } from 'react';
import { Box, Container, Typography, Button } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import ErrorImg from 'src/assets/images/backgrounds/404-error-idea.gif';
import ByeImg from 'src/assets/images/backgrounds/Bye.gif';
import PageContainer from 'src/components/container/PageContainer';

const Logout: FC = () => {
  const [timeRemaining, setTimeRemaining] = useState(3);

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeRemaining((prev) => {
        if (prev <= 1) {
          setTimeout(() => {
            window.location.href = '/auth/login';
          }, 100); // Small delay to ensure the page reloads before navigating

          return 0;
        }

        return prev - 1;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  return (
    <PageContainer title="Goodbye... RKCGPS - Precision in Every Pulse" description="Goodbye">
      <Box
        display="flex"
        flexDirection="column"
        height="100vh"
        textAlign="center"
        justifyContent="center"
      >
        <Container maxWidth="md">
          <img src={ByeImg} alt="404" style={{ width: '100%', maxWidth: '500px' }} />
          <Typography align="center" variant="h1" mb={4}>
            Logout Successfully
          </Typography>
          <Typography align="center" variant="h4" mb={4}>
            Proceeding to authentication page in {timeRemaining}
          </Typography>
          <Button
            color="primary"
            variant="contained"
            component={Link}
            to="/auth/login"
            disableElevation
          >
            Go Back to Login
          </Button>
        </Container>
      </Box>
    </PageContainer>
  );
};

export default Logout;
