import React, { createContext, useState, useContext, ReactNode, useCallback } from 'react';
import { Dayjs } from 'dayjs';

// Define the shape of your context state
interface TransactionContextType {
  supplierId: number | null;
  dateRange: [Dayjs | null, Dayjs | null];
  setSupplierId: (id: number | null) => void;
  setDR: (range: [Dayjs | null, Dayjs | null]) => void;
}

// Create the context with a default value
const TransactionContext = createContext<TransactionContextType>({
  supplierId: null,
  dateRange: [null, null],
  setSupplierId: (id) => {
    console.log('Default context: Supplier ID received', id);
  },
  setDR: (range) => {
    console.log('Default context: Date range received', range);
  },
});

interface SupplierPurchaseProviderProps {
  children: ReactNode;
}

export const SupplierPurchaseProvider = ({ children }: SupplierPurchaseProviderProps) => {
  const [supplierId, setSupplierIdState] = useState<number | null>(() => {
    const stored = localStorage.getItem('supplierId');
    return stored ? Number(stored) : null;
  });
  const [dateRange, setDateRangeState] = useState<[Dayjs | null, Dayjs | null]>(() => {
    const stored = localStorage.getItem('dateRange');
    return stored ? JSON.parse(stored) : [null, null];
  });

  const setSupplierId = useCallback((id: number | null) => {
    console.log('Supplier ID received:', id);
    setSupplierIdState(id);
    if (id) {
      localStorage.setItem('supplierId', id.toString());
    } else {
      localStorage.removeItem('supplierId');
    }
  }, []);

  const setDR = useCallback((range: [Dayjs | null, Dayjs | null]) => {
    console.log('Date range received:', range);
    setDateRangeState(range);
    localStorage.setItem('dateRange', JSON.stringify(range));
  }, []);

  return (
    <TransactionContext.Provider value={{ supplierId, dateRange, setSupplierId, setDR }}>
      {children}
    </TransactionContext.Provider>
  );
};

export const useTransaction = () => {
  return useContext(TransactionContext);
};