import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import Breadcrumb from 'src/layouts/full/shared/breadcrumb/Breadcrumb';
import PageContainer from 'src/components/container/PageContainer';
import DashboardCard from '../../components/shared/DashboardCard';
import { useTransaction } from 'src/contexts/TransmittalTransactionContext';
import { useNavigate } from 'react-router-dom';
import TransmittalBody from './components/TransmittalBody';
import { useEssentialDataContext } from 'src/contexts/EssentialDataContext';

const BCrumb = [
  {
    to: '/',
    title: 'Home',
  },
  {
    title: 'Transmittal View Transaction',
  },
];

const TrasnmittalViewer: React.FC = () => {
  const { transactionId } = useTransaction();
  const navigate = useNavigate();
  const { userData } = useEssentialDataContext();
  const userlevel = userData?.userlevel;
  const [refreshKey, setRefreshKey] = useState(0);

  useEffect(() => {
    if (!transactionId) {
      console.warn('No transaction ID found, redirecting to transmittal entry');
      navigate('/transmittal-entry');
    }
  }, [transactionId, navigate]);

  const handleAcknowledge = () => {
    setRefreshKey((prevKey) => prevKey + 1);
  };

  if (!transactionId) {
    return null;
  }

  return (
    <PageContainer title="RKCWMS - Transmittal View Transaction">
      <Breadcrumb title="Transmittal - View Transaction" items={BCrumb} />

      <TransmittalBody
        key={refreshKey}
        transactionId={transactionId}
        userlevel={userlevel}
        onAcknowledge={handleAcknowledge}
      />
    </PageContainer>
  );
};

export default TrasnmittalViewer;
