import React, { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import Loadable from '../layouts/full/shared/loadable/Loadable';
import ProductMaster from 'src/views/product-master/ProductMaster';
import ProductEntry from 'src/views/product-entry/ProductEntry';
import Brands from 'src/views/brands/Brands';
import Manufacturers from 'src/views/manufacturers/Manufacturers';
import Suppliers from 'src/views/suppliers/Suppliers';
import PaymentTerms from 'src/views/payment-terms/PaymentTerms';
import Locations from 'src/views/locations/Locations';
import ProductCategories from 'src/views/product-categories/ProductCategories';
import Purchases from 'src/views/purchase-entry/Purchases';
import PurchaseEntryViewTransaction from 'src/views/purchase-entry/PurchaseEntryViewTransaction';
import ProductMasterAdjs from 'src/views/product-master-adjs/ProductMasterAdjs';
import ProductAdjustment from 'src/views/product-adjustment/ProductAdjustment';
import AuditLogs from 'src/views/audit-logs/AuditLogs';
import Issuance from 'src/views/issuance/Issuance';
import Customers from 'src/views/customers/Customers';
import AdjustmentTransaction from 'src/views/adjustment-transactions/AdjustmentTransaction';
import IssuanceEntryViewTransaction from 'src/views/issuance/IssuanceEntryViewTransaction';

import AdjustmentDetails from 'src/views/adjustment-transactions/AdjustmentDetails';
import IssuanceReturns from 'src/views/issuance-returns/IssuanceReturns';
import IssuanceReturnEntryViewTransaction from 'src/views/issuance-returns/IssuanceReturnEntryViewTransaction';
import ProductDetails from 'src/views/product-master/ProductDetails';
import PurchasesBySupplier from 'src/views/reports/PurchasesBySupplier';
import PurchasesByProduct from 'src/views/reports/PurchasesByProducts';
import SalesByCustomer from 'src/views/reports/SalesByCustomer';
import SalesByProduct from 'src/views/reports/SalesByProduct';
import ReturnsByCustomer from 'src/views/reports/ReturnsByCustomer';
import ReturnsByProduct from 'src/views/reports/ReturnsByProducts';
import InventorySummary from 'src/views/reports/InventorySummary';
import InventoryAgingSummary from 'src/views/reports/InventoryAgingSummary';
import InventoryValuationSummary from 'src/views/reports/InventoryValuationSummary';
import SelectedSupplierPurchase from 'src/views/reports/PurchasesBySupplier/SelectedSupplierPurchases';
import IpoRequest from 'src/views/IPurchaseOrder/IpoRequest';
import IpoTransactions from 'src/views/IPurchaseOrder/IpoTransactions';
import SelectedProductPurchase from 'src/views/reports/PurchasesByProduct/SelectedProductPurchases';
import SelectedCustomerSales from 'src/views/reports/SalesByCustomer/SelectedCustomerSales';
import SelectedProductSales from 'src/views/reports/SalesByProduct/SelectedProductSales';
import SystemUsers from 'src/views/system-users/SystemUsers';
import Forbidden from 'src/views/errors/403';
import Login from 'src/views/authentication/Login';
import Logout from 'src/views/authentication/Logout';
import Markup from 'src/views/markup/Markup';
import Notifications from 'src/views/notifications/Notifications';
import TransmittalEntry from 'src/views/transmittal-entry/TransmittalEntry';
import QuotationEntry from 'src/views/quotation-entry/QuotationEntry';
import TransmittalViewer from 'src/views/transmittal-entry/TransmittalViewer';
import Quotation from 'src/views/quotation-entry/Quotation';
import Returns from 'src/views/return-products/Returns';
import SelectedProductReturns from 'src/views/reports/ReturnsByProduct/SelectedProductReturns';
import QuotationList from 'src/views/quotation-entry/QuotationList';
import IpoIssuance from 'src/views/IPurchaseOrder/IpoIssuance';

/* ***Layouts**** */
const FullLayout = Loadable(lazy(() => import('../layouts/full/FullLayout')));
const BlankLayout = Loadable(lazy(() => import('../layouts/blank/BlankLayout')));
const FreeLayout = Loadable(lazy(() => import('../layouts/free/FreeLayout')));

/* ****Pages***** */
const Dashboard = Loadable(lazy(() => import('../views/dashboard/Dashboard')));
const Error = Loadable(lazy(() => import('../views/authentication/Error')));

interface RouteObject {
  path: string;
  element: JSX.Element;
  exact?: boolean;
  uac: string[]; // Define uac property
  children?: RouteObject[]; // Make children property optional
}

const Router: RouteObject[] = [
  {
    path: '/',
    element: <FullLayout />,
    uac: [],
    children: [
      { path: '/', element: <Navigate to="/dashboard" />, uac: [] },
      { path: '/dashboard', element: <Dashboard />, uac: [] },
      {
        path: '/product-master',
        element: <ProductMaster />,
        uac: ['superadmin', 'admin', 'purchasing'],
      },
      { path: '/ipo-request', element: <IpoRequest />, uac: ['superadmin', 'admin', 'customer'] },
      { path: '/ipo-transaction', element: <IpoTransactions />, uac: ['superadmin', 'admin', 'customer', 'approver'] },
      {
        path: '/product-master',
        element: <ProductMaster />,
        uac: ['superadmin', 'admin', 'purchasing'],
      },

      {
        path: '/quotation_list',
        element: <QuotationList />,
        uac: ['superadmin', 'admin', 'encoder'],
      },
      {
        path: '/quotation_entry',
        element: <Quotation />,
        uac: ['superadmin', 'admin', 'encoder'],
      },
      {
        path: '/return_products',
        element: <Returns />,
        uac: ['superadmin', 'admin', 'encoder', 'customer'],
      },
      {
        path: '/product-master/view/:id',
        element: <ProductDetails />,
        uac: ['superadmin', 'admin', 'purchasing'],
      },
      {
        path: '/product-master-adjs',
        element: <ProductMasterAdjs />,
        uac: ['superadmin', 'admin', 'control'],
      },
      {
        path: '/adjustment-transactions',
        element: <AdjustmentTransaction />,
        uac: ['superadmin', 'admin', 'control'],
      },
      {
        path: '/adjustment-transactions/view/:id',
        element: <AdjustmentDetails />,
        uac: ['superadmin', 'admin', 'control'],
      },
      {
        path: '/product-adjustment',
        element: <ProductAdjustment />,
        uac: ['superadmin', 'admin', 'purchasing'],
      },
      {
        path: '/product-entry',
        element: <ProductEntry />,
        uac: ['superadmin', 'admin', 'purchasing'],
      },
      { path: '/brands', element: <Brands />, uac: ['superadmin', 'admin', 'purchasing'] },
      {
        path: '/manufacturers',
        element: <Manufacturers />,
        uac: ['superadmin', 'admin', 'purchasing'],
      },
      { path: '/locations', element: <Locations />, uac: ['superadmin', 'admin', 'purchasing'] },
      { path: '/suppliers', element: <Suppliers />, uac: ['superadmin', 'admin', 'purchasing'] },
      { path: '/payment-terms', element: <PaymentTerms />, uac: ['superadmin', 'admin'] },
      { path: '/product-categories', element: <ProductCategories />, uac: ['superadmin', 'admin'] },
      { path: '/markup', element: <Markup />, uac: ['superadmin', 'admin'] },
      { path: '/purchases', element: <Purchases />, uac: ['superadmin', 'admin', 'encoder'] },
      {
        path: '/purchases-entry-view-transaction',
        element: <PurchaseEntryViewTransaction />,
        uac: ['superadmin', 'admin', 'encoder'],
      },
      { path: '/audit-logs', element: <AuditLogs />, uac: ['superadmin', 'admin'] },
      {
        path: '/issuance',
        element: <Issuance />,
        uac: ['superadmin', 'admin', 'encoder', 'stockman', 'checker', 'customer'],
      },
      {
        path: '/ipo_issuance',
        element: <IpoIssuance />,
        uac: ['superadmin', 'admin', 'encoder', 'stockman'],
      },
      {
        path: '/issuance-entry-view-transaction',
        element: <IssuanceEntryViewTransaction />,
        uac: ['superadmin', 'admin', 'encoder', 'checker', 'stockman', 'customer'],
      },
      {
        path: '/issuance-returns',
        element: <IssuanceReturns />,
        uac: ['superadmin', 'admin', 'encoder', 'checker', 'stockman', 'customer'],
      },
      {
        path: '/issuance-return-entry-view-transaction',
        element: <IssuanceReturnEntryViewTransaction />,
        uac: ['superadmin', 'admin', 'encoder', 'checker', 'stockman', 'customer'],
      },
      { path: '/customers', element: <Customers />, uac: ['superadmin', 'admin', 'purchasing'] },
      {
        path: '/report-purchases-by-supplier',
        element: <PurchasesBySupplier />,
        uac: ['superadmin', 'admin', 'purchasing', 'encoder', 'control'],
      },
      {
        path: '/report-purchases-by-selected-supplier',
        element: <SelectedSupplierPurchase />,
        uac: ['superadmin', 'admin', 'purchasing', 'encoder', 'control'],
      },
      {
        path: '/report-purchases-by-product',
        element: <PurchasesByProduct />,
        uac: ['superadmin', 'admin', 'purchasing', 'encoder', 'control'],
      },
      {
        path: '/report-purchases-by-selected-product',
        element: <SelectedProductPurchase />,
        uac: ['superadmin', 'admin', 'purchasing', 'encoder', 'control'],
      },
      {
        path: '/report-sales-by-customer',
        element: <SalesByCustomer />,
        uac: ['superadmin', 'admin', 'purchasing', 'encoder', 'control'],
      },
      {
        path: '/report-sales-by-selected-customer',
        element: <SelectedCustomerSales />,
        uac: ['superadmin', 'admin', 'purchasing', 'encoder', 'control'],
      },
      {
        path: '/report-sales-by-product',
        element: <SalesByProduct />,
        uac: ['superadmin', 'admin', 'purchasing', 'encoder', 'control'],
      },
      {
        path: '/report-sales-by-selected-product',
        element: <SelectedProductSales />,
        uac: ['superadmin', 'admin', 'purchasing', 'encoder', 'control'],
      },
      {
        path: '/report-returns-by-customer',
        element: <ReturnsByCustomer />,
        uac: ['superadmin', 'admin', 'purchasing', 'encoder', 'control'],
      },
      {
        path: '/report-returns-by-product',
        element: <ReturnsByProduct />,
        uac: ['superadmin', 'admin', 'purchasing', 'encoder', 'control'],
      },
      {
        path: '/report-returns-by-selected-product',
        element: <SelectedProductReturns />,
        uac: ['superadmin', 'admin', 'purchasing', 'encoder', 'control'],
      },
      {
        path: '/report-inventory-summary',
        element: <InventorySummary />,
        uac: ['superadmin', 'admin', 'purchasing', 'encoder', 'control'],
      },
      {
        path: '/report-inventory-aging-summary',
        element: <InventoryAgingSummary />,
        uac: ['superadmin', 'admin', 'purchasing', 'encoder', 'control'],
      },
      {
        path: '/report-inventory-valuation-summary',
        element: <InventoryValuationSummary />,
        uac: ['superadmin', 'admin', 'purchasing', 'encoder', 'control'],
      },
      {
        path: '/report-returns-by-product',
        element: <ReturnsByProduct />,
        uac: ['superadmin', 'admin', 'purchasing', 'encoder', 'control'],
      },
      { path: '/system-users', element: <SystemUsers />, uac: ['superadmin', 'admin'] },
      { path: '/notifications', element: <Notifications />, uac: [] },
      {
        path: '/transmittal-entry',
        element: <TransmittalEntry />,
        uac: ['superadmin', 'admin', 'encoder', 'checker', 'stockman', 'customer'],
      },
      {
        path: '/transmittal-viewer',
        element: <TransmittalViewer />,
        uac: ['superadmin', 'admin', 'encoder', 'checker', 'stockman', 'customer'],
      },
      { path: '*', element: <Navigate to="/auth/404" />, uac: [] },
      // report-purchases-by-supplier
    ],
  },
  {
    path: '/auth',
    element: <BlankLayout />,
    uac: [], // Set uac for auth route
    children: [
      { path: 'login', element: <Login />, uac: [] },
      { path: 'logout', element: <Logout />, uac: [] },
      { path: '*', element: <Navigate to="/error/404" />, uac: [] },
    ],
  },
  {
    path: '/error',
    element: <FreeLayout />,
    uac: [], // Set uac for error route
    children: [
      { path: '404', element: <Error />, uac: [] },
      { path: '403', element: <Forbidden />, uac: [] },
    ],
  },
];

export default Router;
