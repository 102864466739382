import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import Breadcrumb from 'src/layouts/full/shared/breadcrumb/Breadcrumb';
import PageContainer from 'src/components/container/PageContainer';
import DashboardCard from 'src/components/shared/DashboardCard';
import SelectedProductSalesTable from './SelectedProductSalesTable';
import { useTransaction } from 'src/contexts/ProductSalesContext';
import { useNavigate } from 'react-router';

const BCrumb = [
  {
    to: '/',
    title: 'Home',
  },
  {
    title: 'Reports',
  },
  {
    title: 'Product By Product Page',
  },
];

const SelectedProductSales = () => {

  const { productId, dateRange } = useTransaction();
  const navigate = useNavigate();

  console.log("ProductID: ",productId)
  console.log("dateRange: ",dateRange)

  useEffect(() => {
    if (!productId) {
      console.warn('No transaction ID found, redirecting to purchase report');
      navigate('/report-sales-by-product');
    }
  }, [productId, navigate]);

  if (!productId) {
    return null; // or show a loading spinner/message
  }

  return (
    <PageContainer title="RKCWMS - Product By Product Page" description="this is Sample page">
      {/* breadcrumb */}
      <Breadcrumb title="Product By Product Page" items={BCrumb} />
      {/* end breadcrumb */}
      <DashboardCard title="Product By Product Page">
        <Box>
          <SelectedProductSalesTable productId={productId} dateRange={dateRange} />
        </Box>
      </DashboardCard>
    </PageContainer>
  );
};

export default SelectedProductSales;
