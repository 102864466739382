import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import { DatePicker, Select } from 'antd';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import 'dayjs/locale/en'; // Import the English locale

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('Asia/Manila');

const { RangePicker } = DatePicker;

interface DateSelectorProps {
  onDateChange: (dates: [dayjs.Dayjs | null, dayjs.Dayjs | null]) => void;
}

const DateSelector: React.FC<DateSelectorProps> = ({ onDateChange }) => {
  const [dateRange, setDateRange] = useState<[dayjs.Dayjs, dayjs.Dayjs]>([dayjs(), dayjs()]);
  const [selectedOption, setSelectedOption] = useState('today');

  useEffect(() => {
    updateDateRange(selectedOption);
  }, [selectedOption]);

  const updateDateRange = (option: string) => {
    let start: dayjs.Dayjs, end: dayjs.Dayjs;
    switch (option) {
      case 'today':
        start = dayjs().startOf('day');
        end = dayjs().endOf('day');
        break;
      case 'this_week':
        start = dayjs().startOf('week');
        end = dayjs().endOf('week');
        break;
      case 'this_month':
        start = dayjs().startOf('month');
        end = dayjs().endOf('month');
        break;
      case 'this_year':
        start = dayjs().startOf('year');
        end = dayjs().endOf('year');
        break;
      case 'yesterday':
        start = end = dayjs().subtract(1, 'day');
        break;
      case 'prev_week':
        start = dayjs().subtract(1, 'week').startOf('week');
        end = dayjs().subtract(1, 'week').endOf('week');
        break;
      case 'prev_month':
        start = dayjs().subtract(1, 'month').startOf('month');
        end = dayjs().subtract(1, 'month').endOf('month');
        break;
      case 'prev_year':
        start = dayjs().subtract(1, 'year').startOf('year');
        end = dayjs().subtract(1, 'year').endOf('year');
        break;
      default:
        start = dayjs().startOf('day');
        end = dayjs().endOf('day');
    }
    const newDateRange: [dayjs.Dayjs, dayjs.Dayjs] = [start, end];
    setDateRange(newDateRange);
    onDateChange(newDateRange);
  };

  const handleSelectChange = (value: string) => {
    setSelectedOption(value);
  };

  const handleRangePickerChange = (dates: [dayjs.Dayjs | null, dayjs.Dayjs | null] | null) => {
    if (selectedOption === 'custom' && dates) {
      const newDateRange: [dayjs.Dayjs, dayjs.Dayjs] = [dates[0] || dayjs(), dates[1] || dayjs()];
      setDateRange(newDateRange);
      onDateChange(newDateRange);
    }
  };

  return (
    <>
      <Select
        value={selectedOption}
        style={{ width: 150 }}
        size="large"
        onChange={handleSelectChange}
        options={[
          { value: 'today', label: 'Today' },
          { value: 'this_week', label: 'This Week' },
          { value: 'this_month', label: 'This Month' },
          { value: 'this_year', label: 'This Year' },
          { value: 'yesterday', label: 'Yesterday' },
          { value: 'prev_week', label: 'Previous Week' },
          { value: 'prev_month', label: 'Previous Month' },
          { value: 'prev_year', label: 'Previous Year' },
          { value: 'custom', label: 'Custom' },
        ]}
        listHeight={450}
      />
      <RangePicker
        value={dateRange}
        onChange={handleRangePickerChange}
        disabled={selectedOption !== 'custom'}
      />
    </>
  );
};

export default DateSelector;
