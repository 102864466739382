import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Box,
  DialogActions,
  Button,
} from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';
import CustomTextField from 'src/components/theme-elements/CustomTextField';
import axiosClient from 'src/axios_client';
import StatusAlert from 'src/components/shared/AlertResponse'; // Adjust the import path as per your directory structure

const validationSchema = yup.object({
  name: yup.string().required('Name is required'),
  description: yup.string().required('Description is required'),
});

type DialogFormProps = {
  isOpen: boolean;
  isEditing: boolean;
  initialData: { id: number; name: string; description: string } | null;
  onClose: () => void;
  onSubmit: (payload: { id: number | null; name: string; description: string }) => void;
  refetch: () => void;
};

const DialogForm: React.FC<DialogFormProps> = ({
  isOpen,
  isEditing,
  initialData,
  onClose,
  onSubmit,
  refetch,
}) => {
  const [statusCode, setStatusCode] = useState(0);
  const [statusData, setStatusData] = useState([]);

  let apiResponse = 0;
  const formik = useFormik({
    initialValues: {
      id: initialData?.id || null,
      name: initialData?.name || '',
      description: initialData?.description || '',
    },
    validationSchema,
    onSubmit: async (values, { resetForm }) => {
      const payload = {
        id: isEditing ? values.id : 0,
        name: values.name,
        description: values.description,
      };

      try {
        if (isEditing) {
          const response = await axiosClient.put(`/manufacturers/${payload?.id}`, {
            name: payload.name,
            description: payload.description,
          });

          const updatedManufacturer = response.data;

          console.log('Server Response:', updatedManufacturer);
          apiResponse = response.status;
        } else {
          const response = await axiosClient.post('/manufacturers', {
            name: payload.name,
            description: payload.description,
          });

          const addedMsp = response.data;
          console.log('Server Response:', addedMsp);
          apiResponse = response.status;
        }

        resetForm();

        refetch();

        onClose();
      } catch (error: any) {
        if (error.response && error.response.status === 422) {
          const errorMessage = error.response.data;
          apiResponse = error.response.status;

          setStatusData(errorMessage);
        } else if (error.response && error.response.status) {
          apiResponse = error.response.status;
        }
      }
      setStatusCode(apiResponse);
      onSubmit(payload);
    },
  });

  useEffect(() => {
    formik.setValues({
      id: initialData?.id || null,
      name: initialData?.name || '',
      description: initialData?.description || '',
    });
  }, [initialData]);

  return (
    <>
      {(statusCode || statusCode !== 0) && (
        <StatusAlert statusCode={statusCode} statusData={statusData} />
      )}
      <Dialog open={isOpen} onClose={onClose}>
        {/* Render StatusAlert if statusCode is set */}
        <form onSubmit={formik.handleSubmit}>
          <DialogTitle>
            {isEditing ? `Edit Manufacturer [${initialData?.name}]` : 'Add Manufacturer'}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>You are editing the device setup.</DialogContentText>
            <Box mt={2}>
              <CustomTextField
                autoFocus
                margin="dense"
                id="name"
                name="name"
                label="Name"
                type="text"
                fullWidth
                value={formik.values.name}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <CustomTextField
                margin="dense"
                id="description"
                name="description"
                label="Description"
                type="text"
                fullWidth
                value={formik.values.description}
                error={formik.touched.description && Boolean(formik.errors.description)}
                helperText={formik.touched.description && formik.errors.description}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </Box>
          </DialogContent>
          <DialogActions sx={{ mb: 2, mr: 2 }}>
            <Button color="error" onClick={onClose}>
              Cancel
            </Button>
            <Button type="submit">{isEditing ? 'Update' : 'Add'}</Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default React.memo(DialogForm);
