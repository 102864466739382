import { Check, RefreshOutlined } from '@mui/icons-material';
import { Alert, Box, Button, Card, CardContent, CardHeader, Chip, Container, Divider, Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material';
import { ClearIcon } from '@mui/x-date-pickers';
import { IconEdit, IconTrash } from '@tabler/icons';
import { keepPreviousData, QueryClient, QueryClientProvider, useQuery } from '@tanstack/react-query';
import { Image } from 'antd';
import { MaterialReactTable, MRT_ColumnDef, MRT_ColumnFiltersState, MRT_PaginationState, MRT_SortingState, useMaterialReactTable } from 'material-react-table';
import React, { useMemo, useState } from 'react'
import { AnyIfEmpty, useSelector } from 'react-redux';
import axiosClient from 'src/axios_client';
import PageContainer from 'src/components/container/PageContainer';
import { useEssentialDataContext } from 'src/contexts/EssentialDataContext';
import { formatDate } from 'src/globals/PublicFunctions';
import Breadcrumb from 'src/layouts/full/shared/breadcrumb/Breadcrumb';
import { AppState } from 'src/store/Store';

type Props = {}


const BCrumb = [
  {
    to: '/',
    title: 'Home',
  },
  {
    title: 'IPO Transactions',
  },
];



const queryClient = new QueryClient();

function IpoTransactions({ }: Props) {

  const customizer = useSelector((state: AppState) => state.customizer);
  const theme = useTheme();
  const borderColor = theme.palette.grey[100];
  const isSmallScreen = useMediaQuery('(max-width:375px)');

  return (
    <PageContainer title="RKCWMS - Product Master Page" description="this is Sample page">
      {/* breadcrumb */}
      <Breadcrumb title="Product Master Page" items={BCrumb} />
      {/* end breadcrumb */}
      <Card sx={{
        padding: 0,
        border: !customizer.isCardShadow ? `1px solid ${borderColor}` : 'none',
        maxWidth: isSmallScreen ? '345px' : 'auto',
      }} style={{ height: '100%', position: 'relative' }}>

        <QueryClientProvider client={queryClient}>
          <IPOTableList />
        </QueryClientProvider>
      </Card>
    </PageContainer>
  );

}

type IPO = {
  id: number;
  user_id: string;
  ipo_number: string;
  destination: string;
  department: any;
  project_site: any;
  purpose: any;
  requested_by: any;
  approved_by: any;
  image_name: any;
  image_url: any;
  status: any;
  created_at: any;
  ipo_requests: any[];
};

type IPOApiResponse = {
  data: Array<IPO>;
  pagination: {
    total: number;
    per_page: number;
    current_page: number;
    last_page: number;
    from: number;
    to: number;
  };
};

// status == 0 ? 'Pending' : status == 1 ? 'Approved' : status == 2 ? 'Rejected' : status == 3 ? 'Incomplete' : 'Completed'
const statusSelect = [{ label: 'Pending', value: '0' }, { label: 'Approved', value: '1' }, { label: 'Rejected', value: '2' }, { label: 'Incomplete', value: '3' }, { label: 'Completed', value: '4' }];
const IPOTableList = () => {

  const { userData } = useEssentialDataContext();

  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>([
    {
      id: 'status',
      value: 0
    }
  ]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const {
    data: { data = [], pagination: meta } = {},
    isError,
    isRefetching,
    isLoading,
    refetch,
  } = useQuery<IPOApiResponse>({
    queryKey: [
      'table-data',
      columnFilters,
      globalFilter,
      pagination.pageIndex,
      pagination.pageSize,
      sorting,
    ],
    queryFn: async () => {
      try {
        const fetchURL = new URL(`${process.env.REACT_APP_API_SERVER}/ipo_transaction`);
        fetchURL.searchParams.set('page', `${pagination.pageIndex + 1}`);
        fetchURL.searchParams.set('per_page', `${pagination.pageSize}`);
        fetchURL.searchParams.set('filters', JSON.stringify(columnFilters ?? []));
        fetchURL.searchParams.set('globalFilter', globalFilter ?? '');
        fetchURL.searchParams.set('sorting', JSON.stringify(sorting ?? []));

        const response = await axiosClient.get(fetchURL.href);
        const json = response.data as IPOApiResponse;

        console.log(json);

        return json;
      } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
      }
    },
    placeholderData: keepPreviousData,
  });
  const columns = useMemo<MRT_ColumnDef<IPO>[]>(
    () => [
      {
        accessorKey: 'ipo_number',
        header: 'Transaction Number',
        size: 180,
        Cell: ({ row }) => {

          return (
            <Typography sx={{ fontWeight: 'bold' }}>
              IPT-{row.original.ipo_number.toString().padStart(5, '0')}
            </Typography>
          );
        },
      },
      {
        accessorKey: 'image_url',
        header: 'Receipt',
        size: 40,
        enableColumnFilter: false,
        enableColumnActions: false,
        enableSorting: false,
        Cell: ({ row }) => {
          return (
            <Image
              width={50}
              alt=''
              src={row.original.image_url}
              preview={{
                width: 'auto',
                src: row.original.image_url,
              }}
            />
          );
        },
      },
      {
        accessorKey: 'destination',
        header: 'Destination Name',
        size: 180,
        enableColumnFilter: false,
        enableColumnActions: false,
        enableSorting: false,
      },
      {
        accessorKey: 'department',
        header: 'Department Name',
        size: 180,
        enableColumnFilter: false,
        enableColumnActions: false,
        enableSorting: false,
      },
      {
        accessorKey: 'project_site',
        header: 'Project Site Name',
        size: 180,
        enableColumnFilter: false,
        enableColumnActions: false,
        enableSorting: false,
      },
      {
        accessorKey: 'requested_by',
        header: 'Requested By',
        size: 180,
      },

      {
        accessorKey: 'status',
        header: 'Status',
        filterVariant: 'select',
        filterSelectOptions: statusSelect,
        size: 20,
        Cell: ({ row }) => {

          console.log(row.original);
          const status = row.original.status;
          const statusText = status == 0 ? 'Pending' : status == 1 ? 'Approved' : status == 2 ? 'Rejected' : status == 3 ? 'Incomplete' : 'Completed';

          return (

            <Chip
              label={statusText}
              color={status == 0 ? 'info' : status == 1 ? 'success' : status == 2 ? 'error' : status == 3 ? 'warning' : 'success'}
              size="small"
              sx={{
                ml: 1,
                fontSize: '0.8rem',
                height: '22px',
                minWidth: '50px',
              }}
            />
          );
        },
      },
      {
        accessorKey: 'created_at',
        header: 'Date Created',
        size: 40,
        enableColumnFilter: false,
        enableColumnActions: false,
        enableSorting: false,
        Cell: ({ row }) => {

          return (
            <>
              {formatDate(row.original.created_at)}
            </>
          );
        },
      },

      {
        accessorKey: 'action',
        header: 'Action',
        size: 20,
        Cell: ({ row }) => {

          return (

            <>
              <Grid container spacing={2} display={row.original.status == 0 ? 'flex' : 'none'}>
                <Grid item xs={6}>
                  <Button onClick={() => approvedClick(row.original.id)} size='small' variant="contained" color='success' >
                    <Check />
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button onClick={() => declineClick(row.original.id)} size='small' variant="contained" color='error' >
                    <ClearIcon />
                  </Button>
                </Grid>
              </Grid>
            </>
          );
        },
      },
    ],
    [],
  );

  const approvedClick = (passedResult: any) => {

    console.log(passedResult);
    axiosClient
      .put(`/ipo_transaction/${passedResult}`, {
        approved: true
      })
      .then((response) => {
        refetch();
      })
      .catch((error) => {
        console.error('Failed to update IPO Transaction:', error);
      });

  }
  const declineClick = (passedResult: any) => {

    console.log(passedResult);

    axiosClient
      .put(`/ipo_transaction/${passedResult}`, {
        approved: false
      })
      .then((response) => {
        refetch();

      })
      .catch((error) => {
        console.error('Failed to update IPO Transaction:', error);
      });
  }

  const table = useMaterialReactTable({
    columns,
    data,
    initialState: {
      showColumnFilters: true,
      columnPinning: {
        left: ['mrt-row-expand'],
      },
      columnVisibility: {
        action: userData?.userlevel == 'approver' || userData?.userlevel == 'superadmin' ? true : false
      }
    },
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    muiToolbarAlertBannerProps: isError
      ? {
        color: 'error',
        children: 'Error loading data',
      }
      : undefined,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    enableColumnOrdering: true,
    muiTableBodyRowProps: ({ row }) => ({
      onClick: (event) => {
        console.info(row);
      },
      sx: {
        cursor: 'pointer', //you might want to change the cursor too when adding an onClick
      },
    }),
    renderTopToolbarCustomActions: () => (
      <Box sx={{ display: 'flex', gap: '1rem', p: '4px', alignItems: 'center' }}>
        <Tooltip arrow title="Refresh Data">
          <IconButton onClick={() => refetch()}>
            <RefreshOutlined />
          </IconButton>
        </Tooltip>
      </Box>
    ),
    renderDetailPanel: ({ row }) => {

      return (

        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Card elevation={6}>
              <>
                <CardHeader title={
                  <Box sx={{ textAlign: 'center' }}>
                    <Typography variant='h3'>RAEL KITZ CORPORATION</Typography>
                    <Typography variant='subtitle2'>Centrum Fuel Statio, Brgy. Talibaew, Calasiao, Pangasinan</Typography>
                    <Typography variant='subtitle2'>{`Telefax: (075) 653-0008`}</Typography>

                  </Box>
                } />
                <Divider />
              </>
              <CardContent>

                <Grid container spacing={0.5}>
                  <Grid item xs={12}>

                    <Grid container sx={{ alignItems: 'center' }}>
                      <Grid item xs={8}>
                        <Typography variant='h6'>INTERNAL PURCHASE ORDER FORM</Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography variant='h6' sx={{ fontWeight: 'bold', textAlign: 'right' }}>
                          IPT-{row.original.ipo_number.toString().padStart(5, '0')}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={8}>
                    <Grid container spacing={1}>
                      <Grid item>
                        <Typography>
                          TO:
                        </Typography>
                      </Grid>
                      <Grid item xs>
                        <Typography>
                          {row.original.destination}
                        </Typography>
                        <Divider sx={{ borderColor: 'black', borderStyle: 'dashed' }} />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={4} sx={{ textAlign: 'right' }}>
                    <Chip
                      label={formatDate(row.original.created_at)}
                      color={'info'}
                      variant='outlined'
                      size="small"
                      sx={{
                        ml: 1,
                        fontSize: '0.8rem',
                        height: '22px',
                        minWidth: '50px',
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Grid container spacing={1}>
                      <Grid item>
                        <Typography>
                          Department:
                        </Typography>
                      </Grid>
                      <Grid item xs>
                        <Typography>
                          {row.original.department}
                        </Typography>
                        <Divider sx={{ borderColor: 'black', borderStyle: 'dashed' }} />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={6}>
                    <Grid container spacing={1}>
                      <Grid item>
                        <Typography>
                          Project Site:
                        </Typography>
                      </Grid>
                      <Grid item xs>
                        <Typography>
                          {row.original.project_site}
                        </Typography>
                        <Divider sx={{ borderColor: 'black', borderStyle: 'dashed' }} />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={1}>
                      <Grid item>
                        <Typography>
                          Purpose:
                        </Typography>
                      </Grid>
                      <Grid item xs>
                        <Typography>
                          {row.original.purpose}
                        </Typography>
                        <Divider sx={{ borderColor: 'black', borderStyle: 'dashed' }} />
                      </Grid>
                    </Grid>

                  </Grid>

                  <Grid item xs={12} mt={3}>
                    <TableContainer component={Paper} elevation={12}>
                      <Table size="small" aria-label="a dense table">
                        <TableHead>
                          <TableRow>
                            <TableCell>QTY</TableCell>
                            <TableCell align="right">Item Name</TableCell>
                            <TableCell align="right">Unit Price</TableCell>
                            <TableCell align="right">Amount</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {row.original.ipo_requests.map((row) => (
                            <TableRow
                              key={row.id}
                              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                              <TableCell component="th" scope="row">
                                {row.quantity}
                              </TableCell>
                              <TableCell align="right">{row.product_name}</TableCell>
                              <TableCell align="right"></TableCell>
                              <TableCell align="right"></TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>

                  <Grid item xs={12} mt={3}>
                    <Grid container spacing={{ sm: 1, md: 5 }}>
                      <Grid item xs={6} sx={{ textAlign: 'center' }}>
                        <Typography>
                          {row.original.approved_by}
                        </Typography>
                        <Divider sx={{ borderColor: 'black', borderStyle: 'dashed' }} />
                        <Typography variant='h6'>
                          Approved By
                        </Typography>
                      </Grid>

                      <Grid item xs={6} sx={{ textAlign: 'center' }}>
                        <Typography>
                          {row.original.requested_by}
                        </Typography>
                        <Divider sx={{ borderColor: 'black', borderStyle: 'dashed' }} />
                        <Typography variant='h6'>
                          Requested By
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>



                  {/* <Grid item xs={12}>
                    <Typography variant='body2' sx={{ fontWeight: 'bold', fontStyle: 'italic', color: 'red' }}>
                      Note: The empty item fields or the quantity 0 won't be saved in request
                    </Typography>
                  </Grid> */}
                </Grid>

              </CardContent>
            </Card>
          </Grid>

        </Grid>


      );
    },
    rowCount: meta?.total ?? 0,
    state: {
      columnFilters,
      globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      sorting,
    },
  });
  return (
    <>
      <MaterialReactTable table={table} />
    </>
  );
};

export default IpoTransactions