import { Close, KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { Alert, Autocomplete, Avatar, Box, Button, Card, Chip, Collapse, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControlLabel, FormLabel, Grid, IconButton, InputAdornment, Paper, Radio, RadioGroup, Stack, Step, StepLabel, Stepper, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography } from '@mui/material'
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { Field, FieldProps, FormikProvider, useFormik } from 'formik';
import React, { FC, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux';
import axiosClient from 'src/axios_client';
import PageContainer from 'src/components/container/PageContainer'
import BlankCard from 'src/components/shared/BlankCard';
import CustomCheckbox from 'src/components/theme-elements/CustomCheckbox';
import CustomTextField from 'src/components/theme-elements/CustomTextField';
import { LogoDark, LogoLight } from 'src/globals/LogoExports';
import { formatCurrency, NumericFormatCustom } from 'src/globals/NumericFormat';
import useFormattedNumber from 'src/hooks/useFormattedNumber';
import { AppState } from 'src/store/Store';
import * as Yup from 'yup';


type Props = {}


type SerialData = {
    value: any;
    is_used: any;
    price: any;
    inSystem: any
    id: any;
};
const ProductReturnStatusOptions = [
    { value: 'good', label: 'Good' },
    { value: 'repair', label: 'For Repair' },
    { value: 'broken', label: 'Broken' },
];
const ProductLocationDivider = [
    { value: 'A', label: 'A' },
    { value: 'B', label: 'B' },
    { value: 'C', label: 'C' },
    { value: 'D', label: 'D' },
    { value: 'E', label: 'E' },
];
const ProductLocationFloor = [
    { value: '1', label: '1' },
    { value: '2', label: '2' },
    { value: '3', label: '3' },
    { value: '4', label: '4' },
    { value: '5', label: '5' },
];
type ProductOptions = {
    id: string,
    name: any,
    sku: any,
    has_serial: any,
    unit: any,
    description: any,
    floating_quantity: any,

    model: any,
    brand: any,
    image: any,
    buying_price: any,
    selling_price: any,
    qty_before: any,
    quantity: any
};
type CustomerOptions = {
    id: any,
    name: any,
    customer_code: any,
    customer_address: any,
    contact_address: any,
    pure_name: any,
};


type ProductTransmital = {
    id: any,
    transmittal_id: any,
    product_name: any,
    brand: any,
    model: any,
    quantity: any,
    remaining_quantity: any,
    serial_number: any,
    reference_number: any,
    transaction: [],
}
type TransmitalOptions = {
    id: string,
    ipo_id: any,
    products: ProductTransmital[],
};

type ReturnTransactions = {
    product_id: any,
    product_name: any,
    product_image: any,
    cost: any,
    sku: any,
    serial_data: SerialData[],
    quantity: any,
    ipo_number: any,
    has_serial: any,
    ipo_issuance_id: any,
    ipo_issuance_name: any,
    ipo_issuance_quantity: any,
    floating_quantity: any,
    is_used_first: any,
    product_available_stock: any,
    stock_location: any,
    initial_locations: any,
    initial_quantity: any,
    used_stocks: any,
};
type ReceiptData = {
    slip_number: any,
    reference_number: any,
    date_of_issuance: any,
    customer_code: any,
    customer_name: any,
    customer_address: any,
    customer_contact_person: any,
    transactions: ReturnTransactions[],
}

const MainComponent = (selectedTransaction: any) => {


    const [isSearching, setIsSearching] = useState(false);
    const [isEmptySearch, setIsEmptySearch] = useState(false);
    const debounceTimeout = useRef<ReturnType<typeof setTimeout> | null>(null);
    const [productOptions, setProductOptions] = useState<ProductOptions[]>([]);
    const [customerOptions, setCustomerOptions] = useState<CustomerOptions[]>([]);
    const [IPOOptions, setIPOOptions] = useState<TransmitalOptions[]>([]);

    const [issuanceIPORequest, setIssuanceIPORequest] = useState<ProductTransmital[]>([]);

    const [serialOptions, setSerialOptions] = useState<SerialData[]>([]);
    const [IPOList, setIPOList] = useState<ReturnTransactions[]>([]);
    const returnListRef = useRef(IPOList);

    const [sucess, setSucess] = useState('');
    const [error, setError] = useState('');

    const [isAutoPrint, setIsAutoPrint] = useState(true);
    const [slipID, setSlipID] = useState('');

    const [maxSerials, setMaxSerials] = useState(0);
    const [issuanceIPO, setIssuanceIPO] = useState<ProductTransmital | null>(null);


    const validationSchema = Yup.object().shape({
        product_id: Yup.string().required('Please provide product'),
        ipo_number: Yup.string().required('Please provide a IPO number'),
        customer_id: Yup.string().required('Please provide a Customer Code'),
        floating_quantity: Yup.number(),
        total_quantity: Yup.number(),
        product_quantity: Yup.number().test(
            'greaterthan-floating',
            'Quantity exceeds available stock',
            function (value) {
                const { total_quantity, floating_quantity, product_id } = this.parent;

                const trueValue = value ?? 0;
                const totalQuant = total_quantity ?? 0;
                const floatingQuant = floating_quantity ?? 0;

                if (!product_id) {
                    return this.createError({
                        message: 'Product ID is needed',
                    });
                }
                if (!issuanceIPO?.remaining_quantity) {

                    return this.createError({
                        message: 'Please select an IPO Item to be issued',
                    });
                }

                if ((value ?? 0) > (issuanceIPO?.remaining_quantity || 0)) {
                    console.log("Has error");
                    return this.createError({
                        message: `Quantity exceeds available (${issuanceIPO?.remaining_quantity || 0})`,
                    });
                }

                if (trueValue > (totalQuant - floatingQuant)) {

                    return false;
                }
                else {
                    setMaxSerials(value ?? 0);

                    return true;
                }


            }
        ),
        issuance_serials: Yup.string().test(
            'unique-check',
            'Serial number is already added',
            function (value) {
                const { serial_data, product_quantity } = this.parent;
                if (productHasSerial && product_quantity) {

                    if (inSystemValue == '1') {
                        if (maxSerials > 0 && serial_data.length == maxSerials) {
                            setError('Serial Numbers max limit reached');
                            return true;
                        }
                        else if (serial_data.length < maxSerials && !serial_data.some((item: any) => item.value === value)) {
                            return this.createError({ message: 'Please add more serial numbers' });
                        }
                        else if (serial_data.length > maxSerials) {
                            return this.createError({ message: 'Please make sure Quantity is equal to Serial Numbers' });
                        }
                        else {
                            console.log("Hello din");
                            return !serial_data.some((item: any) => item.value === value) && serial_data.length < maxSerials;
                        }
                    }
                    else {
                        if (serial_data.length < maxSerials && !serial_data.some((item: any) => item.value === value)) {
                            return this.createError({ message: 'Please add more serial numbers' });
                        }
                        else if (serial_data.length > maxSerials) {
                            return this.createError({ message: 'Please remove some serial numbers' });
                        }
                        else {
                            return true;
                        }
                    }

                }
                else {
                    return true;
                }

            }
        ),
    });
    const formik = useFormik({
        enableReinitialize: false,
        initialValues: {
            product_id: '',
            customer_id: '',
            customer_name: '',
            ipo_number: '',
            is_used_first: '',
            product_status: null,
            product_quantity: '',
            product_image: '',
            unit: '',
            product_price: '',
            product_name: '',
            issuance_serials: '',
            serial_data: [],
            return_price: 0,
            return_condition: '',
            return_location: '',
            location_divider: '',
            location_floor: '',
            transaction_number: '',
            sku: '',
            has_serial: 0,
            total_quantity: '',
            total_amount: 0,
            floating_quantity: '',
        },
        validateOnChange: false,
        validationSchema: validationSchema,
        onSubmit: async (values) => {

            if (formik.isValid) {
                console.log("FORM SUBMITED");
            }
            else {
                console.log("Form invalid");
            }
        },
    });
    const autocompleteSearchChange = (searchText: any, apiText: any) => {
        setIsSearching(true);
        setIsEmptySearch(false);
        if (debounceTimeout.current) {
            clearTimeout(debounceTimeout.current);
        }
        debounceTimeout.current = setTimeout(() => {
            const value = searchText.target.value;

            fetchOptions(value, apiText);
        }, 500);
    };

    const fetchOptions = async (searchText: string, apiText: string) => {
        try {
            const filter = [
                { "id": "name", "value": searchText },

                // { "id": "is_adjusting", "value": 1 }
            ];

            const { status, data } = await axiosClient.get(`/${apiText == 'serial' ? 'check_serial_number' : apiText}`, {
                params:
                    apiText == 'serial' ? { globalFilter: searchText, product_id: formik.values.product_id, per_page: 10 } :
                        apiText == 'customers' ?
                            {
                                globalFilter: searchText,
                                per_page: 10
                            } : apiText == 'ipo_transaction' ?
                                {
                                    filters: JSON.stringify(filter),
                                    is_search: true,
                                    per_page: 10
                                } : {
                                    filters: JSON.stringify(filter),
                                    per_page: 10
                                }
                ,
            });

            console.log(data.data);
            if (status == 200) {
                setIsSearching(false);
                if (data.data.length == 0) {
                    setIsEmptySearch(true);
                }


                if (apiText == 'serial') {
                    const fetchedData = data.data.map((innerData: any) => ({
                        id: innerData.id,
                        product_id: innerData.product_id,
                        floor: innerData.floor,
                        divider: innerData.divider,
                        location_id: innerData.location_id,
                        is_used: innerData.is_used,
                        serial_number_id: innerData.id,
                        price: innerData.product_entered_price,
                        product_price: innerData.product_selling_price,
                        value: innerData.serial_number,
                        inSystem: true,
                    }));
                    setSerialOptions(fetchedData);
                }
                else if (apiText == 'customers') {
                    const fetchedData = data.data.map((innerData: any) => ({
                        id: innerData.id,
                        name: `${innerData.code}(${innerData.name})`,
                        customer_code: innerData.code,
                        customer_address: innerData.address,
                        contact_address: innerData.contact_person,
                        pure_name: innerData.name,
                    }));
                    setCustomerOptions(fetchedData);
                }
                else if (apiText == 'ipo_transaction') {

                    const fetchedData = data.data.map((innerData: any) => {
                        return {
                            id: innerData.ipo_number,
                            ipo_id: innerData.id,
                            products: innerData.ipo_requests,
                        };
                    });

                    setIPOOptions(fetchedData);
                }
                else {
                    const fetchedData = data.data.map((innerData: any) => ({
                        id: innerData.id,
                        name: innerData.name,
                        description: innerData.description,
                        has_serial: innerData.has_serial,
                        image: innerData.image,
                        unit: innerData.unit,
                        sku: innerData.sku,
                        model: innerData.model,
                        brand: innerData.brand_name,
                        floating_quantity: innerData.floating_quantity,
                        qty_before: innerData.qty_before,
                        buying_price: innerData.buying_price,
                        selling_price: innerData.selling_price,
                        quantity: innerData.quantity
                    }));
                    setProductOptions(fetchedData);
                }


            }

        } catch (error) {
            console.error('Failed to fetch product options:', error);
        }
    };

    const handlePriceCheckbox = (event: any) => {
        setIsAutoPrint(event.target.checked);

    }
    const [stocksLocation, setStocksLocation] = useState([]);
    const [usedStocks, setUsedStocks] = useState([]);

    const [receiptData, setReceiptData] = useState<ReceiptData[]>([]);

    const getProductLocations = async (productId: any) => {
        const response = await axiosClient.get(`/stocks_location/product/${productId}`);

        setStocksLocation(response.data.stock_location);
        setUsedStocks(response.data.used_stocks);

    };



    const addIssuanceIPOClick = async () => {
        const errors = await formik.validateForm();
        setError('');
        formik.setTouched({ ['ipo_number']: true, ['product_id']: true, ['product_quantity']: true, ['issuance_serials']: true });

        if (Object.keys(errors).length === 0) {
            const formikVals = formik.values;

            const detectUnique = IPOList.some((item: any) => item.product_id == formik.values.product_id);
            const existingProductIndex = IPOList.findIndex((item) => item.product_id === formik.values.product_id);

            const selectedProduct = productOptions.find(p => p.id === formikVals.product_id);


            const initialUsed: any = [];
            const initialLocation: any = [];

            if (existingProductIndex !== -1) {


                const existingProduct = IPOList[existingProductIndex];
                const availableStock = formik.values.total_quantity;
                const floatingStock = formik.values.floating_quantity;

                // if (selectedTransaction) {
                //     const filteredStocks = selectedTransaction?.transactions.stocks.filter((stat: any) => stat.product_id == selectedProductRef.current.id);
                //     const totalQuantity = filteredStocks?.reduce((sum: number, stock: any) => sum + stock.initial_quantity, 0) || 0;

                //     floatingStock = floatingStock > totalQuantity ? floatingStock - totalQuantity : totalQuantity - floatingStock;

                // }

                setError("Duplicate Product is selected. Please select different product");


            }
            else {

                // const JsonToSet = {
                //   product_id: formikVals.product_id,
                //   product_name: formikVals.product_name,
                //   product_image: formikVals.product_image,
                //   sku: formikVals.sku,
                //   has_serial: formikVals.has_serial,
                //   serial_data: formikVals.serial_data,
                //   quantity: formikVals.product_quantity,
                //   ipo_number: formikVals.ipo_number,
                //   return_product_status: formikVals.product_status,
                //   return_product_value: formikVals.return_price,
                //   ipo_issuance_id: issuanceIPO?.id,
                //   return_product_model: issuanceIPO?.model,
                //   ipo_issuance_name: issuanceIPO?.name,
                //   ipo_issuance_quantity: issuanceIPO?.quantity,
                // };


                const JsonToSetTable = {
                    product_id: formikVals.product_id,
                    product_name: formikVals.product_name,
                    product_image: formikVals.product_image,
                    floating_quantity: formikVals.floating_quantity,
                    sku: formikVals.sku,
                    has_serial: formikVals.has_serial,
                    serial_data: formikVals.serial_data,
                    quantity: formikVals.product_quantity,
                    ipo_number: formikVals.ipo_number,
                    ipo_issuance_id: issuanceIPO?.id,
                    ipo_issuance_name: issuanceIPO?.product_name,
                    ipo_issuance_quantity: issuanceIPO?.quantity,
                    is_used_first: isUsedFirst,
                    cost: formikVals.product_price,
                    product_available_stock: selectedProduct?.quantity,
                    stock_location: stocksLocation,
                    initial_locations: initialLocation ? initialLocation.concat(stocksLocation) : stocksLocation,
                    initial_quantity: selectedProduct?.quantity,
                    used_stocks: initialUsed ? initialUsed.concat(usedStocks) : usedStocks,
                }

                const retList = IPOList.filter((item: any) => item.ipo_issuance_id == issuanceIPO?.id).map((list) => {
                    return list;
                })

                const totalQuantity = IPOList
                    .filter((item: any) => item.ipo_issuance_id == issuanceIPO?.id)
                    .reduce((acc, list) => acc + list.quantity, 0);

                if ((totalQuantity + formikVals.product_quantity) > issuanceIPO?.quantity) {
                    setError(`You will exceed the maximum quantity you can return on product ${issuanceIPO?.product_name}`);
                    return;
                }


                retList.push(JsonToSetTable);

                const set_this = issuanceIPORequest.map((item: any) =>
                    item.id === issuanceIPO?.id ? { ...item, transaction: retList } : item
                );

                const customerOpt = customerOptions.find(p => p.id === formik.values.customer_id);

                const invoiceData: any = {
                    date_of_issuance: dayjs().toISOString,
                    customer_code: customerOpt?.customer_code,
                    customer_name: customerOpt?.customer_code,
                    customer_address: customerOpt?.customer_address,
                    customer_contact_person: customerOpt?.contact_address,
                    transactions: [...IPOList, JsonToSetTable],
                };


                console.log(invoiceData);
                setReceiptData(invoiceData);

                setIssuanceIPORequest(set_this);

                setIPOList([...IPOList, JsonToSetTable]);


                formik.resetForm({
                    values: {
                        ...formikVals,
                        product_id: '',
                        product_quantity: '',
                        serial_data: [],
                        unit: '',
                        total_quantity: '',
                        floating_quantity: '',
                        product_price: '',
                        total_amount: 0,
                    },
                });

                setserial_data([]);
                setMaxSerials(0);
                setIssuanceIPO(null);
                setError('');


                // setIPOList([...IPOList, JsonToSet]);


            }

        }
        console.log(errors);
    };

    useEffect(() => {

        returnListRef.current = IPOList;

    }, [IPOList])

    const [isCheckedFinalize, setIsCheckedFinalize] = useState(false);
    function onChangeFinalize(e: any) {
        const checked = e.target.checked;
        setIsCheckedFinalize(checked);
    }

    const postTransaction = () => {


        if (IPOList.length > 0) {
            let totalAmount = 0;


            // ipo_number: formikVals.ipo_number,
            // ipo_issuance_id: issuanceIPO?.id,
            // ipo_issuance_name: issuanceIPO?.product_name,
            // ipo_issuance_quantity: issuanceIPO?.quantity,

            const transactionData = {
                is_finalized: isCheckedFinalize,
                slip_number: formik.values.ipo_number,
                date_of_issuance: dayjs().format('YYYY-MM-DD'),
                customer_id: formik.values.customer_id,
                customer_name: formik.values.customer_name,
                customer_address: '',
                ipo_id: slipID,
                products: IPOList.map((ipoList) => {
                    const stockLoc = ipoList.stock_location
                        ? ipoList.stock_location.filter((result: any) => result.quantity != 0)
                        : [];
                    let remainingQuantity = ipoList.quantity;
                    const result = [];
                    const getUsedStockResult = [];


                    const uniqueMap: any = new Map();



                    if (ipoList.serial_data.length > 0) {
                        ipoList.serial_data.forEach((item: any) => {
                            // Generate a key based on the unique properties
                            const key = `${item.product_id}-${item.floor}-${item.divider}-${item.price}-${item.location_id}-${item.is_used}`;

                            if (uniqueMap.has(key)) {
                                const existingItem = uniqueMap.get(key);
                                existingItem.quantity++;
                                existingItem.serial_numbers.push(item);
                            } else {
                                uniqueMap.set(key, { ...item, quantity: 1, serial_numbers: [item] });
                            }
                        });
                        const uniqueItems: any = Array.from(uniqueMap.values());

                        for (const item of uniqueItems) {

                            const location = stockLoc.find((loc: any) =>
                                loc.location_id === item.location_id && loc.product_id === item.product_id
                            );
                            console.log(location);

                            totalAmount = totalAmount + (item.price * item.quantity);

                            result.push({
                                id: item.id,
                                floor: item.floor,
                                divider: item.divider,
                                product_id: item.product_id,
                                location_id: item.location_id,
                                takenNew: item.is_used == 0 ? item.quantity : 0,
                                takenQuantity: item.quantity,
                                takenUsed: item.is_used == 1 ? item.quantity : 0,
                                used_quantity: item.is_used == 1 ? item.quantity : 0,
                                value: item.price,
                                quantity: item.quantity,
                                location: location.location,
                                serial_numbers: item.serial_numbers,
                            });

                        }
                    }

                    else {
                        if (ipoList.is_used_first && ipoList.serial_data.length == 0) {

                            for (const itemDetail of ipoList.used_stocks) {
                                if (remainingQuantity <= 0) break;

                                let takenUsed = 0;
                                const serialsTaken: any = [];

                                if (itemDetail.quantity > 0) {

                                    const locationDetails = stockLoc.find((loc: any) =>
                                        loc.location_id === itemDetail.location_id && loc.product_id === itemDetail.product_id
                                    );


                                    if (itemDetail.quantity >= remainingQuantity) {
                                        takenUsed = remainingQuantity;
                                        result.push({
                                            ...itemDetail,
                                            location: locationDetails.location,
                                            takenUsed,
                                            takenNew: 0,
                                            takenQuantity: takenUsed,
                                            serial_numbers: serialsTaken,
                                        });

                                        getUsedStockResult.push({
                                            ...itemDetail,
                                            quantity: takenUsed,
                                        });

                                        remainingQuantity = 0;
                                    } else {
                                        takenUsed = itemDetail.quantity;
                                        result.push({
                                            ...itemDetail,
                                            location: locationDetails.location,
                                            takenUsed,
                                            takenNew: 0,
                                            takenQuantity: takenUsed,
                                            serial_numbers: serialsTaken,
                                        });

                                        getUsedStockResult.push({
                                            ...itemDetail,
                                            quantity: takenUsed,
                                        });

                                        remainingQuantity -= takenUsed;
                                    }
                                }
                            }

                            if (remainingQuantity > 0) {
                                for (const item of stockLoc) {
                                    if (remainingQuantity <= 0) break;

                                    let takenNew = 0;
                                    const serialsTaken: any = [];

                                    if (item.quantity > 0) {
                                        if (item.quantity >= remainingQuantity) {
                                            takenNew = remainingQuantity;
                                            result.push({
                                                ...item,
                                                takenUsed: 0,
                                                takenNew,
                                                takenQuantity: takenNew,
                                                serial_numbers: serialsTaken,
                                            });
                                            remainingQuantity = 0;
                                        } else {
                                            takenNew = item.quantity;
                                            result.push({
                                                ...item,
                                                takenUsed: 0,
                                                takenNew,
                                                takenQuantity: takenNew,
                                                serial_numbers: serialsTaken,
                                            });
                                            remainingQuantity -= takenNew;
                                        }
                                    }
                                }
                            }
                        }
                        else {

                            for (const item of stockLoc) {
                                if (remainingQuantity <= 0) break;

                                let takenNew = 0;
                                const serialsTaken: any = [];

                                if (item.quantity > 0) {
                                    if (item.quantity >= remainingQuantity) {
                                        takenNew = remainingQuantity;
                                        result.push({
                                            ...item,
                                            takenUsed: 0,
                                            takenNew,
                                            takenQuantity: takenNew,
                                            serial_numbers: serialsTaken,
                                        });
                                        remainingQuantity = 0;
                                    } else {
                                        takenNew = item.quantity;
                                        result.push({
                                            ...item,
                                            takenUsed: 0,
                                            takenNew,
                                            takenQuantity: takenNew,
                                            serial_numbers: serialsTaken,
                                        });
                                        remainingQuantity -= takenNew;
                                    }
                                }
                            }

                            if (remainingQuantity > 0) {
                                for (const itemDetail of ipoList.used_stocks) {
                                    if (remainingQuantity <= 0) break;

                                    let takenUsed = 0;
                                    const serialsTaken: any = [];

                                    if (itemDetail.quantity > 0) {

                                        const locationDetails = stockLoc.find((loc: any) =>
                                            loc.location_id === itemDetail.location_id && loc.product_id === itemDetail.product_id
                                        );


                                        if (itemDetail.quantity >= remainingQuantity) {
                                            takenUsed = remainingQuantity;
                                            result.push({
                                                ...itemDetail,
                                                location: locationDetails.location,
                                                takenUsed,
                                                takenNew: 0,
                                                takenQuantity: takenUsed,
                                                serial_numbers: serialsTaken,
                                            });

                                            getUsedStockResult.push({
                                                ...itemDetail,
                                                quantity: takenUsed,
                                            });
                                            remainingQuantity = 0;
                                        } else {
                                            takenUsed = itemDetail.quantity;
                                            result.push({
                                                ...itemDetail,
                                                location: locationDetails.location,
                                                takenUsed,
                                                takenNew: 0,
                                                takenQuantity: takenUsed,
                                                serial_numbers: serialsTaken,
                                            });
                                            getUsedStockResult.push({
                                                ...itemDetail,
                                                quantity: takenUsed,
                                            });
                                            remainingQuantity -= takenUsed;
                                        }
                                    }
                                }
                            }
                        }
                        console.log(getUsedStockResult);
                    }




                    return {
                        id: ipoList.product_id,
                        name: ipoList.product_name,
                        quantity: ipoList.quantity,
                        unit_price: ipoList.cost,
                        amount: totalAmount,
                        floating_quantity: ipoList.floating_quantity,
                        taken_results: result,
                        used_stocks: getUsedStockResult,
                        is_used_first: ipoList.is_used_first,
                        ipo_issuance_request_id: ipoList.ipo_issuance_id,
                        serial_numbers: ipoList.serial_data.map((serial) => ({
                            id: serial.id,
                            is_used: serial.is_used,
                            value: serial.value,
                            price: serial.price,
                        })),

                    };
                }),
                total_amount: totalAmount,
            };


            // console.log(transactionData);

            // return;

            axiosClient.post('/ie_transactions', transactionData)
                .then((response) => {
                    console.log(response);
                })
                .catch((error) => {
                    console.error('Failed to post transaction:', error);
                });

        } else {
            setError('Please check fields and adjustment list to proceed');
        }
    }
    const handleRemoveClick = React.useCallback((passedAdj: any) => {
        removeAdjustment(passedAdj.product_id)
    }, []);

    const removeAdjustment = (id: any) => {

        const updatedArray = returnListRef.current.filter((item: any) => item.product_id !== id);
        setIPOList(updatedArray);
    }

    const [fromDate, setFromDate] = useState(dayjs());
    const handleFromDatechange = (newDate: any) => {
        setFromDate(newDate);
    };

    const [inSystemValue, setInSystemValue] = React.useState('0');

    const [productHasSerial, setProductHasSerial] = useState(false);

    const [serial_data, setserial_data] = useState<SerialData[]>([]);

    const handleDelete = (chipToDelete: any) => () => {

        console.log(chipToDelete);
        const updatedChips = serial_data.filter((chip) => chip !== chipToDelete);
        setError('');
        setserial_data(updatedChips);
        formik.setFieldValue('serial_data', updatedChips);
    };


    const addReturnClick = (returnDetails: any) => {

        console.log(returnDetails);
        if (returnDetails) {
            setIssuanceIPO(returnDetails);
        }

    }

    const [activeStep, setActiveStep] = useState<number>(0);

    const handleStepClick = (index: number) => () => {
        setActiveStep(index);
    };
    const steps = ['Transaction Detail', 'Product Details'];


    const [isUsedFirst, setIsUsedFirst] = useState(true);

    const handleIsUsedFirst = (event: any) => {
        setIsUsedFirst(event.target.checked);
        if (event.target.checked) {
            formik.setFieldValue('is_used_first', 1);
        }
        else {
            formik.setFieldValue('is_used_first', 0);
        }
    }

    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => {

        console.log(IPOList);

        if (IPOList.length > 0) {
            setOpen(true);
        }
        else {
            setError('Please check fields and quotation list to proceed');
        }
    };
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Box>


            <FormikProvider value={formik}>

                <form onSubmit={formik.handleSubmit}>
                    <Grid container padding={3} columnSpacing={2}>

                        <Grid item sm={12} lg={4}>
                            {sucess &&
                                <Alert
                                    variant="filled"
                                    severity="success"
                                    sx={{ alignItems: "center" }}
                                >
                                    {sucess}
                                </Alert>
                            }

                            {error &&
                                <Alert
                                    variant="filled"
                                    severity="error"
                                    sx={{ alignItems: "center" }}
                                >
                                    {error}
                                </Alert>
                            }

                            <Box sx={{ width: '100%' }}>
                                <Stepper activeStep={activeStep} alternativeLabel>
                                    {steps.map((label, index) => (
                                        <Step
                                            key={label}
                                            onClick={handleStepClick(index)}
                                            sx={{ cursor: 'pointer' }}
                                        >
                                            <StepLabel>{label}</StepLabel>
                                        </Step>
                                    ))}
                                </Stepper>

                            </Box>

                            <Grid container spacing={2} style={{ display: activeStep === 1 ? 'none' : 'flex' }}>

                                <Grid item xs={12}>
                                    <FormLabel component="legend" sx={{ mt: 3 }}>
                                        <Typography variant={'h4'}>Ipo Details</Typography>
                                    </FormLabel>
                                </Grid>

                                <Grid item xs={6}>
                                    <CustomTextField
                                        id="issuance_number"
                                        size="small"
                                        disabled
                                        variant="standard"
                                        label="Issuance Number"
                                        defaultValue="Auto Generated"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DateTimePicker
                                            value={fromDate}
                                            disabled
                                            onChange={handleFromDatechange}
                                            slotProps={{ textField: { size: 'small', variant: "standard" } }}
                                            sx={{ width: '100%' }}
                                            label="Issuance Date"
                                            views={['year', 'month', 'day', 'hours', 'minutes']}
                                        />
                                    </LocalizationProvider>
                                </Grid>

                                <Grid item xs={6}>
                                    <Autocomplete
                                        id="ipo_number"
                                        noOptionsText={isSearching ? 'Searching...' : (isEmptySearch ? `Can't find search word` : 'Type to search')}
                                        options={IPOOptions}
                                        value={IPOOptions.find(option => option.id === formik.values.ipo_number) || null}
                                        getOptionLabel={(option) => {
                                            // Display "TET-000001" in the dropdown options
                                            return `${option.id.toString().padStart(5, '0')}`;
                                        }}

                                        isOptionEqualToValue={(option, value) => option.id === value.id}
                                        onChange={(_, newValue) => {
                                            // const selectedProduct = productOptions.find(p => p.id === newValue);
                                            if (newValue) {
                                                setIssuanceIPORequest(newValue.products);
                                                setSlipID(newValue.ipo_id);
                                                formik.setFieldValue('ipo_number', newValue.id);
                                            } else {
                                                formik.setFieldValue('ipo_number', null);
                                            }

                                            if (newValue === null) {
                                                formik.resetForm();
                                                setIPOOptions([]);
                                            }
                                        }}

                                        onBlur={() => formik.setFieldTouched('ipo_number', true)}

                                        fullWidth

                                        renderInput={(params) => (
                                            <CustomTextField
                                                {...params}
                                                InputProps={{
                                                    ...params.InputProps,
                                                    startAdornment: <InputAdornment position="start">IPO - </InputAdornment>,
                                                }}
                                                error={formik.touched.ipo_number && Boolean(formik.errors.ipo_number)}
                                                helperText={formik.touched.ipo_number && formik.errors.ipo_number}
                                                onChange={(e: any) => autocompleteSearchChange(e, 'ipo_transaction')}
                                                variant="standard"
                                                size="small"
                                                label="IPO Number"
                                                placeholder="IPO Number"
                                                aria-label="IPO Number"
                                            />
                                        )}
                                    />
                                </Grid>

                                <Grid item xs={6}>
                                    <CustomTextField
                                        id="slip_number"
                                        size="small"
                                        disabled
                                        variant="standard"
                                        label="Slip Number"
                                        defaultValue="Username here"
                                        value={formik.values.ipo_number}
                                        fullWidth
                                    />
                                </Grid>

                                <Grid item xs={6}>
                                    <Autocomplete
                                        id="customer_id"
                                        noOptionsText={isSearching ? 'Searching...' : (isEmptySearch ? `Can't find search word` : 'Type to search')}
                                        value={formik.values.customer_id || null}
                                        onChange={async (_, newValue) => {
                                            formik.setFieldValue('customer_id', newValue);
                                            const customerOpt = customerOptions.find(p => p.id === newValue);

                                            console.log(customerOpt);

                                            if (customerOpt) {
                                                formik.setFieldValue('customer_name', customerOpt.pure_name);
                                            }
                                            if (newValue === null) {
                                                setCustomerOptions([]);
                                            }
                                        }}
                                        options={customerOptions.map(p => p.id)}
                                        fullWidth
                                        getOptionLabel={(option) => {
                                            const customerOpt = customerOptions.find(p => p.id === option);

                                            return customerOpt ? `${customerOpt.name}` : '';
                                        }}
                                        isOptionEqualToValue={(option, value) => option === value}
                                        renderInput={(params) => (
                                            <CustomTextField
                                                {...params}
                                                error={formik.touched.customer_id && Boolean(formik.errors.customer_id)}
                                                helperText={formik.touched.customer_id && formik.errors.customer_id}
                                                onChange={(e: any) => autocompleteSearchChange(e, 'customers')}
                                                variant="standard"
                                                label="Customer Code"
                                                size="small"
                                                placeholder="Customer Code"
                                                aria-label="Customer Code"
                                            />
                                        )}
                                    />
                                </Grid>

                                <Grid item xs={6}>
                                    <CustomTextField
                                        id="customer_name"
                                        size="small"
                                        value={formik.values.customer_name}
                                        disabled
                                        variant="standard"
                                        label="Customer Name"
                                        defaultValue="No Customer Selected"
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>



                            <Grid container spacing={2} style={{ display: activeStep === 0 ? 'none' : 'flex' }}>
                                <Grid item xs={12}>
                                    <FormLabel component="legend" sx={{ mt: 3 }}>
                                        <Grid container direction={'row'} columnSpacing={1}>
                                            <Grid item>
                                                <Typography variant={'h4'}>Issuance Of Item</Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography variant={'h4'}>-</Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography variant={'h4'}>{issuanceIPO?.product_name ?? 'Item Name'}</Typography>
                                            </Grid>
                                        </Grid>
                                    </FormLabel>
                                </Grid>

                                <Grid item xs={10}>
                                    <Autocomplete
                                        id="product_id"
                                        disabled={!slipID}
                                        noOptionsText={isSearching ? 'Searching...' : (isEmptySearch ? `Can't find search word` : 'Type to search')}
                                        value={formik.values.product_id || null}
                                        onChange={async (_, newValue) => {
                                            formik.setFieldValue('product_id', newValue);
                                            formik.setFieldValue('serial_data', []);
                                            const selectedProduct = productOptions.find(p => p.id === newValue);
                                            if (selectedProduct) {

                                                console.log(selectedProduct);

                                                if (selectedProduct.has_serial) {
                                                    setProductHasSerial(true);
                                                }
                                                else {
                                                    setserial_data([]);
                                                    await formik.setFieldValue('serial_data', []);
                                                    formik.setFieldTouched('serial_data', true);
                                                    setProductHasSerial(false);
                                                }
                                                formik.setFieldValue('sku', selectedProduct.sku);
                                                formik.setFieldValue('unit', selectedProduct.unit);
                                                formik.setFieldValue('has_serial', selectedProduct.has_serial);
                                                formik.setFieldValue('product_image', selectedProduct.image);
                                                formik.setFieldValue('total_quantity', selectedProduct.quantity);
                                                formik.setFieldValue('product_price', selectedProduct.selling_price);
                                                formik.setFieldValue('floating_quantity', selectedProduct.floating_quantity);
                                                formik.setFieldValue('product_name', selectedProduct.name);

                                                getProductLocations(selectedProduct.id);

                                            }
                                            if (newValue === null) {
                                                setProductHasSerial(false);
                                                setProductOptions([]);
                                            }
                                        }}
                                        options={productOptions.map(p => p.id)}
                                        fullWidth
                                        getOptionLabel={(option) => {
                                            const productOpt = productOptions.find(p => p.id === option);
                                            return productOpt ? `${productOpt.name} [Model: ${productOpt.model}] [SKU: ${productOpt.sku}] [Brand: ${productOpt.brand}]` : '';
                                        }}
                                        isOptionEqualToValue={(option, value) => option === value}
                                        renderInput={(params) => (
                                            <CustomTextField
                                                {...params}
                                                error={formik.touched.product_id && Boolean(formik.errors.product_id)}
                                                helperText={formik.touched.product_id && formik.errors.product_id}
                                                onChange={(e: any) => autocompleteSearchChange(e, 'products')}
                                                variant="standard"
                                                size="small"
                                                label="Product name"
                                                placeholder="Product name"
                                                aria-label="Product name"
                                            />
                                        )}
                                    />
                                </Grid>

                                <Grid item xs={2}>
                                    <CustomTextField
                                        id="product_unit"
                                        size="small"
                                        variant="standard"
                                        label="Unit Type"
                                        value={formik.values.unit}
                                        disabled
                                        fullWidth
                                    />

                                </Grid>

                                <Grid item xs={12}>

                                    <Grid container spacing={2}>
                                        <Grid item xs={3}>
                                            <Field
                                                validateOnBlur
                                                validateOnChange
                                                name="product_quantity"
                                                render={({ field, form }: FieldProps) => (
                                                    <CustomTextField
                                                        {...field}
                                                        id="product_quantity"
                                                        size="small"
                                                        variant="standard"
                                                        type="number"
                                                        label="Quantity"
                                                        disabled={!slipID}
                                                        value={form.values.product_quantity}
                                                        onChange={(e: any) => {
                                                            const reqBuying = parseFloat(e.target.value || 0);
                                                            const current_selling = parseFloat(String(formik.values.product_price || 0));
                                                            formik.setFieldValue('total_amount', reqBuying * current_selling);
                                                            form.handleChange(e);
                                                        }}
                                                        onBlur={form.handleBlur}
                                                        error={form.touched.product_quantity && Boolean(form.errors.product_quantity)}
                                                        helperText={form.touched.product_quantity && form.errors.product_quantity}
                                                        fullWidth
                                                    />
                                                )}
                                            />
                                        </Grid>

                                        <Grid item xs={3}>

                                            <CustomTextField
                                                id="total_stock"
                                                size="small"
                                                variant="standard"
                                                type="number"
                                                label="Total Stock"
                                                value={formik.values.total_quantity}
                                                disabled
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <CustomTextField
                                                id="floating_quantity"
                                                size="small"
                                                variant="standard"
                                                type="number"
                                                label="Floating Stock"
                                                value={formik.values.floating_quantity}
                                                disabled
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <CustomTextField
                                                id="product_price"
                                                size="small"
                                                variant="standard"
                                                value={formatCurrency(formik.values.product_price)}
                                                label="Unit Price"
                                                disabled
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <CustomTextField
                                                id="total_amount"
                                                size="small"
                                                variant="standard"
                                                label="Total Amount"
                                                value={formatCurrency(formik.values.total_amount)}
                                                disabled
                                                fullWidth
                                            />
                                        </Grid>
                                        {inSystemValue == '0' &&
                                            <Grid item xs={9}>
                                                <Autocomplete
                                                    multiple
                                                    disabled={!productHasSerial}
                                                    id='issuance_serials'
                                                    value={serial_data}
                                                    noOptionsText={isSearching ? 'Searching...' : (isEmptySearch ? `Can't find search word` : 'Type to search')}
                                                    size='small'
                                                    fullWidth
                                                    options={serialOptions}
                                                    getOptionLabel={(option) => option.value} // Display label in the dropdown
                                                    getOptionDisabled={(options) => {

                                                        const IsTrue = serial_data.length >= parseInt(formik.values.product_quantity);
                                                        return IsTrue;
                                                    }}
                                                    onChange={async (_, newValue, reason, details) => {
                                                        setError('');
                                                        if (details) {
                                                            await formik.setFieldValue('serial_data', newValue);
                                                            setserial_data(newValue);
                                                        }

                                                        if (!details) {
                                                            setserial_data([]);
                                                            await formik.setFieldValue('serial_data', []);
                                                            setSerialOptions([]);
                                                        }
                                                        formik.setFieldTouched('issuance_serials', true);
                                                    }}
                                                    renderInput={(params) => {

                                                        return (
                                                            <CustomTextField
                                                                {...params}
                                                                variant='standard'
                                                                error={formik.touched.issuance_serials && Boolean(formik.errors.issuance_serials)}
                                                                helperText={formik.touched.issuance_serials && formik.errors.issuance_serials}
                                                                onChange={(e: any) => {
                                                                    autocompleteSearchChange(e, 'serial')

                                                                }}
                                                                size='small'
                                                                label="Serial Numbers"
                                                                placeholder="Favorites"
                                                            />
                                                        )
                                                    }}
                                                />
                                            </Grid>
                                        }
                                    </Grid>
                                </Grid>



                                {productHasSerial &&
                                    <>
                                        <Grid item xs={12}>
                                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                                                {serial_data.map((chip, index) => (
                                                    <Chip
                                                        key={index}
                                                        label={chip.value}
                                                        onDelete={
                                                            inSystemValue == '1' // Replace with your actual condition
                                                                ? handleDelete(chip) // or directly use handleDelete(chip) if it doesn't require event handling
                                                                : undefined
                                                        }
                                                    />
                                                ))}
                                            </Box>
                                            <Typography variant="caption" color="textSecondary" sx={{ marginTop: 1 }}>
                                                {`Serial Numbers You have added ${serial_data.length}/${maxSerials} items.`}
                                            </Typography>
                                        </Grid>
                                    </>
                                }


                                <Grid item xs={12}>
                                    <Grid container gap={2}>
                                        <Button variant="contained" color="primary" onClick={addIssuanceIPOClick}>
                                            Add
                                        </Button>
                                        <Button variant="contained" color="primary" onClick={() => {
                                            formik.resetForm();
                                            setSlipID('');
                                            setProductHasSerial(false);
                                            setIssuanceIPO(null);
                                            setIssuanceIPORequest([]);

                                        }}>
                                            Reset
                                        </Button>
                                    </Grid>
                                </Grid>


                            </Grid>

                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={
                                        <CustomCheckbox
                                            onChange={handleIsUsedFirst}
                                            checked={isUsedFirst}
                                            color="primary"
                                            inputProps={{ 'aria-label': 'checkbox with default color' }}
                                        />
                                    }
                                    label="Is Used First"
                                />
                                <FormControlLabel
                                    control={
                                        <CustomCheckbox
                                            onChange={onChangeFinalize}
                                            checked={isCheckedFinalize}
                                            style={{ fontSize: '16px', fontWeight: 'bold' }}
                                            color="primary"
                                            inputProps={{ 'aria-label': 'checkbox with default color' }}
                                        />
                                    }
                                    label="Is Finalized"
                                />

                            </Grid>
                        </Grid>

                        <Grid item sm={12} lg={8} mt={{ xs: 2, md: 0 }}>


                            <Grid container rowSpacing={2}>
                                <Grid item xs={12}>
                                    <Stack direction="row" spacing={2}>
                                        <Button variant="contained" color="primary" onClick={handleClickOpen}>
                                            POST
                                        </Button>
                                        <Button variant="text" color="error">
                                            CLEAR
                                        </Button>
                                    </Stack>
                                </Grid>
                                <Grid item xs={12}>
                                    <BlankCard>
                                        <Table className="printTable" sx={{ width: '100%', textAlign: 'left' }}>
                                            <TableHead sx={{ backgroundColor: 'grey.200' }}>
                                                <TableRow>
                                                    <TableCell sx={{ py: 1, color: 'text.secondary' }}></TableCell>
                                                    <TableCell sx={{ py: 1, color: 'text.secondary' }}>Product Name</TableCell>
                                                    <TableCell sx={{ py: 1, color: 'text.secondary' }}></TableCell>
                                                    <TableCell sx={{ py: 1, color: 'text.secondary' }}></TableCell>
                                                    <TableCell sx={{ py: 1, color: 'text.secondary' }}>Quantity</TableCell>
                                                    <TableCell sx={{ py: 1, color: 'text.secondary' }}>Action</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {issuanceIPORequest.length > 0 && issuanceIPORequest.map((transaction: any, index: any) => {

                                                    return (
                                                        <RowComponent returnDetails={transaction} onAddReturnClick={addReturnClick} onRemoveClick={handleRemoveClick} key={index} />
                                                    );
                                                })}


                                            </TableBody>
                                        </Table>
                                    </BlankCard>
                                </Grid>
                            </Grid>
                        </Grid>

                    </Grid>
                </form>
            </FormikProvider>

            <Dialog
                open={open}
                onClose={handleClose}
                fullWidth
                maxWidth={'md'}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Quotation Details"}
                </DialogTitle>
                <DialogContent>
                    <ModalReceipt data={receiptData} copy={''} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={postTransaction} autoFocus>
                        Post
                    </Button>
                </DialogActions>
            </Dialog>


        </Box >
    );
};

interface GridItemProps {
    data: any;
    copy: any;
}
const ModalReceipt: FC<GridItemProps> = ({ data, copy }) => {
    const { formatWithPrefix } = useFormattedNumber();

    const customizer = useSelector((state: AppState) => state.customizer);
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 1,
                padding: '10px',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'flex-start',
                    mb: 1,
                }}
            >
                <Box>
                    {customizer.activeMode === 'dark' ? (
                        <LogoLight height={'auto'} />
                    ) : (
                        <LogoDark height={'auto'} />
                    )}
                    {/* <Box sx={{ width: '250px', marginLeft: '-36px', marginBottom: '5px' }}>
              <RECEIPT
                style={{
                  fill: theme.palette.mode === 'dark' ? '#ffffff' : '#000000', // Change fill color based on theme mode
                }}
                className="printable-logo"
              />
            </Box> */}
                    <Typography
                        component="p"
                        sx={{ color: 'text.primary', fontWeight: 'bold' }}
                        className="fontSize8pt"
                    >
                        RAEL KITZ CORPORATION - WMS IS
                    </Typography>
                    <Typography variant="body2" sx={{ color: 'text.secondary' }} className="fontSize8pt">
                        RRF Building San Miguel Calasiao, Pangasinan
                        <br />
                        +1 (123) 456 7891, +44 (876) 543 2198
                    </Typography>
                    <Typography
                        variant="body2"
                        sx={{ mt: 1, fontWeight: 'bold', textTransform: 'uppercase' }}
                        className="fontSize8pt"
                    >
                        {copy}
                    </Typography>
                </Box>
                <Box sx={{ textAlign: 'right' }}>
                    <Typography
                        variant="h6"
                        component="p"
                        sx={{ color: 'text.primary', fontWeight: 'bold' }}
                        className="fontSize8pt"
                    >
                        INVOICE #{data?.slip_number}
                    </Typography>
                    <Typography variant="body2" sx={{ color: 'text.secondary' }} className="fontSize8pt">
                        Reference Number: {data?.reference_number} <br />
                        Issuance Date: {data?.date_of_issuance}
                    </Typography>
                    <Typography
                        variant="body1"
                        sx={{ color: 'text.primary', fontWeight: 'bold' }}
                        className="fontSize8pt"
                    >
                        Customer: {data?.customer_name}
                    </Typography>
                    <Typography variant="body2" sx={{ color: 'text.secondary' }} className="fontSize8pt">
                        Customer Code: {data?.customer_code}
                        <br />
                        Address: {data?.customer_address}
                        <br />
                        Contact Person: {data?.customer_contact_person}
                        <br />
                    </Typography>
                </Box>
            </Box>
            <Table className="printTable" sx={{ width: '100%', textAlign: 'left' }}>
                <TableHead sx={{ backgroundColor: 'grey.200' }}>
                    <TableRow>
                        <TableCell sx={{ py: 1, color: 'text.secondary' }}>PRODUCT NAME</TableCell>
                        <TableCell sx={{ py: 1, color: 'text.secondary' }}>Location</TableCell>
                        <TableCell sx={{ py: 1, color: 'text.secondary' }}>UNIT</TableCell>
                        <TableCell sx={{ py: 1, color: 'text.secondary' }}>COST</TableCell>
                        {/* <TableCell sx={{ py: 1, color: 'text.secondary' }}>QTY</TableCell> */}
                        <TableCell sx={{ py: 1, color: 'text.secondary' }}>AMOUNT</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>

                    {data?.transactions?.map((transaction: any, index: any) => {
                        const stockLoc = transaction.stock_location
                            ? transaction.stock_location.filter((result: any) => result.quantity != 0)
                            : [];


                        const serialNumbers = transaction.serial_data.filter(
                            (opt: any) => opt.product_id === transaction.product_id,
                        );

                        let serialIndex = 0;
                        let remainingQuantity = transaction.quantity;
                        const result = [];


                        const uniqueMap: any = new Map();

                        if (transaction.serial_data.length > 0) {
                            transaction.serial_data.forEach((item: any) => {
                                // Generate a key based on the unique properties
                                const key = `${item.product_id}-${item.floor}-${item.divider}-${item.price}-${item.location_id}-${item.is_used}`;

                                if (uniqueMap.has(key)) {
                                    const existingItem = uniqueMap.get(key);
                                    existingItem.quantity++;
                                    existingItem.serial_numbers.push(item);
                                } else {

                                    uniqueMap.set(key, { ...item, quantity: 1, serial_numbers: [item] });
                                }
                            });
                            const uniqueItems: any = Array.from(uniqueMap.values());

                            for (const item of uniqueItems) {

                                const location = stockLoc.find((loc: any) =>
                                    loc.location_id === item.location_id && loc.product_id === item.product_id
                                );

                                console.log(location);

                                result.push({
                                    id: item.id,
                                    floor: item.floor,
                                    divider: item.divider,
                                    product_id: item.product_id,
                                    location_id: item.location_id,
                                    takenNew: item.is_used == 0 ? item.quantity : 0,
                                    takenQuantity: item.quantity,
                                    takenUsed: item.is_used == 1 ? item.quantity : 0,
                                    used_quantity: item.is_used == 1 ? item.quantity : 0,
                                    value: item.price,
                                    quantity: item.quantity,
                                    location: location.location,
                                    serial_numbers: item.serial_numbers,
                                });

                            }
                        }
                        else {

                            if (transaction.is_used_first && transaction.serial_data.length == 0) {
                                for (const item of transaction.used_stocks) {
                                    if (remainingQuantity <= 0) break;

                                    let takenUsed = 0;
                                    const serialsTaken: any = [];

                                    const locationDetails = stockLoc.find((loc: any) =>
                                        loc.location_id === item.location_id && loc.product_id === item.product_id
                                    );

                                    if (item.quantity > 0) {
                                        if (item.quantity >= remainingQuantity) {
                                            takenUsed = remainingQuantity;
                                            serialsTaken.push(...serialNumbers.slice(serialIndex, serialIndex + takenUsed));


                                            result.push({
                                                ...item,
                                                takenUsed,
                                                takenNew: 0,
                                                location: locationDetails.location,
                                                takenQuantity: takenUsed,
                                                serial_numbers: serialsTaken,
                                            });
                                            remainingQuantity = 0;
                                        } else {
                                            takenUsed = item.quantity;
                                            serialsTaken.push(...serialNumbers.slice(serialIndex, serialIndex + takenUsed));
                                            result.push({
                                                ...item,
                                                takenUsed,
                                                takenNew: 0,
                                                location: locationDetails.location,
                                                takenQuantity: takenUsed,
                                                serial_numbers: serialsTaken,
                                            });
                                            remainingQuantity -= takenUsed;
                                        }
                                        serialIndex += takenUsed;
                                    }
                                }

                                if (remainingQuantity > 0) {
                                    for (const item of stockLoc) {
                                        if (remainingQuantity <= 0) break;

                                        let takenNew = 0;
                                        const serialsTaken: any = [];

                                        const new_stock_qty = item.quantity - item.used_quantity;

                                        if (new_stock_qty > 0) {
                                            if (new_stock_qty >= remainingQuantity) {
                                                takenNew = remainingQuantity;
                                                serialsTaken.push(...serialNumbers.slice(serialIndex, serialIndex + takenNew));
                                                result.push({
                                                    ...item,
                                                    takenUsed: 0,
                                                    value: transaction.cost,
                                                    takenNew,
                                                    takenQuantity: takenNew,
                                                    serial_numbers: serialsTaken,
                                                });
                                                remainingQuantity = 0;
                                            } else {
                                                takenNew = new_stock_qty;
                                                serialsTaken.push(...serialNumbers.slice(serialIndex, serialIndex + takenNew));
                                                result.push({
                                                    ...item,
                                                    takenUsed: 0,
                                                    value: transaction.cost,
                                                    takenNew,
                                                    takenQuantity: takenNew,
                                                    serial_numbers: serialsTaken,
                                                });
                                                remainingQuantity -= takenNew;
                                            }
                                            serialIndex += takenNew;
                                        }
                                    }
                                }
                            }
                            else {
                                for (const item of stockLoc) {
                                    if (remainingQuantity <= 0) break;

                                    let takenNew = 0;
                                    const serialsTaken: any = [];

                                    const new_stock_qty = item.quantity - item.used_quantity;

                                    console.log(new_stock_qty);
                                    if (new_stock_qty > 0) {
                                        if (new_stock_qty >= remainingQuantity) {
                                            takenNew = remainingQuantity;
                                            serialsTaken.push(...serialNumbers.slice(serialIndex, serialIndex + takenNew));
                                            result.push({
                                                ...item,
                                                takenUsed: 0,
                                                value: transaction.cost,
                                                takenNew,
                                                takenQuantity: takenNew,
                                                serial_numbers: serialsTaken,
                                            });
                                            remainingQuantity = 0;
                                        } else {
                                            takenNew = new_stock_qty;
                                            serialsTaken.push(...serialNumbers.slice(serialIndex, serialIndex + takenNew));
                                            result.push({
                                                ...item,
                                                takenUsed: 0,
                                                value: transaction.cost,
                                                takenNew,
                                                takenQuantity: takenNew,
                                                serial_numbers: serialsTaken,
                                            });
                                            remainingQuantity -= takenNew;
                                        }
                                        serialIndex += takenNew;
                                    }
                                }

                                if (remainingQuantity > 0) {
                                    for (const item of transaction.used_stocks) {
                                        if (remainingQuantity <= 0) break;

                                        let takenUsed = 0;
                                        const serialsTaken: any = [];

                                        const locationDetails = stockLoc.find((loc: any) =>
                                            loc.location_id === item.location_id && loc.product_id === item.product_id
                                        );

                                        if (item.quantity > 0) {
                                            if (item.quantity >= remainingQuantity) {
                                                takenUsed = remainingQuantity;
                                                serialsTaken.push(...serialNumbers.slice(serialIndex, serialIndex + takenUsed));


                                                result.push({
                                                    ...item,
                                                    takenUsed,
                                                    takenNew: 0,
                                                    location: locationDetails.location,
                                                    takenQuantity: takenUsed,
                                                    serial_numbers: serialsTaken,
                                                });
                                                remainingQuantity = 0;
                                            } else {
                                                takenUsed = item.quantity;
                                                serialsTaken.push(...serialNumbers.slice(serialIndex, serialIndex + takenUsed));
                                                result.push({
                                                    ...item,
                                                    takenUsed,
                                                    takenNew: 0,
                                                    location: locationDetails.location,
                                                    takenQuantity: takenUsed,
                                                    serial_numbers: serialsTaken,
                                                });
                                                remainingQuantity -= takenUsed;
                                            }
                                            serialIndex += takenUsed;
                                        }
                                    }
                                }
                            }


                            if (remainingQuantity > 0) {
                                console.log('Not enough stock available');
                            }
                        }



                        console.log(result);

                        return result.map((item) => (
                            <TableRow key={item.id}>
                                <TableCell sx={{ py: 1 }}>{transaction.product_name}</TableCell>
                                <TableCell sx={{ py: 1 }}>
                                    {item.location.name}
                                    {item.divider} Floor {item.floor}
                                    {item.serial_numbers.length > 0 && (
                                        <>
                                            <br />
                                            Serial Numbers:
                                            {item.serial_numbers.map((serials: any) => {
                                                return ' ' + serials.value + ', ';
                                            })}
                                        </>
                                    )}
                                </TableCell>
                                <TableCell sx={{ py: 1 }}>
                                    {item.takenQuantity} {transaction.serial_data.length > 0 ? '' : item.takenNew > 0 ? 'new' : 'old'} {transaction.product_unit}
                                </TableCell>
                                <TableCell sx={{ py: 1 }}>{formatWithPrefix(item.value)}</TableCell>
                                {/* <TableCell sx={{ py: 1 }}>{transaction.quantity}</TableCell> */}
                                <TableCell sx={{ py: 1 }}>
                                    {formatWithPrefix(item.takenQuantity * item.value)}
                                </TableCell>
                            </TableRow>
                        ));
                    })}
                </TableBody>
            </Table>

            <Divider sx={{ borderColor: '#acacac', display: 'none', displayPrint: 'block' }} />
            <Grid
                container
                columnSpacing={5}
                sx={{ fontSize: { md: '8pt', lg: '12pt' }, display: 'none', displayPrint: 'block' }}
            >
                <Grid item xs={2.5} className="fontSize8pt">
                    Requested By:
                    <Divider sx={{ borderColor: 'black', mt: { xs: 5 } }} />
                </Grid>
                <Grid item xs={2.5} className="fontSize8pt">
                    Prepared By:
                    <Divider sx={{ borderColor: 'black', mt: { xs: 5 } }} />
                </Grid>
                <Grid item xs={7}>
                    <Grid container columnSpacing={1}>
                        <Grid item className="fontSize8pt">
                            Checked By:
                        </Grid>
                        <Grid item flexGrow={1}>
                            <Grid container columnSpacing={2}>
                                <Grid item className="fontSize8pt">
                                    Checker
                                </Grid>
                                <Grid item sx={{ flexGrow: 1, alignContent: 'end' }}>
                                    <Divider sx={{ borderColor: 'black' }} />
                                </Grid>
                            </Grid>
                            <Grid container columnSpacing={2} mt={1}>
                                <Grid item className="fontSize8pt">
                                    Hauler Representative
                                </Grid>
                                <Grid item sx={{ flexGrow: 1, alignContent: 'end' }}>
                                    <Divider sx={{ borderColor: 'black' }} />
                                </Grid>
                            </Grid>
                            <Grid container columnSpacing={2} mt={1}>
                                <Grid item className="fontSize8pt">
                                    Customer Authorized Representative
                                </Grid>
                                <Grid item sx={{ flexGrow: 1, alignContent: 'end' }}>
                                    <Divider sx={{ borderColor: 'black' }} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12} mt={1}>
                    <Grid container columnSpacing={1} justifyContent={'between'}>
                        <Grid item xs={8}>
                            <Typography sx={{ fontWeight: 'bold' }} className="fontSize8pt">
                                Received the above goods in full and good condition.
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Grid item flexGrow={1}>
                                <Grid container columnSpacing={2}>
                                    <Grid item className="fontSize8pt">
                                        Received By:
                                    </Grid>
                                    <Grid item sx={{ flexGrow: 1, alignContent: 'end' }}>
                                        <Divider sx={{ borderColor: 'black' }} />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid
                item
                xs={12}
                sx={{ textAlign: 'center', display: 'none', displayPrint: 'block' }}
                mt={1}
            >
                <Divider sx={{ borderColor: 'black' }} />
            </Grid>

            <Grid
                item
                xs={12}
                sx={{ textAlign: 'center', display: 'none', displayPrint: 'block' }}
                mt={1}
            >
                <Grid container columnSpacing={1}>
                    <Grid item>
                        <Typography variant="body2" sx={{ color: 'text.secondary' }} className="fontSize8pt">
                            WAREHOUSE MANAGEMENT SYSTEM - by: INFORMATION SYSTEMS{' '}
                            {dayjs().format('MM/DD/YYYY h:mm:ss A')}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="body2" sx={{ color: 'text.secondary' }} className="fontSize8pt">
                            User: name of user
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
};
const RowComponent: React.FC<{
    returnDetails: any;
    onAddReturnClick: any;
    onRemoveClick: any;
}> = React.memo(({ returnDetails, onAddReturnClick, onRemoveClick }) => {

    const [open, setOpen] = React.useState(false);

    const addReturnClick = (details: any) => {
        onAddReturnClick(details);
    }

    const handleRemoveClick = () => {
        onRemoveClick(returnDetails);
    };
    console.log(returnDetails);
    useEffect(() => {

        console.log("return details state changed");
    }, [returnDetails])
    return (
        <React.Fragment>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                    </IconButton>
                </TableCell>
                <TableCell sx={{ py: 1, color: 'text.secondary' }}>{returnDetails.product_name}</TableCell>
                <TableCell sx={{ py: 1, color: 'text.secondary' }}></TableCell>
                <TableCell sx={{ py: 1, color: 'text.secondary' }}></TableCell>
                <TableCell sx={{ py: 1, color: 'text.secondary' }}>{returnDetails.remaining_quantity}</TableCell>
                <TableCell sx={{ py: 1, color: 'text.secondary' }}>
                    <Button variant='outlined' size='small' onClick={() => addReturnClick(returnDetails)}>
                        Add Issuance
                    </Button>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>

                            <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                                Issuance Items
                            </Typography>
                            <Table size="small" aria-label="purchases">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Image</TableCell>
                                        <TableCell>Product Name</TableCell>
                                        {/* <TableCell>Location</TableCell> */}
                                        <TableCell>Serial Numbers</TableCell>
                                        <TableCell>Quantity</TableCell>
                                        <TableCell>Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>

                                    {returnDetails.transaction && returnDetails.transaction.map((trans: any, index: any) => {

                                        console.log(trans);

                                        return (
                                            <TableRow key={index}>
                                                <TableCell scope="row">
                                                    <Avatar>
                                                        <img src={trans.product_image} alt='' height={'auto'} width={'100%'}></img>
                                                    </Avatar>
                                                </TableCell>
                                                <TableCell> {trans.product_name} </TableCell>
                                                {/* <TableCell>{trans.return_location_string}{trans.return_product_divider}, Floor {trans.return_product_location}</TableCell> */}

                                                <TableCell>{
                                                    trans.serial_data ?
                                                        trans.serial_data.map((serial: any) => {
                                                            return `${serial.value}, `;
                                                        })
                                                        : 'N/A'

                                                }</TableCell>
                                                <TableCell>{trans.quantity}</TableCell>
                                                <TableCell>
                                                    <IconButton edge="end" aria-label="comments" onClick={handleRemoveClick}>
                                                        <Close width={20} height={20} />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}

                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
});
function IpoIssuance({ }: Props) {
    return (
        <PageContainer title="RKCWMS - Product Adjustment" description="this is Sample page">
            <Card sx={{ mt: { xs: 0, md: 5 } }} elevation={12}>
                <MainComponent />
            </Card>
        </PageContainer>
    )
}

export default IpoIssuance