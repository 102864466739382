import { Add, RefreshOutlined } from '@mui/icons-material'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Avatar, Box, Button, Card, Chip, Container, Divider, Drawer, Grid, IconButton, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, Skeleton, Tab, Theme, Tooltip, Typography, useMediaQuery } from '@mui/material'
import { IconMenu2 } from '@tabler/icons'
import { QueryClient, QueryClientProvider, keepPreviousData, useQuery } from '@tanstack/react-query'
import { Image } from 'antd'
import { stubTrue } from 'lodash'
import { MRT_ColumnDef, MRT_ColumnFiltersState, MRT_PaginationState, MRT_RowSelectionState, MRT_SortingState, MaterialReactTable, useMaterialReactTable } from 'material-react-table'
import React, { memo, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router'
import { Link } from 'react-router-dom'
import ReactVirtualizedAutoSizer, { Size } from 'react-virtualized-auto-sizer'
import { FixedSizeList, ListChildComponentProps } from 'react-window'
import axiosClient from 'src/axios_client'
import PageContainer from 'src/components/container/PageContainer'
import Scrollbar from 'src/components/custom-scroll/Scrollbar'
import { AdjustmentsTable, IssuanceTable, PurchasesTable, SerialNumberTable } from 'src/globals/MaterialTables'
import { formatCurrency } from 'src/globals/NumericFormat'
import { formatDate } from 'src/globals/PublicFunctions'
import { AppState } from 'src/store/Store'

type Props = {}
type HandleClickType = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, index: number) => void;
interface RowProps extends ListChildComponentProps {
    data: any;
    selectedIndex: number | null;
    index: any;
    style: any;
    handleClick: HandleClickType;
}

interface TransactionData {
    adjustment_status: any;
    created_at: any;
    id: any;
    image: any;
    products: any;
    reason: any;
    reference_number: any;
    transaction_number: any;
    updated_at: any;
}

interface ProductData {
    id: any;
    transaction_history: TransactionData[];
    name: any;
    description: any;
    sku: any;
    location: any;
    unit: any;
    created_by: any;
    has_serial: any;
    manufacturer_name: any;
    brand_name: any;
    supplier_name: any;
    category_name: any;
    buying_price: any;
    selling_price: any;
    image: any;
}
type ProductApiResponse = {
    data: Array<ProductData>;
    pagination: {
        total: number;
        per_page: number;
        current_page: number;
        last_page: number;
        from: number;
        to: number;
    };
};

type Adjustments = {
    id: number;
    product_id: number;
    product_name: string;
    sku: string;
    created_at: any;
    status: any;
    is_price_adjustment: any;
    is_quantity_adjustment: any;
    transaction_number: any;
    buying_price: number;
    selling_price: number;
    quantity: number;
    requested_selling: number;
    requested_buying: number;
    requested_quantity: number;
    reason: string;
};

type AdjustmentApiResponse = {
    data: Array<Adjustments>;
    pagination: {
        total: number;
        per_page: number;
        current_page: number;
        last_page: number;
        from: number;
        to: number;
    };
};

type Transactions = {
    id: number;
    reference_number: number;
    invoice_number: string;
    supplier_id: string;
    date_of_purchase: any;
    image: any;
    created_by: {
        id: any,
        name: any
    };
    created_at: any;
    updated_at: number;
    selling_price: number;
    supplier: {
        id: any,
        name: any
    };
};

type TransactionsApiResponse = {
    data: Array<Transactions>;
    pagination: {
        total: number;
        per_page: number;
        current_page: number;
        last_page: number;
        from: number;
        to: number;
    };
};
type Issuance = {
    id: number;
    reference_number: number;
    slip_number: string;
    customer_id: string;
    date_of_issuance: any;
    created_by: {
        id: any,
        name: any
    };
    created_at: any;
    updated_at: number;
    total_amount: number;
};

type IssuanceApiResponse = {
    data: Array<Issuance>;
    pagination: {
        total: number;
        per_page: number;
        current_page: number;
        last_page: number;
        from: number;
        to: number;
    };
};
const Row: React.FC<RowProps> = memo(({ data, index, style, selectedIndex, handleClick }) => {
    const item = data[index];
    const status = data[index].adjustment_status;
    const statusText = status >= 1 && status <= 3 ? 'Pending' : status >= 4 && status <= 6 ? 'Approved' : 'Unknown';

    return (
        <div style={style}>
            <ListItemButton
                onClick={(event) => handleClick(event, index)}
                sx={{
                    mb: 0,
                    py: 2,
                    px: 3,
                }}
                selected={selectedIndex === index}
            >
                <ListItemText
                    primary={
                        <>
                            <Grid container>
                                <Grid item flexGrow={1}>
                                    <Typography variant="subtitle2" fontWeight={600}>
                                        Item name
                                    </Typography>
                                </Grid>
                            </Grid>

                        </>
                    }
                    sx={{ my: 0 }}
                />
            </ListItemButton>
        </div>
    )
}
);

const MaterialTable = () => {

    const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>([]);
    const [globalFilter, setGlobalFilter] = useState('');
    const [sorting, setSorting] = useState<MRT_SortingState>([]);

    const navigate = useNavigate();
    const [pagination, setPagination] = useState<MRT_PaginationState>({
        pageIndex: 0,
        pageSize: 10,
    });
    const {
        data: { data = [], pagination: meta } = {},
        isError,
        isRefetching,
        isLoading,
        refetch,
    } = useQuery<ProductApiResponse>({
        queryKey: [
            'table-data',
            columnFilters,
            globalFilter,
            pagination.pageIndex,
            pagination.pageSize,
            sorting,
        ],
        queryFn: async () => {
            try {
                const fetchURL = new URL(`${process.env.REACT_APP_API_SERVER}/products`);
                fetchURL.searchParams.set('page', `${pagination.pageIndex + 1}`);
                fetchURL.searchParams.set('per_page', `${pagination.pageSize}`);
                fetchURL.searchParams.set('filters', JSON.stringify(columnFilters ?? []));
                fetchURL.searchParams.set('globalFilter', globalFilter ?? '');
                fetchURL.searchParams.set('sorting', JSON.stringify(sorting ?? []));

                const response = await axiosClient.get(fetchURL.href);
                const json = response.data as ProductApiResponse;

                console.log(json);

                return json;
            } catch (error) {
                console.error('Error fetching data:', error);
                throw error;
            }
        },
        placeholderData: keepPreviousData,
    });
    const columns = useMemo<MRT_ColumnDef<ProductData>[]>(
        () => [
            {
                accessorKey: 'name',
                header: 'Product Name',
                size: 80
            },
        ],
        [],
    );
    const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});

    const table = useMaterialReactTable({
        columns,
        data,
        layoutMode: 'grid',
        defaultColumn: {
            minSize: 20, //allow columns to get smaller than default
            maxSize: 9001, //allow columns to get larger than default
            size: 180, //make columns wider by default
        },
        initialState: {
            columnVisibility: { sku: false },
            showColumnFilters: true,
        },
        enableRowSelection: true,
        enableMultiRowSelection: false,
        positionToolbarAlertBanner: 'none',
        onRowSelectionChange: setRowSelection,
        manualFiltering: true,
        manualPagination: true,
        manualSorting: true,
        muiToolbarAlertBannerProps: isError
            ? {
                color: 'error',
                children: 'Error loading data',
            }
            : undefined,
        onColumnFiltersChange: setColumnFilters,
        onGlobalFilterChange: setGlobalFilter,
        onPaginationChange: setPagination,
        onSortingChange: setSorting,
        enableColumnOrdering: true,
        muiTableBodyRowProps: ({ row }) => ({
            onClick: (event) => {
                const toggleHandler = row.getToggleSelectedHandler();
                toggleHandler(event);
                navigate(`/product-master/view/${row.original.sku}`);
            },
            sx: {
                cursor: 'pointer', //you might want to change the cursor too when adding an onClick
            },
        }),
        renderTopToolbarCustomActions: () => (
            <Box sx={{ display: 'flex', gap: '1rem', p: '4px', alignItems: 'center' }}>
                <Tooltip arrow title="Refresh Data">
                    <IconButton onClick={() => refetch()}>
                        <RefreshOutlined />
                    </IconButton>
                </Tooltip>
                <Button variant="contained" startIcon={<Add />} color="primary" to="/product-entry" component={Link}>
                    New
                </Button>
            </Box>
        ),

        rowCount: meta?.total ?? 0,
        state: {
            columnFilters,
            globalFilter,
            isLoading,
            rowSelection,
            pagination,
            showAlertBanner: isError,
            showProgressBars: isRefetching,
            sorting,
        },
    });



    return (
        <MaterialReactTable table={table} />

        // <>
        //     <MaterialReactTable table={table} />
        // </>
    );
}

// const AdjustmentsTable = () => {
//     const { id } = useParams();
//     const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>([{ id: 'sku', value: id }]);
//     const [globalFilter, setGlobalFilter] = useState('');
//     const [sorting, setSorting] = useState<MRT_SortingState>([]);

//     const navigate = useNavigate();
//     const [pagination, setPagination] = useState<MRT_PaginationState>({
//         pageIndex: 0,
//         pageSize: 10,
//     });
//     const {
//         data: { data = [], pagination: meta } = {},
//         isError,
//         isRefetching,
//         isLoading,
//         refetch,
//     } = useQuery<AdjustmentApiResponse>({
//         queryKey: [
//             'table-data',
//             columnFilters,
//             globalFilter,
//             pagination.pageIndex,
//             pagination.pageSize,
//             sorting,
//         ],
//         queryFn: async () => {
//             try {
//                 const fetchURL = new URL(`${process.env.REACT_APP_API_SERVER}/adjustments`);
//                 fetchURL.searchParams.set('page', `${pagination.pageIndex + 1}`);
//                 fetchURL.searchParams.set('per_page', `${pagination.pageSize}`);
//                 fetchURL.searchParams.set('filters', JSON.stringify(columnFilters ?? []));
//                 fetchURL.searchParams.set('globalFilter', globalFilter ?? '');
//                 fetchURL.searchParams.set('sorting', JSON.stringify(sorting ?? []));

//                 const response = await axiosClient.get(fetchURL.href);
//                 const json = response.data as AdjustmentApiResponse;

//                 console.log(json);

//                 return json;
//             } catch (error) {
//                 console.error('Error fetching data:', error);
//                 throw error;
//             }
//         },
//         placeholderData: keepPreviousData,
//     });
//     const columns = useMemo<MRT_ColumnDef<Adjustments>[]>(
//         () => [
//             {
//                 accessorKey: 'created_at',
//                 header: 'Date',
//                 size: 40,
//                 Cell: ({ row }) => {

//                     return (
//                         <div>
//                             {formatDate(row.original.created_at)}
//                         </div>
//                     );
//                 },
//             },
//             {
//                 accessorKey: 'transaction_number',
//                 header: 'Transaction Number',
//                 size: 100,
//             },
//             {
//                 accessorKey: 'is_price_adjustment',
//                 header: 'Price Adjustment?',
//                 enableColumnFilter: false,
//                 enableColumnActions: false,
//                 size: 60,
//                 Cell: ({ row }) => {
//                     const isPrice = row.original.is_price_adjustment;
//                     const isPriceAdjustment = isPrice == 1 ? 'Yes' : 'No';

//                     return (
//                         isPriceAdjustment
//                     );
//                 },
//             },
//             {
//                 accessorKey: 'is_quantity_adjustment',
//                 header: 'Qty. Adjustment?',
//                 size: 60,
//                 enableColumnFilter: false,
//                 enableColumnActions: false,
//                 Cell: ({ row }) => {
//                     const isQuantity = row.original.is_quantity_adjustment;
//                     const isQuantityAdjustment = isQuantity == 1 ? 'Yes' : 'No';
//                     return (
//                         isQuantityAdjustment
//                     );
//                 },
//             },
//             {
//                 accessorKey: 'status',
//                 header: 'Current Status',
//                 size: 60,
//                 Cell: ({ row }) => {
//                     const status = row.original.status;
//                     const statusText = status >= 1 && status <= 3 ? 'Pending' : status >= 4 && status <= 6 ? 'Approved' : 'Unknown';

//                     return (
//                         <Chip label={statusText} color={statusText == 'Pending' ? 'secondary' : statusText == 'Approved' ? 'success' : 'error'} />
//                     );
//                 },
//             },

//         ],
//         [],
//     );

//     const table = useMaterialReactTable({
//         columns,
//         data,
//         layoutMode: 'grid',
//         enableColumnDragging: false,
//         defaultColumn: {
//             minSize: 20, //allow columns to get smaller than default
//             maxSize: 9001, //allow columns to get larger than default
//             size: 180, //make columns wider by default
//         },
//         initialState: {
//             showColumnFilters: true,
//         },
//         positionToolbarAlertBanner: 'none',
//         muiToolbarAlertBannerProps: isError
//             ? {
//                 color: 'error',
//                 children: 'Error loading data',
//             }
//             : undefined,
//         onColumnFiltersChange: setColumnFilters,
//         onGlobalFilterChange: setGlobalFilter,
//         onPaginationChange: setPagination,
//         onSortingChange: setSorting,
//         enableColumnOrdering: true,
//         muiTableBodyRowProps: ({ row }) => ({
//             onClick: (event) => {
//                 window.open(`/adjustment-transactions/view/${row.original.transaction_number}`, '_blank');
//             },
//             sx: {
//                 cursor: 'pointer', //you might want to change the cursor too when adding an onClick
//             },
//         }),
//         renderTopToolbarCustomActions: () => (
//             <Box sx={{ display: 'flex', gap: '1rem', p: '4px', alignItems: 'center' }}>
//                 <Tooltip arrow title="Refresh Data">
//                     <IconButton onClick={() => refetch()}>
//                         <RefreshOutlined />
//                     </IconButton>
//                 </Tooltip>
//             </Box>
//         ),

//         rowCount: meta?.total ?? 0,
//         state: {
//             columnFilters,
//             globalFilter,
//             isLoading,
//             pagination,
//             showAlertBanner: isError,
//             showProgressBars: isRefetching,
//             sorting,
//         },
//     });



//     return (
//         <MaterialReactTable table={table} />

//         // <>
//         //     <MaterialReactTable table={table} />
//         // </>
//     );
// }

// const PurchasesTable = () => {
//     const { id } = useParams();
//     const [purchasesFilter, setPurchaseFilter] = useState<MRT_ColumnFiltersState>([]);
//     const [purchaseglobalFilter, setPurchaseGlobalFilter] = useState('');
//     const [sorting, setSorting] = useState<MRT_SortingState>([]);

//     const navigate = useNavigate();
//     const [pagination, setPagination] = useState<MRT_PaginationState>({
//         pageIndex: 0,
//         pageSize: 10,
//     });
//     const {
//         data: { data = [], pagination: meta } = {},
//         isError,
//         isRefetching,
//         isLoading,
//         refetch,
//     } = useQuery<TransactionsApiResponse>({
//         queryKey: [
//             'table-data',
//             purchasesFilter,
//             purchaseglobalFilter,
//             pagination.pageIndex,
//             pagination.pageSize,
//             sorting,
//         ],
//         queryFn: async () => {
//             try {
//                 const fetchURL = new URL(`${process.env.REACT_APP_API_SERVER}/pe_transactions/view/${id}`);
//                 fetchURL.searchParams.set('page', `${pagination.pageIndex + 1}`);
//                 fetchURL.searchParams.set('per_page', `${pagination.pageSize}`);
//                 fetchURL.searchParams.set('filters', JSON.stringify(purchasesFilter ?? []));
//                 fetchURL.searchParams.set('globalFilter', purchaseglobalFilter ?? '');
//                 fetchURL.searchParams.set('sorting', JSON.stringify(sorting ?? []));

//                 const response = await axiosClient.get(fetchURL.href);
//                 const json = response.data as TransactionsApiResponse;

//                 console.log(json);

//                 return json;
//             } catch (error) {
//                 console.error('Error fetching data:', error);
//                 throw error;
//             }
//         },
//         placeholderData: keepPreviousData,
//     });
//     const columns = useMemo<MRT_ColumnDef<Transactions>[]>(
//         () => [
//             {
//                 accessorKey: 'id',
//                 header: 'Id',
//                 size: 40,
//             },
//             {
//                 accessorKey: 'created_at',
//                 header: 'Date',
//                 size: 40,
//                 Cell: ({ row }) => {

//                     return (
//                         <div>
//                             {formatDate(row.original.created_at)}
//                         </div>
//                     );
//                 },
//             },
//             {
//                 accessorKey: 'invoice_number',
//                 header: 'Invoice Number',
//                 size: 40,
//             },
//             {
//                 accessorKey: 'reference_number',
//                 header: 'Reference Number',
//                 size: 40,
//             },
//             {
//                 accessorKey: 'image',
//                 header: 'Receipt',
//                 size: 40,
//                 enableColumnFilter: false,
//                 enableColumnActions: false,
//                 enableSorting: false,
//                 Cell: ({ row }) => {

//                     return (
//                         <Image
//                             width={50}
//                             alt=''
//                             src={row.original.image}
//                             preview={{
//                                 width: 'auto',
//                                 src: row.original.image,
//                             }}
//                         />
//                     );
//                 },
//             },
//         ],
//         [],
//     );

//     const table = useMaterialReactTable({
//         columns,
//         data,
//         layoutMode: 'grid',
//         enableColumnDragging: false,
//         defaultColumn: {
//             minSize: 20, //allow columns to get smaller than default
//             maxSize: 9001, //allow columns to get larger than default
//             size: 180, //make columns wider by default
//         },
//         initialState: {
//             showColumnFilters: true,
//             columnVisibility: { id: false }
//         },
//         positionToolbarAlertBanner: 'none',
//         muiToolbarAlertBannerProps: isError
//             ? {
//                 color: 'error',
//                 children: 'Error loading data',
//             }
//             : undefined,
//         onColumnFiltersChange: setPurchaseFilter,
//         onGlobalFilterChange: setPurchaseGlobalFilter,
//         onPaginationChange: setPagination,
//         onSortingChange: setSorting,
//         enableColumnOrdering: true,
//         renderTopToolbarCustomActions: () => (
//             <Box sx={{ display: 'flex', gap: '1rem', p: '4px', alignItems: 'center' }}>
//                 <Tooltip arrow title="Refresh Data">
//                     <IconButton onClick={() => refetch()}>
//                         <RefreshOutlined />
//                     </IconButton>
//                 </Tooltip>
//             </Box>
//         ),

//         rowCount: meta?.total ?? 0,
//         state: {
//             columnFilters: purchasesFilter,
//             globalFilter: purchaseglobalFilter,
//             isLoading,
//             pagination,
//             showAlertBanner: isError,
//             showProgressBars: isRefetching,
//             sorting,
//         },
//     });



//     return (
//         <MaterialReactTable table={table} />

//         // <>
//         //     <MaterialReactTable table={table} />
//         // </>
//     );
// }


// const IssuanceTable = () => {
//     const { id } = useParams();
//     const [issuanceFilter, setIssuanceFilter] = useState<MRT_ColumnFiltersState>([]);
//     const [issuanceGlobalFilter, setIssuanceGlobalFilter] = useState('');
//     const [sorting, setSorting] = useState<MRT_SortingState>([]);

//     const navigate = useNavigate();
//     const [pagination, setPagination] = useState<MRT_PaginationState>({
//         pageIndex: 0,
//         pageSize: 10,
//     });
//     const {
//         data: { data = [], pagination: meta } = {},
//         isError,
//         isRefetching,
//         isLoading,
//         refetch,
//     } = useQuery<IssuanceApiResponse>({
//         queryKey: [
//             'table-data',
//             issuanceFilter,
//             issuanceGlobalFilter,
//             pagination.pageIndex,
//             pagination.pageSize,
//             sorting,
//         ],
//         queryFn: async () => {
//             try {
//                 const fetchURL = new URL(`${process.env.REACT_APP_API_SERVER}/ie_transactions/view/${id}`);
//                 fetchURL.searchParams.set('page', `${pagination.pageIndex + 1}`);
//                 fetchURL.searchParams.set('per_page', `${pagination.pageSize}`);
//                 fetchURL.searchParams.set('filters', JSON.stringify(issuanceFilter ?? []));
//                 fetchURL.searchParams.set('globalFilter', issuanceGlobalFilter ?? '');
//                 fetchURL.searchParams.set('sorting', JSON.stringify(sorting ?? []));

//                 const response = await axiosClient.get(fetchURL.href);
//                 const json = response.data as IssuanceApiResponse;

//                 console.log(json);

//                 return json;
//             } catch (error) {
//                 console.error('Error fetching data:', error);
//                 throw error;
//             }
//         },
//         placeholderData: keepPreviousData,
//     });
//     const columns = useMemo<MRT_ColumnDef<Issuance>[]>(
//         () => [
//             {
//                 accessorKey: 'id',
//                 header: 'Id',
//                 size: 40,
//             },
//             {
//                 accessorKey: 'created_at',
//                 header: 'Date',
//                 size: 40,
//                 Cell: ({ row }) => {

//                     return (
//                         <div>
//                             {formatDate(row.original.created_at)}
//                         </div>
//                     );
//                 },
//             },
//             {
//                 accessorKey: 'date_of_issuance',
//                 header: 'Issued Date',
//                 size: 40,
//             },
//             {
//                 accessorKey: 'created_by',
//                 header: 'Creator',
//                 size: 40,
//                 Cell: ({ row }) => {
//                     console.log(row.original);

//                     return (
//                         <div>
//                             {row.original.created_by ? row.original.created_by.name : null}
//                         </div>
//                     );
//                 },
//             },
//             {
//                 accessorKey: 'reference_number',
//                 header: 'Reference Number',
//                 size: 40,
//             },
//         ],
//         [],
//     );

//     const table = useMaterialReactTable({
//         columns,
//         data,
//         layoutMode: 'grid',
//         enableColumnDragging: false,
//         defaultColumn: {
//             minSize: 20, //allow columns to get smaller than default
//             maxSize: 9001, //allow columns to get larger than default
//             size: 180, //make columns wider by default
//         },
//         initialState: {
//             showColumnFilters: true,
//             columnVisibility: { id: false }
//         },
//         positionToolbarAlertBanner: 'none',
//         muiToolbarAlertBannerProps: isError
//             ? {
//                 color: 'error',
//                 children: 'Error loading data',
//             }
//             : undefined,
//         onColumnFiltersChange: setIssuanceFilter,
//         onGlobalFilterChange: setIssuanceGlobalFilter,
//         onPaginationChange: setPagination,
//         onSortingChange: setSorting,
//         enableColumnOrdering: true,
//         renderTopToolbarCustomActions: () => (
//             <Box sx={{ display: 'flex', gap: '1rem', p: '4px', alignItems: 'center' }}>
//                 <Tooltip arrow title="Refresh Data">
//                     <IconButton onClick={() => refetch()}>
//                         <RefreshOutlined />
//                     </IconButton>
//                 </Tooltip>
//             </Box>
//         ),

//         rowCount: meta?.total ?? 0,
//         state: {
//             columnFilters: issuanceFilter,
//             globalFilter: issuanceGlobalFilter,
//             isLoading,
//             pagination,
//             showAlertBanner: isError,
//             showProgressBars: isRefetching,
//             sorting,
//         },
//     });



//     return (
//         <MaterialReactTable table={table} />

//         // <>
//         //     <MaterialReactTable table={table} />
//         // </>
//     );
// }

const MainComponent = () => {

    const { id } = useParams();

    const customizer = useSelector((state: AppState) => state.customizer);
    const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
    const [isMobileSidebarOpen, setMobileSidebarOpen] = useState(false);
    const drawerWidth = 380;
    const [loadingData, setLoadingData] = useState(false);
    const [panelValue, setPanelValue] = React.useState('1');
    const [productData, setProductData] = useState<ProductData>();

    useEffect(() => {
        setLoadingData(true);
        axiosClient.get(`/products/view/${id}`)
            .then(({ data, status }) => {
                if (status == 200) {
                    setLoadingData(false);
                }
                setProductData(data.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }, [id])
    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setPanelValue(newValue);
    };

    return (
        <PageContainer title="RKCWMS - Product Adjustment Page" description="this is Sample page">

            <Container sx={{ maxWidth: { md: '1500px' } }}>
                <Card
                    sx={{ display: 'flex', p: 0 }}
                    elevation={customizer.isCardShadow ? 9 : 0}
                    variant={!customizer.isCardShadow ? 'outlined' : undefined}
                >
                    <Drawer
                        open={isMobileSidebarOpen}
                        onClose={() => setMobileSidebarOpen(false)}
                        variant={lgUp ? 'permanent' : 'temporary'}
                        sx={{
                            width: drawerWidth,
                            flexShrink: 0,

                            zIndex: lgUp ? 0 : 1,
                            [`& .MuiDrawer-paper`]: { position: 'relative', overflow: 'hidden' },
                        }}
                    >

                        <Box display="flex" alignItems="center" p={2}>
                            <ListItem dense disableGutters>
                                <ListItemText
                                    primary={<Typography variant="h5">Product Lists</Typography>}
                                />
                            </ListItem>
                        </Box>
                        <MaterialTable />

                        {/* <AutoSizer>
                            {({ height, width }: Size) => (
                                <FixedSizeList
                                    height={height} // Adjust based on your container's height
                                    itemCount={data.length}
                                    itemSize={90}
                                    width={width}
                                >
                                    {({ index, style }) => (
                                        <Row
                                            data={data}
                                            index={index}
                                            style={style}
                                            selectedIndex={selectedIndex}
                                            handleClick={handleListItemClick}
                                        />
                                    )}
                                </FixedSizeList>
                            )}
                        </AutoSizer> */}
                    </Drawer>
                    <Box flexGrow={1}>
                        <Box display="flex" alignItems="center" p={2}>
                            <Box
                                sx={{
                                    display: { xs: 'block', md: 'block', lg: 'none' },
                                    mr: '10px',
                                }}
                            >
                                <IconMenu2 stroke={1.5} onClick={() => setMobileSidebarOpen(true)} />
                            </Box>
                            <ListItem dense disableGutters>
                                <ListItemText
                                    primary={<Typography variant="h5">{productData?.name}</Typography>}
                                    secondary={<Typography variant='subtitle2'>{productData?.sku}</Typography>}
                                />
                            </ListItem>
                        </Box>
                        <Divider />
                        <Scrollbar sx={{ height: 'calc(100vh - 170px)', flexGrow: 1 }}>
                            <Container>
                                <Box sx={{ maxWidth: { xs: 320, sm: 480, md: '100%' }, bgcolor: 'background.paper' }}>
                                    <TabContext value={panelValue}>
                                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                            <TabList onChange={handleChange} variant="scrollable" scrollButtons="auto" aria-label="lab API tabs example">
                                                <Tab label="Overview" value="1" />
                                                <Tab label="Purchases" value="2" />
                                                <Tab label="Returns" value="3" />
                                                <Tab label="Adjustments" value="4" />
                                                <Tab label="Issuances" value="5" />

                                                {productData?.has_serial &&
                                                    <Tab label="Serial Numbers" value="6" />
                                                }
                                            </TabList>
                                        </Box>
                                        <TabPanel value="1">
                                            {loadingData &&
                                                <Box flexGrow={2}>
                                                    <Container sx={{ mt: 5 }}>
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={3}>
                                                                <Skeleton variant="rectangular" height={19} />
                                                            </Grid>
                                                            <Grid item xs={9}>
                                                                <Skeleton variant="rectangular" height={19} />
                                                            </Grid>
                                                            <Grid item xs={3}>
                                                                <Skeleton variant="rectangular" height={19} />
                                                            </Grid>
                                                            <Grid item xs={9}>
                                                                <Skeleton variant="rectangular" height={19} />
                                                            </Grid>
                                                            <Grid item xs={3}>
                                                                <Skeleton variant="rectangular" height={19} />
                                                            </Grid>
                                                            <Grid item xs={9}>
                                                                <Skeleton variant="rectangular" height={19} />
                                                            </Grid>
                                                            <Grid item xs={3}>
                                                                <Skeleton variant="rectangular" height={19} />
                                                            </Grid>
                                                            <Grid item xs={9}>
                                                                <Skeleton variant="rectangular" height={19} />
                                                            </Grid>
                                                            <Grid item xs={3}>
                                                                <Skeleton variant="rectangular" height={19} />
                                                            </Grid>
                                                            <Grid item xs={9}>
                                                                <Skeleton variant="rectangular" height={19} />
                                                            </Grid>
                                                            <Grid item xs={3}>
                                                                <Skeleton variant="rectangular" height={19} />
                                                            </Grid>
                                                            <Grid item xs={9}>
                                                                <Skeleton variant="rectangular" height={19} />
                                                            </Grid>
                                                            <Grid item xs={3}>
                                                                <Skeleton variant="rectangular" height={19} />
                                                            </Grid>
                                                            <Grid item xs={9}>
                                                                <Skeleton variant="rectangular" height={19} />
                                                            </Grid>
                                                            <Grid item xs={3}>
                                                                <Skeleton variant="rectangular" height={19} />
                                                            </Grid>
                                                            <Grid item xs={9}>
                                                                <Skeleton variant="rectangular" height={19} />
                                                            </Grid>
                                                        </Grid>

                                                    </Container>
                                                </Box>
                                            }
                                            {(!loadingData && productData) &&
                                                <Box flexGrow={2}>
                                                    <Container sx={{ mt: 5 }}>
                                                        <Grid container>
                                                            <Grid item xs={12} md={9}>
                                                                <Grid container rowSpacing={{ xs: 0, sm: 2 }}>
                                                                    <Grid item xs={12} sm={3} fontWeight={600}>
                                                                        Item Name
                                                                    </Grid>
                                                                    <Grid item xs={12} sm={9}>
                                                                        {productData.name}
                                                                    </Grid>
                                                                    <Grid item xs={12} sm={3} fontWeight={600}>
                                                                        Item Type
                                                                    </Grid>
                                                                    <Grid item xs={12} sm={9}>
                                                                        {productData.category_name}
                                                                    </Grid>
                                                                    <Grid item xs={12} sm={3} fontWeight={600} mt={{ xs: 1, sm: 0 }}>
                                                                        SKU
                                                                    </Grid>
                                                                    <Grid item xs={12} sm={9}>
                                                                        {productData.sku}
                                                                    </Grid>
                                                                    <Grid item xs={12} sm={3} fontWeight={600} mt={{ xs: 1, sm: 0 }}>
                                                                        Description
                                                                    </Grid>
                                                                    <Grid item xs={12} sm={9}>
                                                                        {productData.description}
                                                                    </Grid>
                                                                    <Grid item xs={12} sm={3} fontWeight={600} mt={{ xs: 1, sm: 0 }}>
                                                                        Supplier
                                                                    </Grid>
                                                                    <Grid item xs={12} sm={9}>
                                                                        {productData.supplier_name}
                                                                    </Grid>
                                                                    <Grid item xs={12} sm={3} fontWeight={600} mt={{ xs: 1, sm: 0 }}>
                                                                        Unit
                                                                    </Grid>
                                                                    <Grid item xs={12} sm={9}>
                                                                        {productData.unit}
                                                                    </Grid>
                                                                    <Grid item xs={12} sm={3} fontWeight={600} mt={{ xs: 1, sm: 0 }}>
                                                                        Created Source
                                                                    </Grid>
                                                                    <Grid item xs={12} sm={9}>
                                                                        {productData.created_by}
                                                                    </Grid>
                                                                    <Grid item xs={12} mt={{ xs: 1, sm: 0 }}>
                                                                        <Divider />
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid item xs={12} md={3} display={'flex'} justifyContent={'end'}>
                                                                <Avatar variant="rounded" sx={{ height: { xs: 'auto', md: '160px' }, width: { xs: '100%', md: '160px' } }}>
                                                                    <img src={productData?.image} height={'auto'} width={'100%'}></img>
                                                                </Avatar>
                                                            </Grid>
                                                        </Grid>
                                                    </Container>
                                                </Box>
                                            }
                                        </TabPanel>
                                        <TabPanel value="2">
                                            {!(loadingData && productData) &&
                                                <PurchasesTable />
                                            }
                                        </TabPanel>
                                        <TabPanel value="3">
                                            Returns
                                        </TabPanel>
                                        <TabPanel value="4">
                                            {!(loadingData && productData) &&
                                                <AdjustmentsTable />
                                            }
                                        </TabPanel>
                                        <TabPanel value="5">
                                            {!(loadingData && productData) &&
                                                <IssuanceTable />
                                            }
                                        </TabPanel>

                                        <TabPanel value="6">
                                            {!(loadingData && productData?.has_serial) &&
                                                <SerialNumberTable />
                                            }
                                        </TabPanel>

                                    </TabContext>
                                </Box>


                            </Container>
                        </Scrollbar>
                    </Box>

                </Card>
            </Container>

        </PageContainer>
    )
}
const queryClient = new QueryClient();

function ProductDetails({ }: Props) {

    return (
        <QueryClientProvider client={queryClient}>
            <MainComponent />
        </QueryClientProvider>

    )
}

export default ProductDetails